import { Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Tooltip, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { currencyFormat, dateFormat } from "../../app/util/util";
import useDeposits from "../../app/hooks/useDeposits";
import AppPagination from "../../app/components/AppPagination";
import { useAppDispatch } from "../../app/store/configureStore";
import { removeDeposit, setPageNumber, getAxiosParamsForExport } from "../deposits/depositSlice";
import { useState } from "react";
import DepositForm from "./DepositForm";
//import { Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import { Deposit } from "../../app/models/deposit";
import { exportToExcel } from "../../app/util/exportFile";
import { depositExportFields } from "../../app/util/exportSettings";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DepositFilterDrawer from "./DepositFilterDrawer";
import { hasAdminOrWriteRole } from "../auth/authSlice";

export default function Deposits() {
    const { deposits, metaData, depositParams } = useDeposits();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedDeposit, setSelectedDeposit] = useState<Deposit | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState("");

    function handleSelectDeposit(deposit: Deposit) {
        setSelectedDeposit(deposit);
        setEditMode(true);
    }

    function handleDeleteDeposit(id: string) {
        setLoading(true);
        setTarget(id);
        agent.Deposit.delete(id)
            .then(() => dispatch(removeDeposit(id)))
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    function cancelEdit() {
        if (selectedDeposit) setSelectedDeposit(undefined);
        setEditMode(false);
    }

    function noWrapAndMinWidth() {
        return { whiteSpace: 'nowrap', maxWidth: 5 };
    }

    function noWrap() {
        return { whiteSpace: 'nowrap' };
    }

    function exportData() {
        let depositsForExport: any;
        agent.Deposit.getFilteredForExport('', getAxiosParamsForExport(depositParams))
            .then(data => {
                depositsForExport = data.items;
            })
            .catch(error => console.log(error))
            .finally(() => {
                exportToExcel(`Deposits`, depositExportFields, depositsForExport);
            });
    }

    if (editMode) return <DepositForm deposit={selectedDeposit} cancelEdit={cancelEdit} />

    return (
        <>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5' sx={{ letterSpacing: '1px' }}>Deposits ({metaData?.totalCount || 0})</Typography> 
                <Box sx={{ alignItems: 'right' }}>
                    <DepositFilterDrawer />
                    <Tooltip title="Export to Excel" placement="top">
                        <IconButton
                            size='large'
                            color='inherit'
                            onClick={exportData}
                            sx={{ ml: 'auto' }}
                        ><CloudDownloadIcon /></IconButton>
                    </Tooltip>
                    {hasAdminOrWriteRole() && <Tooltip title="Create Record" placement="top">
                        <IconButton
                            color='inherit'
                            size='large'
                            onClick={() => setEditMode(true)}
                            sx={{ ml: 'auto' }}
                        ><AddCircleIcon /></IconButton>
                    </Tooltip>}
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={noWrap} >
                        <TableRow>
                            {hasAdminOrWriteRole() && <TableCell align="center">Actions</TableCell>}
                            <TableCell align="center" style={{ display: 'none' }}>Id</TableCell>
                            <TableCell align="center" style={{ display: 'none' }}>GasStationId</TableCell>
                            <TableCell align="center">DepositDate</TableCell>
                            <TableCell align="center">FromDate</TableCell>
                            <TableCell align="center">ToDate</TableCell>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center">Notes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={noWrap}  >
                        {deposits.map((deposit) => (
                            deposit.id &&
                            <TableRow
                                key={deposit.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {hasAdminOrWriteRole() && <TableCell sx={noWrapAndMinWidth} align="center">
                                    <Button style={{ padding: 0 }} onClick={() => handleSelectDeposit(deposit)} startIcon={<Edit />} />
                                    <LoadingButton
                                        style={{ padding: 0 }}
                                        loading={loading && target === deposit.id}
                                        startIcon={<Delete />}
                                        color='error'
                                        onClick={() => handleDeleteDeposit(deposit.id || '')}
                                    />
                                </TableCell>}
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {deposit.id}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {deposit.gasStationId}
                                </TableCell>
                                <TableCell sx={noWrap} align="center">{dateFormat(deposit.depositDate?.toString() || '')}</TableCell>
                                <TableCell sx={noWrap} align="center">{dateFormat(deposit.fromDate?.toString() || '')}</TableCell>
                                <TableCell sx={noWrap} align="center">{dateFormat(deposit.toDate?.toString() || '')}</TableCell>
                                <TableCell align="center">{currencyFormat(deposit.amount || 0)}</TableCell>                                
                                <TableCell align="center">{deposit.notes || ''}</TableCell>                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {metaData &&
                <Box sx={{ pt: 2 }}>
                    <AppPagination
                        metaData={metaData}
                        onPageChange={(page: number) => dispatch(setPageNumber({ pageNumber: page }))}
                    />
                </Box>
            }
        </>
    )
}