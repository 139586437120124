import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationParamSchema } from './expenseParamValidation';
import AppDatePicker from '../../app/components/AppDatePicker';
import { dateFormatReset } from '../../app/util/util';
import { initParamsForFilter, resetExpenseParams, setExpenseParams } from './expenseSlice';
import { useAppDispatch } from '../../app/store/configureStore';
import { toast } from 'react-toastify';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AppTextInput from '../../app/components/AppTextInput';

type Anchor = 'right';

export default function ExpenseFilterDrawer() {
    const { control, reset, handleSubmit, formState: { isDirty, isSubmitting } } = useForm({
        mode: 'all',
        resolver: yupResolver<any>(validationParamSchema)
    });

    const dispatch = useAppDispatch();

    const [state, setState] = React.useState({
        right: false
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };


    const content = (anchor: Anchor) => (
        <Box
            sx={{ width: 350, p: 4 }}
            role="presentation"
        >
            <Typography variant="h5" gutterBottom sx={{ mb: 4, letterSpacing: '1px' }}>Filters</Typography>
            <form onSubmit={handleSubmit(handleSubmitData)} onReset={clearFilters}>
                <Paper sx={{ mb: 2 }}>
                    {/*<CashRegisterFromDateSearch />*/}
                    <AppDatePicker control={control} name='fromDate' label='From Date' />
                </Paper>
                <Paper sx={{ mb: 2 }}>
                    {/*<CashRegisterToDateSearch />*/}
                    <AppDatePicker control={control} name='toDate' label='To Date' />
                </Paper>
                <Paper sx={{ mb: 2 }}>
                    {/*<CashRegisterFromDateSearch />*/}
                    <AppTextInput control={control} name='or' label='OR' />
                </Paper>
                <Paper sx={{ mb: 2 }}>
                    {/*<CashRegisterToDateSearch />*/}
                    <AppTextInput control={control} name='particulars' label='Particulars' />
                </Paper>
                <Paper sx={{ mb: 2 }}>
                    {/*<CashRegisterToDateSearch />*/}
                    <AppTextInput control={control} name='accountTitle' label='Account Title' />
                </Paper>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <LoadingButton onClick={clearFilters} type='reset' variant='contained' size='small' color='inherit'>Clear</LoadingButton>
                    <LoadingButton loading={isSubmitting} type='submit' variant='contained' size='small' color='success'>Search</LoadingButton>
                </Box>
            </form>
        </Box>
    );

    function handleSubmitData(data: FieldValues) {
        try {
            data.fromDate = dateFormatReset(data.fromDate);
            data.toDate = dateFormatReset(data.toDate);
            dispatch(setExpenseParams(data));
        } catch (error: any) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred when searching.');
        } finally {
            setState({ right: false });
        }
    }

    const clearFilters = () => {
        try {
            dispatch(setExpenseParams(initParamsForFilter));
        } catch (error: any) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred when searching.');
        } finally {
            dispatch(resetExpenseParams());
            reset();
        }
    }

    return (
        <div style={{ display: 'contents' }}>
            {<React.Fragment key='expenseFilterDrawer'>
                <Tooltip title="Filter records" placement="top">
                    <IconButton
                        color='inherit'
                        onClick={toggleDrawer('right', true)}
                        sx={{ ml: 'auto' }}
                    ><FilterAltIcon /></IconButton>
                </Tooltip>
                <Drawer
                    anchor='right'
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                >
                    {content('right')}
                </Drawer>
            </React.Fragment>}
        </div>
    );
}