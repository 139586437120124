import { Button, Menu, Fade, MenuItem, FormControl, InputLabel, Select, Box, Typography, Divider, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { setActiveGasStation, signOut } from "../../features/auth/authSlice";
import { setCashRegisterActiveGasStation } from "../../features/cash-registers/cashRegisterSlice";
//import { clearBasket } from "../../features/basket/basketSlice";
import { useAppDispatch, useAppSelector } from "../store/configureStore";
import AppSelectList from "../components/AppSelectList";
import { GasStation } from "../models/gasStation";
import agent from "../api/agent";
import LoadingComponent from "./LoadingComponent";
import LogoutIcon from '@mui/icons-material/Logout';
import { setExpenseActiveGasStation } from "../../features/expenses/expenseSlice";
import { setDepositActiveGasStation } from "../../features/deposits/depositSlice";
import { setInventoryActiveGasStation } from "../../features/inventories/inventorySlice";
import { setPaymentActiveGasStation } from "../../features/payments/paymentSlice";
import { setPayrollActiveGasStation } from "../../features/payrolls/payrollSlice";
import { setProductActiveGasStation } from "../../features/products/productSlice";
import { setPumpPriceChangeActiveGasStation } from "../../features/pump-price-changes/pumpPriceChangeSlice";
import { setPumpTransactionActiveGasStation } from "../../features/pump-transactions/pumpTransactionSlice";
import { setSupplierActiveGasStation } from "../../features/suppliers/supplierSlice";
import { setTransactionActiveGasStation } from "../../features/transactions/transactionSlice";
import { setDashboardActiveGasStation } from "../../features/dashboard/dashboardSlice";
export default function SignedInMenu() {
    const dispatch = useAppDispatch();
    const { user, activeGasStation } = useAppSelector(state => state.auth);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [gasStationId, setGasStationId] = useState('');    
    const [gasStations, setGasStations] = useState<any[] | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        
        agent.GasStation.getGasStations()
        .then(data => {
            setGasStations(data.data);
            setGasStationId(activeGasStation || '');
        })
        .catch(error => console.log(error))
        .finally(() => {
            setLoading(false);
        });
        
    }, []);

    if (loading) return <LoadingComponent message='Loading filters...' />
    
    return (
        <>
            <Box display='flex' justifyContent='space-between' sx={{ m: 3 }}>
                <Box display='flex' alignItems='center'>
                    <FormControl variant='standard' sx={{ minWidth: 120 }} size="small">
                        <InputLabel sx={{ mb: 4, letterSpacing: '2px' }}>Gas Station</InputLabel>
                        <Select
                            value={gasStationId}
                            name='gasStationId'
                            onChange={async (event) => {
                                const { value } = event.target;
                                setGasStationId(value);
                                dispatch(setActiveGasStation(value));
                                dispatch(setCashRegisterActiveGasStation(value));
                                dispatch(setDepositActiveGasStation(value));
                                dispatch(setExpenseActiveGasStation(value));
                                dispatch(setInventoryActiveGasStation(value));
                                dispatch(setPaymentActiveGasStation(value));
                                dispatch(setPayrollActiveGasStation(value));
                                dispatch(setProductActiveGasStation(value));
                                dispatch(setPumpPriceChangeActiveGasStation(value));
                                dispatch(setPumpTransactionActiveGasStation(value));
                                dispatch(setSupplierActiveGasStation(value));
                                dispatch(setTransactionActiveGasStation(value));
                                dispatch(setDashboardActiveGasStation(value));
                                //window.location.reload();
                            }}
                        >
                            {gasStations?.map((item) => (
                                <MenuItem key={item.id?.toString()} value={item.id?.toString()}>{`${item.name}`}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 1 }} />
                <Box display='flex' alignItems='center'>
                    <Tooltip title="Logout" placement="right">
                        <IconButton
                            color='inherit'
                            onClick={() => {
                                dispatch(signOut());
                                window.location.href = "/login";
                            }}
                            sx={{ ml: 2 }}
                        >
                            <LogoutIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            
        </>
    );
}