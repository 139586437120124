import { get } from 'lodash';
import * as XLSX from 'xlsx';
import { currencyFormat, dateFormat } from './util';
import dayjs from 'dayjs';

export const exportToCsv = (fileName: string, columns: any[], rows: any[]) => {
    
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    const formattedTime = currentDate.toISOString().split('T')[1].split('.')[0].replace(/:/g, '-');
    const fullFilename = `${fileName}_${formattedDate}_${formattedTime}`;

    const csvContent = "data:text/csv;charset=utf-8," +
        columns.map(column => column.headerName).join(",") + "\n" +
        rows.map(row => columns.map(column => row[column.field]).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fullFilename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const exportToExcel = (fileName: string, columns: any[], rows: any[]) => {
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
        const formattedRow = {} as any;
        columns.forEach(column => {
            const cellValue = get(row, column.field);
            formattedRow[column.headerName] = formatCellValue(cellValue);
        });
        return formattedRow;
    }));
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    const formattedTime = currentDate.toISOString().split('T')[1].split('.')[0].replace(/:/g, '-');
    const fullFilename = `${fileName}_${formattedDate}_${formattedTime}`;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${fullFilename}.xlsx`);
};

const formatCellValue = (value: any): any => {
    if (typeof value === 'number') {
        return currencyFormat(value);
    } else if (typeof value === 'string' && !isNaN(Date.parse(value))) {
        return dateFormat(value);
    } else {
        return value?.toString();
    }
};