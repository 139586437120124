import { Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Tooltip, IconButton } from "@mui/material";
import { currencyFormat, dateFormat } from "../../app/util/util";
import usePumpTransactions from "../../app/hooks/usePumpTransactions";
import AppPagination from "../../app/components/AppPagination";
import { useAppDispatch } from "../../app/store/configureStore";
import { getAxiosParamsForExport, removePumpTransaction, setPageNumber } from "../pump-transactions/pumpTransactionSlice";
import { useState } from "react";
import PumpTransactionForm from "./PumpTransactionForm";
//import { Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import { PumpTransaction } from "../../app/models/pumpTransaction";
import { exportToExcel } from "../../app/util/exportFile";
import { pumpTransactionExportFields } from "../../app/util/exportSettings";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PumpTransactionFilterDrawer from "./PumpTransactionFilterDrawer";
export default function PumpTransactions() {
    const { pumpTransactions, metaData, pumpTransactionParams } = usePumpTransactions();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedPumpTransaction, setSelectedPumpTransaction] = useState<PumpTransaction | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState("");

    function handleSelectPumpTransaction(pumpTransaction: PumpTransaction) {
        setSelectedPumpTransaction(pumpTransaction);
        setEditMode(true);
    }

    function handleDeletePumpTransaction(id: string) {
        setLoading(true);
        setTarget(id);
        agent.PumpTransaction.delete(id)
            .then(() => dispatch(removePumpTransaction(id)))
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    function cancelEdit() {
        if (selectedPumpTransaction) setSelectedPumpTransaction(undefined);
        setEditMode(false);
    }

    function noWrapAndMinWidth() {
        return { whiteSpace: 'nowrap', maxWidth: 5 };
    }

    function noWrap() {
        return { whiteSpace: 'nowrap' };
    }

    function exportData() {
        let dataForExport: any;
        agent.PumpTransaction.getFilteredForExport('', getAxiosParamsForExport(pumpTransactionParams))
            .then(data => {
                dataForExport = data.items;
            })
            .catch(error => console.log(error))
            .finally(() => {
                exportToExcel(`PumpTransactions`, pumpTransactionExportFields, dataForExport);
            });
    }

    if (editMode) return <PumpTransactionForm pumpTransaction={selectedPumpTransaction} cancelEdit={cancelEdit} />

    return (
        <>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5' sx={{ letterSpacing: '1px' }}>Pump Transactions ({metaData?.totalCount || 0})</Typography>
                <Box sx={{ alignItems: 'right' }}>
                    <PumpTransactionFilterDrawer />
                    <Tooltip title="Export to Excel" placement="top">
                        <IconButton
                            size='large'
                            color='inherit'
                            onClick={exportData}
                            sx={{ ml: 'auto', fontSize: '2rem' }}
                        ><CloudDownloadIcon /></IconButton>
                    </Tooltip>                
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={noWrap} >
                        <TableRow>
                            {/*<TableCell align="center">Actions</TableCell>*/}
                            <TableCell align="center" style={{ display: 'none' }}>Id</TableCell>
                            <TableCell align="center" style={{ display: 'none' }}>GasStationId</TableCell>
                            <TableCell align="center">PumpNumber</TableCell>
                            <TableCell align="center">PumpTransactionDate</TableCell>
                            <TableCell align="center">BeginningLiters</TableCell>
                            <TableCell align="center">EndingLiters</TableCell>
                            <TableCell align="center">SaleAmount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={noWrap}  >
                        {pumpTransactions.map((pumpTransaction) => (
                            pumpTransaction.id &&
                            <TableRow
                                key={pumpTransaction.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {pumpTransaction.id}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {pumpTransaction.gasStationId}
                                </TableCell>
                                <TableCell align="center">{pumpTransaction.pumpNumber}</TableCell>
                                <TableCell align="center">{dateFormat(pumpTransaction.transactionDate?.toString() || '')}</TableCell>
                                <TableCell align="center">{pumpTransaction.beginningLiters}</TableCell>
                                <TableCell align="center">{pumpTransaction.endingLiters}</TableCell>
                                <TableCell align="center">{currencyFormat(pumpTransaction.saleAmount || 0)}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {metaData &&
                <Box sx={{ pt: 2 }}>
                    <AppPagination
                        metaData={metaData}
                        onPageChange={(page: number) => dispatch(setPageNumber({ pageNumber: page }))}
                    />
                </Box>
            }
        </>
    )
}