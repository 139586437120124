import { Typography, Grid, Paper, Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import AppDropzone from "../../app/components/AppDropzone";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
//import useProducts from "../../app/hooks/useProducts";
import useTransactions from "../../app/hooks/useTransactions";
//import { Product } from "../../app/models/product";
import { Transaction } from "../../app/models/transaction";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from "./transactionValidation";
import agent from "../../app/api/agent";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { setTransaction, fetchLitersAsync, setFiltersLoaded } from "../transactions/transactionSlice";
import { LoadingButton } from "@mui/lab";
import AppCheckbox from "../../app/components/AppCheckbox";
import AppDatePicker from "../../app/components/AppDatePicker";
import { currencyFormat, currencyFormat2, dateFormat, dateFormatReset } from "../../app/util/util";
import { PumpTransaction } from "../../app/models/pumpTransaction";
import { setSupplier } from "../suppliers/supplierSlice";
import useInventories from "../../app/hooks/useInventories";
import FormControlContext from "@mui/material/FormControl/FormControlContext";
import { toast } from "react-toastify";
import { InventoryLookupData } from "../../app/models/inventory";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { setDashboardActiveGasStation } from "../dashboard/dashboardSlice";

interface Props {
    transaction?: Transaction;
    cancelEdit: () => void;
}

export default function TransactionForm({ transaction, cancelEdit }: Props) {
    const { control, reset, handleSubmit, register, watch, formState: { isDirty, isSubmitting, errors } } = useForm({
        mode: 'all',
        resolver: yupResolver<any>(validationSchema)
    });
    const { activeGasStation } = useAppSelector(state => state.auth);
    //const { brands, types } = useGasStations();
    const watchFile = watch('file', null);
    const dispatch = useAppDispatch();
    setFiltersLoaded(false);
    //const { allInventories } = useInventories();
    const [pumpNumber, setPumpNumber] = useState('');
    const [cost, setCost] = useState(0);
    const [price, setPrice] = useState(0);
    const [beginningLiters, setBeginningLiters] = useState(0);
    const [endingLiters, setEndingLiters] = useState(0);
    const [inventoryId, setInventoryId] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [saleAmount, setSaleAmount] = useState(0);
    const [allInventories, setAllInventories] = useState<any[] | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (transaction && !watchFile && !isDirty) reset(transaction);
        if (transaction) {
            setPumpNumber(transaction.pumpTransaction.pumpNumber || '');
            setCost(transaction.cost || 0);
            setPrice(transaction.price || 0);
            setBeginningLiters(transaction.pumpTransaction.beginningLiters || 0);
            setEndingLiters(transaction.pumpTransaction.endingLiters || 0);
            setInventoryId(transaction.inventoryId || '');
            setQuantity(transaction.quantity || 0);
            setSaleAmount(transaction.pumpTransaction.saleAmount || 0);

        }
        agent.Inventory.getLookupData('')
            .then(data => {
                setAllInventories((data.data as InventoryLookupData).allInventories);
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
        return () => {
            if (watchFile) URL.revokeObjectURL(watchFile.preview);
        }
    }, [transaction, reset, watchFile, isDirty]);

    if (loading) return <LoadingComponent message='Loading filters...' />

    async function handleSubmitData(data: FieldValues) {
        let response: any;
        try {

            data.transactionDate = dateFormatReset(data.transactionDate);
            data.inventoryId = inventoryId || '';
            data.pumpNumber = pumpNumber || '';
            data.beginningLiters = beginningLiters || 0;
            data.endingLiters = endingLiters || 0;
            data.quantity = quantity || 0;
            data.cost = cost || 0;
            data.price = price || 0;
            data.saleAmount = saleAmount || 0;
            data.poAmount = data.poAmount === '' || data.poAmount === null ? 0 : data.poAmount;
            data.discount = data.discount === '' || data.discount === null ? 0 : data.discount;
            data.amountAddOn = data.amountAddOn === '' || data.amountAddOn === null ? 0 : data.amountAddOn;
            data.calibration = data.calibration === '' || data.calibration === null ? 0 : data.calibration;
            if (transaction) {
                response = await agent.Transaction.patch(data.gasStationId, data.id, data);
            } else {
                response = await agent.Transaction.post(data.gasStationId, data);
            }
            dispatch(setTransaction(response));
            toast.success(response.message?.toString() || 'Success!');
            cancelEdit();
        } catch (error) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred.');
        } finally {
            dispatch(setDashboardActiveGasStation(activeGasStation));
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4, letterSpacing: '1px' }}>
                Transaction Details
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={3}>
                    {(transaction?.id?.toString() || '') !== '' &&
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='tranId' label='Transaction Id' readonly={true} />
                    </Grid>}
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='transactionDate' label='Transaction Date' />
                    </Grid>
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <AppTextInput control={control} name='transactionType' label='Transaction Type' />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth error={!!errors.inventoryId}>
                            <InputLabel>Product-Supplier</InputLabel>
                            <Select
                                value={inventoryId}
                                variant='outlined'
                                {...register('inventoryId')} 
                                inputProps={{ readOnly: (transaction?.id?.toString() || '') !== '' ? true : false  }}
                                onChange={async (event) => {
                                    const { value } = event.target;
                                    setInventoryId(value);
                                    if (pumpNumber) {
                                        const response = (await dispatch(fetchLitersAsync({ inventoryId: value, pumpNumber: pumpNumber })))?.payload as PumpTransaction;
                                        setCost(response?.cost || 0);
                                        setPrice(response?.price || 0);

                                        const tempBeginningLiters = response?.endingLiters || 0;
                                        setBeginningLiters(tempBeginningLiters);

                                        const tempEndingLiters = currencyFormat2(tempBeginningLiters - quantity);
                                        setEndingLiters(tempEndingLiters);

                                        const tempSaleAmount = currencyFormat2(quantity * (response?.price || 0));
                                        setSaleAmount(tempSaleAmount);
                                    }                                    
                                }} 
                                
                            >
                                {allInventories?.map((item) => (
                                    <MenuItem key={item.id?.toString()} value={item.id?.toString()}>{`${item.supplier.name}-${item.product.name}` }</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.inventoryId?.message?.toString()}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='shift' label='Shift' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            fullWidth
                            variant='outlined'
                            label='Pump Number'
                            value={pumpNumber}
                            inputProps={{ readOnly: (transaction?.id?.toString() || '') !== '' ? true : false }}
                            {...register('pumpNumber')} 
                            onChange={async (event) => {
                                const { value } = event.currentTarget;
                                setPumpNumber(value);
                                if (inventoryId) {
                                    const response = (await dispatch(fetchLitersAsync({ inventoryId: inventoryId, pumpNumber: value })))?.payload as PumpTransaction;
                                    
                                    setCost(response?.cost || 0);
                                    setPrice(response?.price || 0);

                                    const tempBeginningLiters = response?.endingLiters || 0;
                                    setBeginningLiters(tempBeginningLiters);

                                    const tempEndingLiters = currencyFormat2(tempBeginningLiters - quantity);
                                    setEndingLiters(tempEndingLiters);

                                    const tempSaleAmount = currencyFormat2(quantity * (response?.price || 0));
                                    setSaleAmount(tempSaleAmount);
                                }                                
                            }}
                            error={!!errors.pumpNumber}
                            helperText={errors.pumpNumber?.message?.toString()}
                        />
                    </Grid>
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <AppTextInput control={control} name='pumpTransaction' label='Pump Transaction' />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            variant='outlined'
                            {...register('beginningLiters')} 
                            fullWidth
                            value={beginningLiters}
                            label='Beginning Liters'
                            inputProps={{ readOnly: (transaction?.id?.toString() || '') !== '' ? true : false }}
                            onChange={(event) => {
                                const { value } = event.currentTarget;
                                setBeginningLiters(parseFloat(value));
                                const tempEndingLiters = currencyFormat2(parseFloat(value) - quantity);
                                setEndingLiters(tempEndingLiters);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            variant='outlined'
                            name='endingLiters'
                            fullWidth
                            value={endingLiters}
                            label='Ending Liters'
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='calibration' label='Calibration' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            variant='outlined'
                            fullWidth
                            value={quantity}
                            label='Quantity'
                            inputProps={{ readOnly: (transaction?.id?.toString() || '') !== '' ? true : false }}
                            {...register('quantity')} 
                            onChange={async (event) => {
                                const { value } = event.currentTarget;
                                setQuantity(parseFloat(value));
                                if (inventoryId && pumpNumber) {
                                    const response = (await dispatch(fetchLitersAsync({ inventoryId: inventoryId, pumpNumber: pumpNumber })))?.payload as PumpTransaction;
                                    setCost(response?.cost || 0);
                                    setPrice(response?.price || 0);

                                    //const tempBeginningLiters = response?.endingLiters || 0;
                                    //setBeginningLiters(tempBeginningLiters);

                                    const tempEndingLiters = currencyFormat2(beginningLiters - parseFloat(value));
                                    setEndingLiters(tempEndingLiters);

                                    const tempSaleAmount = currencyFormat2(parseFloat(value) * (response?.price || 0));
                                    setSaleAmount(tempSaleAmount);
                                }               
                            }}
                            error={!!errors.quantity}
                            helperText={errors.quantity?.message?.toString()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            variant='outlined'
                            name='cost'
                            fullWidth
                            value={cost}
                            label='Supplier Cost'
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            variant='outlined'
                            name='price'
                            fullWidth
                            value={price}
                            label='Selling Price'
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            variant='outlined'
                            name='saleAmount'
                            fullWidth
                            value={saleAmount}
                            label='Sale Amount'
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='amountAddOn' label='Amount Add On' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='poAmount' label='PO Amount' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='discount' label='Discount' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='discountCard' label='Discount Card' />
                    </Grid>
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <AppTextInput control={control} name='paymentMethod' label='Payment Method' />*/}
                    {/*</Grid>*/}
                </Grid>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='contained' color='inherit'>Cancel</Button>
                    <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='success'>Submit</LoadingButton>
                </Box>
            </form>
        </Box>
    )
}