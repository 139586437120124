import * as yup from 'yup';

export const validationParamSchema = yup.object({
    //fromDate: yup
    //    .string()
    //    .when('toDate', {
    //        is: yup.ref('toDate').getValue.length > 0,
    //        then: yup
    //            .date()
    //            .typeError("Must be a valid date")
    //            .required('Required')
    //            .max(yup.ref('toDate'), 'From Date must be before To Date'),
    //    }),
    //toDate: yup
    //    .string()
    //    .when('fromDate', {
    //        is: yup.ref('fromDate').getValue.length > 0,
    //        then: yup
    //            .date()
    //            .typeError("Must be a valid date")
    //            .required('Required')
    //            .min(yup.ref('fromDate'), 'To Date must be after From Date'),
    //    })
})