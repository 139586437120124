export function getCookie(key: string) {
  const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export function currencyFormat(amount: number) {
    if (amount) {
        const fixedNumber = amount.toFixed(2);
        return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } 
    return 0;
}

export function currencyFormat2(amount: number) {
    if (amount) {
        return parseFloat(amount.toFixed(2));
    }
    return 0;
}

export function dateFormat(value: string) {
    if (value) {
        return new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
    }

    return '';
}

export function dateFormatReset(value: string) {
    if (value) {
        const taipeiOptions: Intl.DateTimeFormatOptions = {
            timeZone: 'Asia/Taipei',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        };

        const newDate = new Date(value).toLocaleString('en-US', taipeiOptions);
        const newDateISO = new Date(newDate).toISOString();
        return newDateISO.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, 'T00:00:00.000Z');
    }
    return null;
}

export function dateFormatResetParam(value: string) {
    const taipeiOptions: Intl.DateTimeFormatOptions = {
        timeZone: 'Asia/Taipei',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    };

    const newDate = new Date(value).toLocaleString('en-US', taipeiOptions);
    const newDateISO = new Date(newDate).toISOString();
    return newDateISO.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, 'T00:00:00.000Z');
}

function formatDateToMMDDYYYY(inputDate: string) {
    const date = new Date(inputDate);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to get the correct month (0-11).
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
}