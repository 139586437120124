import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { Product, ProductParams } from "../../app/models/product";
import { RootState } from "../../app/store/configureStore";

interface ProductState {
    productsLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    productParams: ProductParams;
    metaData: MetaData | null;
    productActiveGasStation: string;
}

const productsAdapter = createEntityAdapter<Product>();

function getAxiosParams(productParams: ProductParams) {
    const params = new URLSearchParams();
    params.append('page', productParams.pageNumber.toString());
    params.append('pageSize', productParams.pageSize.toString());
    if (productParams.name) params.append('name', productParams.name);
    if (productParams.productType) params.append('productType', productParams.productType);

    return params;
}

export const getAxiosParamsForExport = (productParams: ProductParams) => {
    return getAxiosParams(productParams);
}


export const fetchProductsAsync = createAsyncThunk<Product[], void, { state: RootState }>(
    'product/fetchProductsAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().product.productParams);
        try {
            const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
            const response = await agent.Product.getFiltered(gasStationId || '', params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchProductAsync = createAsyncThunk<Product, string, { state: RootState }>(
    'product/fetchProductAsync',
    async (id :string, thunkAPI) => {
        try {
            const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
            return await agent.Product.getByKeys(gasStationId || '', id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 10,
        gasStationId: '',
        name: '',
        productType: '',
    }
}

export const initParamsForFilter = () => {
    return initParams;
}

export const productSlice = createSlice({
    name: 'product',
    initialState: productsAdapter.getInitialState<ProductState>({
        productsLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        productParams: initParams(),
        metaData: null,
        productActiveGasStation: ''
    }),
    reducers: {
        setProductParams: (state, action) => {
            state.productsLoaded = false;
            state.productParams = { ...state.productParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.productsLoaded = false;
            state.productParams = { ...state.productParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetProductParams: (state) => {
            state.productParams = initParams();
        },
        setProduct: (state, action) => {
            productsAdapter.upsertOne(state, action.payload);
            state.productsLoaded = false;
        },
        removeProduct: (state, action) => {
            productsAdapter.removeOne(state, action.payload);
            state.productsLoaded = false;
        },
        setProductActiveGasStation: (state, action) => {
            state.productActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchProductsAsync.pending, (state) => {
            state.status = 'pendingFetchProducts';
        });
        builder.addCase(fetchProductsAsync.fulfilled, (state, action) => {
            productsAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.productsLoaded = true;
        });
        builder.addCase(fetchProductsAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchProductAsync.pending, (state) => {
            state.status = 'pendingFetchProduct';
        });
        builder.addCase(fetchProductAsync.fulfilled, (state, action) => {
            productsAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchProductAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
    })
})

export const productSelectors = productsAdapter.getSelectors((state: RootState) => state.product);

export const { setProductParams, resetProductParams, setMetaData, setPageNumber, setProduct, removeProduct, setProductActiveGasStation } = productSlice.actions;