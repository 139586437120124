import { Typography, Grid, Paper, Box, Button } from "@mui/material";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInput";
import { CashRegister } from "../../app/models/cashRegister";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from "./cashRegisterValidation";
import agent from "../../app/api/agent";
import { useAppDispatch } from "../../app/store/configureStore";
import { setCashRegister } from "../cash-registers/cashRegisterSlice";
import { LoadingButton } from "@mui/lab";
import AppDatePicker from "../../app/components/AppDatePicker";
import { dateFormatReset } from "../../app/util/util";
import { toast } from "react-toastify";

interface Props {
    cashRegister?: CashRegister;
    cancelEdit: () => void;
}

export default function CashRegisterForm({ cashRegister, cancelEdit }: Props) {
    const { control, reset, handleSubmit, watch, formState: { isDirty, isSubmitting } } = useForm({
        mode: 'all',
        resolver: yupResolver<any>(validationSchema)
    });

    const watchFile = watch('file', null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (cashRegister && !watchFile && !isDirty) reset(cashRegister);
        return () => {
            if (watchFile) URL.revokeObjectURL(watchFile.preview);
        }
    }, [cashRegister, reset, watchFile, isDirty]);

    async function handleSubmitData(data: FieldValues) {
        let response: any;
        try {
            
            data.cashRegisterDate = dateFormatReset(data.cashRegisterDate);
            data.startingBalance = data.startingBalance || 0;
            data.endingBalance = data.endingBalance || 0;
            data.overage = data.overage || 0;
            data.shortage = data.shortage || 0;
            data.cashOnHand = data.cashOnHand || 0;
            if (cashRegister) {
                response = await agent.CashRegister.patch(data.gasStationId, data.id, data);
            } else {
                response = await agent.CashRegister.post(data.gasStationId, data);
            }
            dispatch(setCashRegister(response));
            toast.success(response.message?.toString() || 'Success!');
            cancelEdit();
            
        } catch (error: any) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred.');
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4, letterSpacing: '1px' }}>
                Cash Register Details
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='cashRegisterDate' label='Cash Register Date' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='startingBalance' label='Starting Balance' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='endingBalance' label='Ending Balance' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='overage' label='Overage' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='shortage' label='Shortage' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='cashOnHand' label='Cash On Hand' />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='contained' color='inherit'>Cancel</Button>
                    <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='success'>Submit</LoadingButton>
                </Box>
            </form>
        </Box>
    )
}