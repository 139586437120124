import { Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Tooltip, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { currencyFormat, dateFormat } from "../../app/util/util";
import useExpenses from "../../app/hooks/useExpenses";
import AppPagination from "../../app/components/AppPagination";
import { useAppDispatch } from "../../app/store/configureStore";
import { getAxiosParamsForExport, removeExpense, setPageNumber } from "../expenses/expenseSlice";
import { useState } from "react";
import ExpenseForm from "./ExpenseForm";
//import { Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import { Expense } from "../../app/models/expense";
import { exportToExcel } from "../../app/util/exportFile";
import { expenseExportFields } from "../../app/util/exportSettings";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpenseFilterDrawer from "./ExpenseFilterDrawer";
import { hasAdminOrWriteRole } from "../auth/authSlice";
export default function Expenses() {
    const { expenses, metaData, expenseParams } = useExpenses();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedExpense, setSelectedExpense] = useState<Expense | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState("");

    function handleSelectExpense(expense: Expense) {
        setSelectedExpense(expense);
        setEditMode(true);
    }

    function handleDeleteExpense(id: string) {
        setLoading(true);
        setTarget(id);
        agent.Expense.delete(id)
            .then(() => dispatch(removeExpense(id)))
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    function cancelEdit() {
        if (selectedExpense) setSelectedExpense(undefined);
        setEditMode(false);
    }

    function noWrapAndMinWidth() {
        return { whiteSpace: 'nowrap', maxWidth: 5 };
    }

    function noWrap() {
        return { whiteSpace: 'nowrap' };
    }

    function exportData() {
        let expensesForExport: any;
        agent.Expense.getFilteredForExport('', getAxiosParamsForExport(expenseParams))
            .then(data => {
                expensesForExport = data.items;
            })
            .catch(error => console.log(error))
            .finally(() => {
                exportToExcel(`Expenses`, expenseExportFields, expensesForExport);
            });
    }

    if (editMode) return <ExpenseForm expense={selectedExpense} cancelEdit={cancelEdit} />

    return (
        <>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5' sx={{ letterSpacing: '1px' }}>Expenses ({metaData?.totalCount || 0})</Typography>
                <Box sx={{ alignItems: 'right' }}>
                    <ExpenseFilterDrawer />
                    <Tooltip title="Export to Excel" placement="top">
                        <IconButton
                            size='large'
                            color='inherit'
                            onClick={exportData}
                            sx={{ ml: 'auto', fontSize: '2rem' }}
                        ><CloudDownloadIcon /></IconButton>
                    </Tooltip>
                    {hasAdminOrWriteRole() && <Tooltip title="Create Record" placement="top">
                        <IconButton
                            size='large'
                            color='inherit'
                            onClick={() => setEditMode(true)}
                            sx={{ ml: 'auto' }}
                        ><AddCircleIcon /></IconButton>
                    </Tooltip>}
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={noWrap} >
                        <TableRow>
                            {hasAdminOrWriteRole() && <TableCell align="center">Actions</TableCell>}
                            <TableCell align="center" style={{ display: 'none' }}>Id</TableCell>
                            <TableCell align="center" style={{ display: 'none' }}>GasStationId</TableCell>
                            <TableCell align="center">Expense Date</TableCell>
                            <TableCell align="center">OR</TableCell>
                            <TableCell align="center">Particulars</TableCell>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center">Account Title</TableCell>
                            <TableCell align="center">Notes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={noWrap}  >
                        {expenses.map((expense) => (
                            expense.id &&
                            <TableRow
                                key={expense.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {hasAdminOrWriteRole() && <TableCell sx={noWrapAndMinWidth} align="center">
                                    <Button style={{ padding: 0 }} onClick={() => handleSelectExpense(expense)} startIcon={<Edit />} />
                                    <LoadingButton
                                        style={{ padding: 0 }}
                                        loading={loading && target === (expense.id || '')}
                                        startIcon={<Delete />}
                                        color='error'
                                        onClick={() => handleDeleteExpense(expense.id || '')}
                                    />
                                </TableCell>}
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {expense.id || ''}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {expense.gasStationId || ''}
                                </TableCell>
                                <TableCell sx={noWrap} align="center">{dateFormat(expense.expenseDate?.toString() || '')}</TableCell>
                                <TableCell align="center">{expense.or || ''}</TableCell>
                                <TableCell align="center">{expense.particulars || ''}</TableCell>
                                <TableCell align="center">{currencyFormat(expense.amount || 0)}</TableCell>
                                <TableCell align="center">{expense.accountTitle || ''}</TableCell>
                                <TableCell align="center">{expense.notes || ''}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {metaData &&
                <Box sx={{ pt: 2 }}>
                    <AppPagination
                        metaData={metaData}
                        onPageChange={(page: number) => dispatch(setPageNumber({ pageNumber: page }))}
                    />
                </Box>
            }
        </>
    )
}