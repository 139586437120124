import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { User, UserParams } from "../../app/models/user";
import { RootState } from "../../app/store/configureStore";

interface UserState {
    usersLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    gasStations: any[];
    roles: any[];
    pages: any[];
    userParams: UserParams;
    metaData: MetaData | null;
}

const usersAdapter = createEntityAdapter<User>();

function getAxiosParams(userParams: UserParams) {
    const params = new URLSearchParams();
    params.append('page', userParams.pageNumber.toString());
    params.append('pageSize', userParams.pageSize.toString());
    return params;
}

export const fetchUsersAsync = createAsyncThunk<User[], void, { state: RootState }>(
    'user/fetchUsersAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().user.userParams);
        try {
            const response = await agent.User.getFiltered(params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchUserAsync = createAsyncThunk<User, any>(
    'user/fetchUserAsync',
    async (id: string, thunkAPI) => {
        try {
            return await agent.User.getByKeys(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchFilters = createAsyncThunk(
    'user/fetchFilters',
    async (_, thunkAPI) => {
        try {
            let response = await agent.User.getUserLookupData();
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 50,
        //orderBy: 'name',
        gasStations: [],
        roles: [],
        pages: [],
    }
}

export const userSlice = createSlice({
    name: 'user',
    initialState: usersAdapter.getInitialState<UserState>({
        usersLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        gasStations: [],
        roles: [],
        pages: [],
        userParams: initParams(),
        metaData: null
    }),
    reducers: {
        setUserParams: (state, action) => {
            state.usersLoaded = false;
            state.userParams = { ...state.userParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.usersLoaded = false;
            state.userParams = { ...state.userParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetUserParams: (state) => {
            state.userParams = initParams();
        },
        setUser: (state, action) => {
            usersAdapter.upsertOne(state, action.payload);
            state.usersLoaded = false;
        },
        removeUser: (state, action) => {
            usersAdapter.removeOne(state, action.payload);
            state.usersLoaded = false;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchUsersAsync.pending, (state) => {
            state.status = 'pendingFetchUsers';
        });
        builder.addCase(fetchUsersAsync.fulfilled, (state, action) => {
            usersAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.usersLoaded = true;
        });
        builder.addCase(fetchUsersAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchUserAsync.pending, (state) => {
            state.status = 'pendingFetchUser';
        });
        builder.addCase(fetchUserAsync.fulfilled, (state, action) => {
            usersAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchUserAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchFilters.pending, (state) => {
            state.status = 'pendingFetchFilters';
        });
        builder.addCase(fetchFilters.fulfilled, (state, action) => {
            state.gasStations = action.payload.gasStations;
            state.roles = action.payload.roles;
            state.pages = action.payload.pages;
            state.filtersLoaded = true;
            state.status = 'idle';
        });
        builder.addCase(fetchFilters.rejected, (state, action) => {
            state.status = 'idle';
        })
    })
})

export const userSelectors = usersAdapter.getSelectors((state: RootState) => state.user);

export const { setUserParams, resetUserParams, setMetaData, setPageNumber, setUser, removeUser } = userSlice.actions;