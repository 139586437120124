import { TextField } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";

interface Props extends UseControllerProps {
    label: string;
    multiline?: boolean;
    rows?: number;
    type?: string;
    readonly?: boolean | false;
    onChange?: (value: any) => void;
    value?: any;
}

export default function AppTextInput(props: Props) {
    const { fieldState, field } = useController({ ...props, defaultValue: '' })
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = props.type === 'number' ? parseFloat(event.target.value) : event.target.value;
        if (field) {
            field.onChange(inputValue);
            if (props.onChange) {
                props.onChange(inputValue);
            }
        }
    };


    const valueProp = props.type === 'number' ? 0 : ''; 
    if (props.type === 'number') {
        if (props.value === '' || props.value === null) {
            props.value = 0;
        }
    }
    
    return (
        props.onChange ?
        <TextField 
            {...props}
            {...field}
                value={props.value || valueProp} 
            multiline={props.multiline}
            rows={props.rows}
            type={props.type}
            fullWidth
            variant='outlined'
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            InputProps={{ readOnly: props.readonly }}
            onChange={handleInputChange}
            /> 
            : <TextField
                {...props}
                {...field}
                multiline={props.multiline}
                rows={props.rows}
                type={props.type}
                fullWidth
                variant='outlined'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputProps={{ readOnly: props.readonly }}
            /> 
    )
}