import { useEffect } from "react";
import { inventorySelectors, fetchInventoriesAsync, fetchFilters } from "../../features/inventories/inventorySlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useInventories() {
    const inventories = useAppSelector(inventorySelectors.selectAll);
    const { inventoriesLoaded, filtersLoaded, metaData, products, suppliers, allInventories, inventoryActiveGasStation, inventoryParams } = useAppSelector(state => state.inventory);
    //const { productsLoaded } = useAppSelector(state => state.product);
    //const { suppliersLoaded } = useAppSelector(state => state.supplier);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!inventoriesLoaded) dispatch(fetchInventoriesAsync());
        if (inventoryActiveGasStation) dispatch(fetchInventoriesAsync());
    }, [inventoriesLoaded, inventoryActiveGasStation, dispatch])

    useEffect(() => {
        if (!filtersLoaded) dispatch(fetchFilters());

        //if (!productsLoaded) dispatch(fetchFilters());

        //if (!suppliersLoaded) dispatch(fetchFilters());

    }, [filtersLoaded, dispatch]);

    return {
        inventories,
        inventoriesLoaded,
        inventoryActiveGasStation,
        filtersLoaded,
        products,
        suppliers,
        allInventories,
        inventoryParams,
        metaData
    }
}