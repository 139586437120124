import { useEffect } from "react";
import { supplierSelectors, fetchSuppliersAsync } from "../../features/suppliers/supplierSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useSuppliers() {
    const suppliers = useAppSelector(supplierSelectors.selectAll);
    const { suppliersLoaded, filtersLoaded, metaData, supplierActiveGasStation, supplierParams } = useAppSelector(state => state.supplier);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!suppliersLoaded) dispatch(fetchSuppliersAsync());
        if (supplierActiveGasStation) dispatch(fetchSuppliersAsync());
    }, [suppliersLoaded, supplierActiveGasStation, dispatch])

    return {
        suppliers,
        suppliersLoaded,
        filtersLoaded,
        supplierActiveGasStation,
        supplierParams,
        metaData
    }
}