import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { Inventory, InventoryLookupData, InventoryParams } from "../../app/models/inventory";
import { RootState } from "../../app/store/configureStore";

interface InventoryState {
    inventoriesLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    products: any[];
    suppliers: any[];
    allInventories: any[];
    inventoryParams: InventoryParams;
    metaData: MetaData | null;
    inventoryActiveGasStation: string;
}

const inventoriesAdapter = createEntityAdapter<Inventory>();

function getAxiosParams(inventoryParams: InventoryParams) {
    const params = new URLSearchParams();
    params.append('page', inventoryParams.pageNumber.toString());
    params.append('pageSize', inventoryParams.pageSize.toString());
    if (inventoryParams.productName) params.append('productName', inventoryParams.productName);
    if (inventoryParams.supplierName) params.append('supplierName', inventoryParams.supplierName);
    return params;
}

export const getAxiosParamsForExport = (inventoryParams: InventoryParams) => {
    return getAxiosParams(inventoryParams);
}

export const fetchInventoriesAsync = createAsyncThunk<Inventory[], void, { state: RootState }>(
    'inventory/fetchInventoriesAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().inventory.inventoryParams);
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            const response = await agent.Inventory.getFiltered(gasStationId || '', params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchInventoryAsync = createAsyncThunk<Inventory, string, { state: RootState }>(
    'inventory/fetchInventoryAsync',
    async (id, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            return await agent.Inventory.getByKeys(gasStationId || '', id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchFilters = createAsyncThunk<InventoryLookupData, void, { state: RootState }>(
    'inventory/fetchFilters',
    async (_, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {            
            var response = await agent.Inventory.getLookupData(gasStationId || '');
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 10,
        gasStationId: '',
        productName: '',
        supplierName: ''
    }
}


export const initParamsForFilter = () => {
    return initParams;
}

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState: inventoriesAdapter.getInitialState<InventoryState>({
        inventoriesLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        inventoryParams: initParams(),
        metaData: null,
        products: [],
        suppliers: [],
        allInventories: [],
        inventoryActiveGasStation: ''
    }),
    reducers: {
        setInventoryParams: (state, action) => {
            state.inventoriesLoaded = false;
            state.inventoryParams = { ...state.inventoryParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.inventoriesLoaded = false;
            state.inventoryParams = { ...state.inventoryParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetInventoryParams: (state) => {
            state.inventoryParams = initParams();
        },
        setInventory: (state, action) => {
            inventoriesAdapter.upsertOne(state, action.payload);
            state.inventoriesLoaded = false;
        },
        removeInventory: (state, action) => {
            inventoriesAdapter.removeOne(state, action.payload);
            state.inventoriesLoaded = false;
        },
        setInventoryActiveGasStation: (state, action) => {
            state.inventoryActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchInventoriesAsync.pending, (state) => {
            state.status = 'pendingFetchInventories';
        });
        builder.addCase(fetchInventoriesAsync.fulfilled, (state, action) => {
            inventoriesAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.inventoriesLoaded = true;
        });
        builder.addCase(fetchInventoriesAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchInventoryAsync.pending, (state) => {
            state.status = 'pendingFetchInventory';
        });
        builder.addCase(fetchInventoryAsync.fulfilled, (state, action) => {
            inventoriesAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchInventoryAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchFilters.pending, (state) => {
            state.status = 'pendingFetchFilters';
        });
        builder.addCase(fetchFilters.fulfilled, (state, action) => {
            state.products = action.payload.products;
            state.suppliers = action.payload.suppliers;
            state.allInventories = action.payload.allInventories;
            state.filtersLoaded = true;
            state.status = 'idle';
        });
        builder.addCase(fetchFilters.rejected, (state, action) => {
            state.status = 'idle';
            console.log(action.payload);
        })
    })
})

export const inventorySelectors = inventoriesAdapter.getSelectors((state: RootState) => state.inventory);

export const { setInventoryParams, resetInventoryParams, setMetaData, setPageNumber, setInventory, removeInventory, setInventoryActiveGasStation } = inventorySlice.actions;