import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { history } from "../..";
import { PaginatedResponse } from "../models/pagination";
import { store } from "../store/configureStore";

const sleep = () => new Promise(resolve => setTimeout(resolve, 500));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//axios.defaults.withCredentials = true;

const responseBody = (response: AxiosResponse) => response.data;

//axios.interceptors.request.use(config => {
//    const token = store.getState().auth.user?.token;
//    if (token) config.headers.Authorization = `Bearer ${token}`;
//    return config;
//})
axios.interceptors.request.use(config => {
    const token = store.getState().auth.user?.accessToken;
    if (token) {
        config.headers.common["Authorization"] = "Bearer " + token;
        config.headers.common["Access-Control-Allow-Origin"] = "*";
    } else {
        config.headers.common["Content-Type"] = "application/x-www-form-urlencoded";
    }
    return config;
})

axios.interceptors.response.use(async response => {
    if (process.env.NODE_ENV === 'development') await sleep();
    const pagination = response.headers['pagination'];
    if (pagination) {
        response.data = new PaginatedResponse(response.data, JSON.parse(pagination));
        return response;
    }
    return response;
}, (error: AxiosError) => {
    const { data, status } = error.response!;
    switch (status) {
        case 400:
            if (data.errors) {
                const modelStateErrors: string[] = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modelStateErrors.push(data.errors[key])
                    }
                }
                throw modelStateErrors.flat();
            }
            toast.error(data.errors[0]);
            break;
        case 401:
            toast.error(data.errors[0]);
            break;
        case 403:
            toast.error('You are not allowed to do that!');
            break;
        case 500:
            history.push({
                pathname: '/server-error',
                state: { error: data }
            });
            break;
        default:
            break;
    }
    return Promise.reject(error.response);
})

const requests = {
    get: (url: string, params?: URLSearchParams) => axios.get(url, { params }).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    patch: (url: string, body: {}) => axios.patch(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
    postForm: (url: string, data: FormData) => axios.post(url, data, {
        headers: { 'Content-type': 'multipart/form-data' }
    }).then(responseBody),
    putForm: (url: string, data: FormData) => axios.put(url, data, {
        headers: { 'Content-type': 'multipart/form-data' }
    }).then(responseBody)
}

function createFormData(item: any) {
    let formData = new FormData();
    for (const key in item) {
        formData.append(key, item[key])
    }
    return formData;
}

const Auth = {
    login: (values: any) => requests.post('Auth/login', values),
    register: (values: any) => requests.post('Auth/register', values),
    currentUser: () => requests.get('Auth')
}


const CashRegister = {
    
    getFiltered: (gasStationId: string, params: URLSearchParams) => requests.get(`CashRegisters/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getFilteredForExport: (gasStationId: string, params: URLSearchParams) => requests.get(`CashRegisters/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getByKeys: (
        gasStationId: string,
        id: string) =>
        requests.get(`CashRegisters/${store.getState().auth?.activeGasStation?.toString()}/details/${id}`),
    post: (gasStationId: string, values: any) => requests.post(`CashRegisters/${store.getState().auth?.activeGasStation?.toString()}`, values),
    patch: (gasStationId: string, id: string, values: any) => requests.patch(`CashRegisters/${store.getState().auth?.activeGasStation?.toString()}/${id}`, values),
    delete: (id: string) => requests.delete(`CashRegisters/${id}`)
}

const Deposit = {
    getFiltered: (gasStationId: string, params: URLSearchParams) => requests.get(`Deposits/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getFilteredForExport: (gasStationId: string, params: URLSearchParams) => requests.get(`Deposits/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getByKeys: (
        gasStationId: string,
        id: string) =>
        requests.get(`Deposits/${store.getState().auth?.activeGasStation?.toString()}/details/${id}`),
    post: (gasStationId: string, values: any) => requests.post(`Deposits/${store.getState().auth?.activeGasStation?.toString()}`, values),
    patch: (gasStationId: string, id: string, values: any) => requests.patch(`Deposits/${store.getState().auth?.activeGasStation?.toString()}/${id}`, values),
    delete: (id: string) => requests.delete(`Deposits/${id}`)
}

const Expense = {
    getFiltered: (gasStationId: string, params: URLSearchParams) => requests.get(`Expenses/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getFilteredForExport: (gasStationId: string, params: URLSearchParams) => requests.get(`Expenses/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getByKeys: (
        gasStationId: string,
        id: string) =>
        requests.get(`Expenses/${store.getState().auth?.activeGasStation?.toString()}/details/${id}`),
    post: (gasStationId: string, values: any) => requests.post(`Expenses/${store.getState().auth?.activeGasStation?.toString()}`, values),
    patch: (gasStationId: string, id: string, values: any) => requests.patch(`Expenses/${store.getState().auth?.activeGasStation?.toString()}/${id}`, values),
    delete: (id: string) => requests.delete(`Expenses/${id}`)
}

const GasStation = {
    getFiltered: (params: URLSearchParams) => requests.get('gasstations', params),        
    getByKeys: (
        id: string) =>
        requests.get(`gasstations/${id}`), 
    getGasStations: () => requests.get(`gasstations/getactiveGasStations`),
    post: (values: any) => requests.post(`gasstations/`, values),
    patch: (id: string, values: any) => requests.patch(`gasstations/${id}`, values),
    delete: (id: string) => requests.delete(`gasstations/${id}`)
}

const Inventory = {
    getFiltered: (gasStationId: string, params: URLSearchParams) => requests.get(`inventories/${store.getState().auth?.activeGasStation?.toString()}`, params),     
    getFilteredForExport: (gasStationId: string, params: URLSearchParams) => requests.get(`inventories/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getByKeys: (
        gasStationId: string,
        id: string) =>
        requests.get(`inventories/${store.getState().auth?.activeGasStation?.toString()}/details/${id}`),
    getLookupData: (gasStationId: string) => requests.get(`inventories/${store.getState().auth?.activeGasStation?.toString()}/filters/lookup/data`),
    post: (gasStationId: string, values: any) => requests.post(`inventories/${store.getState().auth?.activeGasStation?.toString()}`, values),
    patch: (gasStationId: string, id: string, values: any) => requests.patch(`inventories/${store.getState().auth?.activeGasStation?.toString()}/${id}`, values),
    delete: (id: string) => requests.delete(`inventories/${id}`)
}

const Payment = {
    getFiltered: (gasStationId: string, params: URLSearchParams) => requests.get(`payments/${store.getState().auth?.activeGasStation?.toString()}`, params),    
    getFilteredForExport: (gasStationId: string, params: URLSearchParams) => requests.get(`payments/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getByKeys: (
        gasStationId: string,
        id: string) =>
        requests.get(`payments/${store.getState().auth?.activeGasStation?.toString()}/details/${id}`),
    post: (gasStationId: string, values: any) => requests.post(`payments/${store.getState().auth?.activeGasStation?.toString()}`, values),
    patch: (gasStationId: string, id: string, values: any) => requests.patch(`payments/${store.getState().auth?.activeGasStation?.toString()}/${id}`, values),
    delete: (id: string) => requests.delete(`payments/${id}`)
}

const Payroll = {
    getFiltered: (gasStationId: string, params: URLSearchParams) => requests.get(`payrolls/${store.getState().auth?.activeGasStation?.toString()}`, params),        
    getFilteredForExport: (gasStationId: string, params: URLSearchParams) => requests.get(`payrolls/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getByKeys: (
        gasStationId: string,
        id: string) =>
        requests.get(`payrolls/${gasStationId}/details/${id}`),
    post: (gasStationId: string, values: any) => requests.post(`payrolls/${store.getState().auth?.activeGasStation?.toString()}`, values),
    patch: (gasStationId: string, id: string, values: any) => requests.patch(`payrolls/${store.getState().auth?.activeGasStation?.toString()}/${id}`, values),
    delete: (id: string) => requests.delete(`payrolls/${id}`)
}

const Product = {
    getFiltered: (gasStationId: string, params: URLSearchParams) => requests.get(`products/${store.getState().auth?.activeGasStation?.toString()}`, params),     
    getFilteredForExport: (gasStationId: string, params: URLSearchParams) => requests.get(`products/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getByKeys: (gasStationId: string,
        id: string) =>
        requests.get(`products/${store.getState().auth?.activeGasStation?.toString()}/details/${id}`),
    post: (gasStationId: string, values: any) => requests.post(`products/${store.getState().auth?.activeGasStation?.toString()}`, values),
    patch: (gasStationId: string, id: string, values: any) => requests.patch(`products/${store.getState().auth?.activeGasStation?.toString()}/${id}`, values),
    delete: (id: string) => requests.delete(`products/${id}`)
}

const PumpPriceChange = {
    getFiltered: (gasStationId: string, params: URLSearchParams) => requests.get(`pumppricechanges/${store.getState().auth?.activeGasStation?.toString()}`, params),     
    getByKeys: (
        gasStationId: string,
        id: string) =>
        requests.get(`pumppricechanges/${store.getState().auth?.activeGasStation?.toString()}/${id}`),
    post: (gasStationId: string, values: any) => requests.post(`pumppricechanges/${store.getState().auth?.activeGasStation?.toString()}`, values),
    patch: (gasStationId: string, id: string, values: any) => requests.patch(`pumppricechanges/${store.getState().auth?.activeGasStation?.toString()}/${id}`, values),
    delete: (id: string) => requests.delete(`pumppricechanges/${id}`)
}

const PumpTransaction = {
    getFiltered: (gasStationId: string, params: URLSearchParams) => requests.get(`pumptransactions/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getFilteredForExport: (gasStationId: string, params: URLSearchParams) => requests.get(`pumptransactions/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getByKeys: (
        gasStationId: string,
        id: string) =>
        requests.get(`pumptransactions/${store.getState().auth?.activeGasStation?.toString()}/details/${id}`),
    getLiters: (inventoryId: string, pumpNumber: string) => requests.get(`pumptransactions/${store.getState().auth?.activeGasStation?.toString()}/liters/${inventoryId}/${pumpNumber}`),
    post: (gasStationId: string, values: any) => requests.post(`pumptransactions/${store.getState().auth?.activeGasStation?.toString()}`, values),
    patch: (gasStationId: string, id: string, values: any) => requests.patch(`pumptransactions/${store.getState().auth?.activeGasStation?.toString()}/${id}`, values),
    delete: (id: string) => requests.delete(`pumptransactions/${id}`)
}

const Supplier = {
    getFiltered: (gasStationId: string, params: URLSearchParams) => requests.get(`suppliers/${store.getState().auth?.activeGasStation?.toString()}`, params),    
    getFilteredForExport: (gasStationId: string, params: URLSearchParams) => requests.get(`suppliers/${store.getState().auth?.activeGasStation?.toString()}`, params),
    getByKeys: (gasStationId: string,      
        id: string) =>
        requests.get(`suppliers/${store.getState().auth?.activeGasStation?.toString()}/details/${id}`),
    post: (gasStationId: string, values: any) => requests.post(`suppliers/${store.getState().auth?.activeGasStation?.toString()}`, values),
    patch: (gasStationId: string, id: string, values: any) => requests.patch(`suppliers/${store.getState().auth?.activeGasStation?.toString()}/${id}`, values),
    delete: (id: string) => requests.delete(`suppliers/${id}`)
}

const Transaction = {
    getFiltered: (gasStationId: string, params: URLSearchParams) => requests.get(`Transactions/${store.getState().auth?.activeGasStation?.toString()}`, params),       
    getByKeys: (
        gasStationId: string,
        id: string) =>
        requests.get(`Transactions/${store.getState().auth?.activeGasStation?.toString()}/${id}`),
    getOpenTransactions: (gasStationId: string) => requests.get(`transactions/${store.getState().auth?.activeGasStation?.toString()}/open`),
    post: (gasStationId: string, values: any) => requests.post(`Transactions/${store.getState().auth?.activeGasStation?.toString()}`, values),
    patch: (gasStationId: string, id: string, values: any) => requests.patch(`Transactions/${store.getState().auth?.activeGasStation?.toString()}/${id}`, values),
    delete: (id: string) => requests.delete(`Transactions/${id}`)
}

const User = {
    getFiltered: (params: URLSearchParams) => requests.get(`users`, params),
    getByKeys: (userId: string) => requests.get(`users/${userId}`),
    getUserLookupData: () => requests.get('users/userlookupdata'),
    patch: (userId: string, values: any) => requests.patch(`users/${userId}`, values),
    updateuserrole: (values: any) => requests.patch(`updateuserrole`, values),
    updateuserpage: (values: any) => requests.patch(`updateuserpage`, values),
    updateusergasstation: (values: any) => requests.patch(`updateusergasstation`, values),
    post: (values: any) => requests.post(`users`, values),
    delete: (id: string) => requests.delete(`users/${id}`)
}

const Dashboard = {
    //GetTotalSalesAndQuantity: (gasStationId: string) => requests.get(`Dashboard/${store.getState().auth?.activeGasStation?.toString()}/TotalSalesAndQuantity`),
    //GetTotalSalesAndQuantityPerProduct: (gasStationId: string) => requests.get(`Dashboard/${store.getState().auth?.activeGasStation?.toString()}/TotalSalesAndQuantityPerProduct`),
    //GetPumpEndingLiters: (gasStationId: string) => requests.get(`Dashboard/${store.getState().auth?.activeGasStation?.toString()}/PumpEndingLiters`),
    getFiltered: (gasStationId: string) => requests.get(`Dashboard/${store.getState().auth?.activeGasStation?.toString()}`),

}

//const Admin = {
//    createProduct: (product: any) => requests.postForm('products', createFormData(product)),
//    updateProduct: (product: any) => requests.putForm('products', createFormData(product)),
//    deleteProduct: (id: number) => requests.delete(`products/${id}`)
//}

const TestErrors = {
    get400Error: () => requests.get('buggy/bad-request'),
    get401Error: () => requests.get('buggy/unauthorised'),
    get404Error: () => requests.get('buggy/not-found'),
    get500Error: () => requests.get('buggy/server-error'),
    getValidationError: () => requests.get('buggy/validation-error'),
}

const agent = {
    
    TestErrors,
    Auth,
    CashRegister,
    Deposit,
    Expense,
    GasStation,
    Inventory,
    Payment,
    Payroll,
    Product,
    PumpPriceChange,
    PumpTransaction,
    Supplier,
    Transaction,
    User,
    Dashboard
}

export default agent;