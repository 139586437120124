import { Typography, Grid, Paper, Box, Button } from "@mui/material";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import AppDropzone from "../../app/components/AppDropzone";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
//import useProducts from "../../app/hooks/useProducts";
import useProducts from "../../app/hooks/useProducts";
//import { Product } from "../../app/models/product";
import { Product } from "../../app/models/product";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from "./productValidation";
import agent from "../../app/api/agent";
import { useAppDispatch } from "../../app/store/configureStore";
import { setProduct } from "../products/productSlice";
import { LoadingButton } from "@mui/lab";
import AppCheckbox from "../../app/components/AppCheckbox";
import AppDatePicker from "../../app/components/AppDatePicker";
import { toast } from "react-toastify";

interface Props {
    product?: Product;
    cancelEdit: () => void;
}

export default function ProductForm({ product, cancelEdit }: Props) {
    const { control, reset, handleSubmit, watch, formState: { isDirty, isSubmitting } } = useForm({
        mode: 'all',
        resolver: yupResolver<any>(validationSchema)
    });
    //const { brands, types } = useGasStations();
    const watchFile = watch('file', null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (product && !watchFile && !isDirty) reset(product);
        return () => {
            if (watchFile) URL.revokeObjectURL(watchFile.preview);
        }
    }, [product, reset, watchFile, isDirty]);

    async function handleSubmitData(data: FieldValues) {
        let response: any;
        try {
            
            if (product) {
                response = await agent.Product.patch(data.gasStationId, data.id, data);
            } else {
                response = await agent.Product.post(data.gasStationId, data);
            }
            dispatch(setProduct(response));
            toast.success(response.message?.toString() || 'Success!');;
            cancelEdit();
        } catch (error) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred.');
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4, letterSpacing: '1px' }}>
                Product Details
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='productCode' label='Product Code' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='name' label='Name' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='description' label='Description' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='manufacturer' label='Manufacturer' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='manufacturerPartNumber' label='PN/SN' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='productType' label='Product Type' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='basePrice' label='BasePrice' />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='contained' color='inherit'>Cancel</Button>
                    <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='success'>Submit</LoadingButton>
                </Box>
            </form>
        </Box>
    )
}