export const cashRegisterExportFields = [
    { field: 'cashRegisterDate', headerName: 'CashRegisterDate' },
    { field: 'startingBalance', headerName: 'StartingBalance' },
    { field: 'endingBalance', headerName: 'EndingBalance' },
    { field: 'overage', headerName: 'Overage' },
    { field: 'shortage', headerName: 'Shortage' },
    { field: 'cashOnHand', headerName: 'CashOnHand' },
];

export const depositExportFields = [
    { field: 'depositDate', headerName: 'DepositDate' },
    { field: 'fromDate', headerName: 'FromDate' },
    { field: 'toDate', headerName: 'ToDate' },
    { field: 'amount', headerName: 'Amount' },
    { field: 'notes', headerName: 'Notes' },
];


export const expenseExportFields = [
    { field: 'expenseDate', headerName: 'ExpenseDate' },
    { field: 'or', headerName: 'OR' },
    { field: 'particulars', headerName: 'Particulars' },
    { field: 'accountTitle', headerName: 'AccountTitle' },
    { field: 'notes', headerName: 'Notes' },
];

export const paymentExportFields = [
    { field: 'tranId', headerName: 'TranId' },
    { field: 'paymentDate', headerName: 'PaymentDate' },
    { field: 'amount', headerName: 'Amount' },
    { field: 'notes', headerName: 'Notes' },
];

export const payrollExportFields = [
    { field: 'payrollDate', headerName: 'PayrollDate' },
    { field: 'fromDate', headerName: 'FromDate' },
    { field: 'toDate', headerName: 'ToDate' },
    { field: 'salaryAmount', headerName: 'SalaryAmount' },
    { field: 'notes', headerName: 'Notes' },
];

export const pumpTransactionExportFields = [
    { field: 'transactionDate', headerName: 'TransactionDate' },
    { field: 'pumpNumber', headerName: 'PumpNumber' },
    { field: 'beginningLiters', headerName: 'BeginningLiters' },
    { field: 'endingLiters', headerName: 'EndingLiters' },
    { field: 'saleAmount', headerName: 'SaleAmount' }
];

export const supplierExportFields = [
    { field: 'supplierCode', headerName: 'SupplierCode' },
    { field: 'name', headerName: 'Name' },
    { field: 'telephone', headerName: 'TelephoneNo' },
    { field: 'mobileNumber', headerName: 'MobileNo' },
    { field: 'description', headerName: 'Description' },
    { field: 'address', headerName: 'Address' }
];

export const productExportFields = [
    { field: 'productCode', headerName: 'ProductCode' },
    { field: 'name', headerName: 'Name' },
    { field: 'description', headerName: 'Description' },
    { field: 'manufacturer', headerName: 'Manufacturer' },
    { field: 'manufacturerPartNumber', headerName: 'PN/SN' },
    { field: 'productType', headerName: 'ProductType' },
    { field: 'basePrice', headerName: 'BasePrice' }
];

export const inventoryExportFields = [
    { field: 'product.name', headerName: 'Product' },
    { field: 'supplier.name', headerName: 'Supplier' },
    { field: 'quantityInStock', headerName: 'QuantityInStock' },
    { field: 'purchasePrice', headerName: 'PurchasePrice' },
    { field: 'sellingPrice', headerName: 'SellingPrice' },
    { field: 'margin', headerName: 'Margin' },
    { field: 'markUpProfit', headerName: 'MarkUpProfit' },
    { field: 'lastReorderDate', headerName: 'LastReorderDate' },
    { field: 'lastReorderQuantity', headerName: 'LastReorderQuantity' },
    { field: 'expirationDate', headerName: 'ExpirationDate' },
];

export const transactionExportFields = [
    { field: 'tranId', headerName: 'TranId' },
    { field: 'transactionDate', headerName: 'TransactionDate' },
    { field: 'inventory.product.name', headerName: 'Product' },
    { field: 'inventory.supplier.name', headerName: 'Supplier' },
    { field: 'shift', headerName: 'Shift' },
    { field: 'pumpTransaction.pumpNumber', headerName: 'PumpNumber' },
    { field: 'status', headerName: 'Status' },
    { field: 'pumpTransaction.beginningLiters', headerName: 'BeginningLiters' },
    { field: 'pumpTransaction.endingLiters', headerName: 'EndingLiters' },
    { field: 'calibration', headerName: 'Calibration' },
    { field: 'quantity', headerName: 'Quantity' },
    { field: 'cost', headerName: 'Cost' },
    { field: 'price', headerName: 'Price' },
    { field: 'pumpTransaction.saleAmount', headerName: 'SaleAmount' },
    { field: 'amountAddOn', headerName: 'AmountAddOn' },
    { field: 'poAmount', headerName: 'POAmount' },
    { field: 'discount', headerName: 'Discount' },
    { field: 'discountCard', headerName: 'DiscountCard' },
    { field: 'totalAmountExcDiscount', headerName: 'TotalAmountExcDiscount' },
    { field: 'totalAmountIncDiscount', headerName: 'TotalAmountIncDiscount' },
];
