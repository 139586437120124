import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { Deposit, DepositParams } from "../../app/models/deposit";
import { RootState } from "../../app/store/configureStore";
import { dateFormatResetParam } from "../../app/util/util";
import { Dashboard } from "../../app/models/dashboard";

interface DashboardState {
    dashboardLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    dashboardParams: any;
    metaData: Dashboard | null;
    dashboardActiveGasStation: string;
}

const dashboardAdapter = createEntityAdapter<Dashboard>();

//function getAxiosParams(depositParams: DashboardParams) {
//    const params = new URLSearchParams();
//    params.append('page', depositParams.pageNumber.toString());
//    params.append('pageSize', depositParams.pageSize.toString());
//    if (depositParams.fromDate) params.append('fromDate', depositParams.fromDate.toString());
//    if (depositParams.toDate) params.append('toDate', depositParams.toDate.toString());
//    return params;
//}


//export const getAxiosParamsForExport = (depositParams: DepositParams) => {
//    return getAxiosParams(depositParams);
//}

export const fetchDashboardAsync = createAsyncThunk<Dashboard[], void, { state: RootState }>(
    'dashboard/fetchDashboardAsync',
    async (_, thunkAPI) => {
        //const params = getAxiosParams(thunkAPI.getState().deposit.depositParams);
        const gasStationId = thunkAPI.getState()?.auth?.activeGasStation?.toString();
        try {
            const response = await agent.Dashboard.getFiltered(gasStationId || '');
            thunkAPI.dispatch(setMetaData(response));
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

//export const fetchDepositAsync = createAsyncThunk<Deposit, string, { state: RootState }>(
//    'deposit/fetchDepositAsync',
//    async (id, thunkAPI) => {
//        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
//        try {
//            return await agent.Deposit.getByKeys(gasStationId || '', id);
//        } catch (error: any) {
//            return thunkAPI.rejectWithValue({ error: error.data })
//        }
//    }
//)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 10,
        gasStationId: ''
    }
}

//export const initParamsForFilter = () => {
//    return initParams;
//}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: dashboardAdapter.getInitialState<DashboardState>({
        dashboardLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        dashboardParams: initParams(),
        metaData: null,
        dashboardActiveGasStation: ''
    }),
    reducers: {
        setDashboardParams: (state, action) => {
            state.dashboardLoaded = false;
            state.dashboardParams = { ...state.dashboardParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.dashboardLoaded = false;
            state.dashboardParams = { ...state.dashboardParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetDashboardParams: (state) => {
            state.dashboardParams = initParams();
        },
        setDashboard: (state, action) => {
            dashboardAdapter.upsertOne(state, action.payload);
            state.dashboardLoaded = false;
        },
        removeDashboard: (state, action) => {
            dashboardAdapter.removeOne(state, action.payload);
            state.dashboardLoaded = false;
        },
        setDashboardActiveGasStation: (state, action) => {
            state.dashboardActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchDashboardAsync.pending, (state) => {
            state.status = 'pendingFetchDashboard';
        });
        builder.addCase(fetchDashboardAsync.fulfilled, (state, action) => {
            dashboardAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.dashboardLoaded = true;
        });
        builder.addCase(fetchDashboardAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        //builder.addCase(fetchDepositAsync.pending, (state) => {
        //    state.status = 'pendingFetchDeposit';
        //});
        //builder.addCase(fetchDepositAsync.fulfilled, (state, action) => {
        //    depositsAdapter.upsertOne(state, action.payload);
        //    state.status = 'idle';
        //});
        //builder.addCase(fetchDepositAsync.rejected, (state, action) => {
        //    console.log(action);
        //    state.status = 'idle';
        //});
    })
})

export const dashboardSelectors = dashboardAdapter.getSelectors((state: RootState) => state.dashboard);

export const { setDashboardParams, resetDashboardParams, setMetaData, setPageNumber, setDashboard, removeDashboard, setDashboardActiveGasStation } = dashboardSlice.actions;