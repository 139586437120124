import { Typography, Grid, Paper, Box, Button, TextField } from "@mui/material";
import React, { useState, useEffect, ChangeEvent } from 'react';
import { FieldValues, useForm } from "react-hook-form";
import AppDropzone from "../../app/components/AppDropzone";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
import useProducts from "../../app/hooks/useProducts";
import useSuppliers from "../../app/hooks/useSuppliers";
import useInventories from "../../app/hooks/useInventories";
//import { Product } from "../../app/models/product";
import { Inventory, InventoryLookupData } from "../../app/models/inventory";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from "./inventoryValidation";
import agent from "../../app/api/agent";
import { useAppDispatch } from "../../app/store/configureStore";
import { setInventory, fetchFilters } from "../inventories/inventorySlice";
import { LoadingButton } from "@mui/lab";
import AppCheckbox from "../../app/components/AppCheckbox";
import AppDatePicker from "../../app/components/AppDatePicker";
import { currencyFormat, currencyFormat2, dateFormat, dateFormatReset } from "../../app/util/util";
import { toast } from "react-toastify";
import { Product } from "../../app/models/product";
import LoadingComponent from "../../app/layout/LoadingComponent";

interface Props {
    inventory?: Inventory;
    cancelEdit: () => void;
}

export default function InventoryForm({ inventory, cancelEdit }: Props) {
    const { control, reset, handleSubmit, watch, register,  formState: { isDirty, isSubmitting, errors } } = useForm({
        mode: 'all',
        resolver: yupResolver<any>(validationSchema)
    });
    
    
    const watchFile = watch('file', null);
    const dispatch = useAppDispatch();
    
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [sellingPrice, setSellingPrice] = useState(0);
    const [margin, setMargin] = useState(0);
    const [markUpProfit, setMarkUpProfit] = useState(0);
    const [lastReorderQuantity, setLastReorderQuantity] = useState(0);
    const [products, setProducts] = useState<any[] | null>(null);
    const [suppliers, setSuppliers] = useState<any[] | null>(null);
    const [loading, setLoading] = useState(true);    

    useEffect(() => {
        if (inventory && !watchFile && !isDirty) reset(inventory);
        if (inventory) {
            setPurchasePrice(inventory.purchasePrice || 0);
            setSellingPrice(inventory.sellingPrice || 0);
            setMargin(inventory.margin || 0);
            setMarkUpProfit(inventory.markUpProfit || 0);
            setLastReorderQuantity(inventory.lastReorderQuantity || 0);
        }

        agent.Inventory.getLookupData('')
            .then(data => {
                setProducts((data.data as InventoryLookupData).products);
                setSuppliers((data.data as InventoryLookupData).suppliers);
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }, [inventory, reset, watchFile, isDirty, margin, purchasePrice, sellingPrice, markUpProfit, lastReorderQuantity]);

    if (loading) return <LoadingComponent message='Loading filters...' />

    async function handleSubmitData(data: FieldValues) {
        let response: any;
        try {
            
            data.lastReorderDate = data.lastReorderDate ? dateFormatReset(data.lastReorderDate) : null;
            data.expirationDate = data.expirationDate ? dateFormatReset(data.expirationDate) : null;
            data.purchasePrice = purchasePrice || 0;
            data.sellingPrice = sellingPrice || 0;
            data.markUpProfit = markUpProfit || 0;
            data.margin = margin || 0;
            data.lastReorderQuantity = lastReorderQuantity || 0;
            if (inventory) {
                response = await agent.Inventory.patch(data.gasStationId, data.id, data);
            } else {
                response = await agent.Inventory.post(data.gasStationId, data);
            }
            dispatch(setInventory(response));
            toast.success(response.message?.toString() || 'Success!');
            cancelEdit();
        } catch (error: any) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred.');
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4, letterSpacing: '1px' }}>
                Inventory Details
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <AppSelectList items={products || null} control={control} name='productId' label='Product' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppSelectList items={suppliers || null} control={control} name='supplierId' label='Supplier' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='quantityInStock' label='Qty In Stock' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            fullWidth
                            variant='outlined'
                            label='Purchase Price'
                            {...register('purchasePrice')} 
                            value={purchasePrice}
                            onChange={(event) => {                            
                                const { value } = event.currentTarget;
                                setPurchasePrice(parseFloat(value));
                                const tempMargin = currencyFormat2(sellingPrice - parseFloat(value));
                                const tempMarkUpProfit = currencyFormat2(tempMargin * lastReorderQuantity);
                                setMargin(tempMargin);
                                setMarkUpProfit(tempMarkUpProfit);
                            }}
                            error={!!errors.purchasePrice}
                            helperText={errors.purchasePrice?.message?.toString()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            variant='outlined'
                            fullWidth
                            label='Selling Price'
                            {...register('sellingPrice')} 
                            value={sellingPrice}
                            onChange={(event) => {
                                const { value } = event.currentTarget;
                                setSellingPrice(parseFloat(value));
                                const tempMargin = currencyFormat2(parseFloat(value) - purchasePrice);
                                const tempMarkUpProfit = currencyFormat2(tempMargin * lastReorderQuantity);
                                setMargin(tempMargin);
                                setMarkUpProfit(tempMarkUpProfit);
                            }}
                            InputProps={{ readOnly: (inventory?.id?.toString() || '') !== '' ? true : false }}
                            error={!!errors.sellingPrice}
                            helperText={errors.sellingPrice?.message?.toString()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            variant='outlined'
                            name='margin'
                            fullWidth
                            value={margin}
                            label='Margin'
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            variant='outlined'
                            name='markUpProfit'
                            fullWidth
                            value={markUpProfit}
                            label='Markup Profit'
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='lastReorderDate' label='Last Reorder Date' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type='number'
                            variant='outlined'
                            name='lastReorderQuantity'
                            fullWidth
                            value={lastReorderQuantity}
                            label='Last Reorder Qty'
                            InputProps={{ readOnly: (inventory?.id || '') === '' ? false : true }}
                            onChange={(event) => {
                                const { value } = event.currentTarget;
                                setLastReorderQuantity(parseFloat(value));
                                const tempMarkUpProfit = currencyFormat2((sellingPrice - purchasePrice) * parseFloat(value));
                                setMarkUpProfit(tempMarkUpProfit);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='expirationDate' label='Expiration Date' />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='contained' color='inherit'>Cancel</Button>
                    <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='success'>Submit</LoadingButton>
                </Box>
            </form>
        </Box>
    )
}