import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from '../../app/store/configureStore';
import { signInUser } from './authSlice';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
export default function Login() {
    const history = useHistory();
    const location = useLocation<any>();
    const dispatch = useAppDispatch();
    const { register, handleSubmit, formState: { isSubmitting, errors, isValid } } = useForm({
        mode: 'all'
    });
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    async function submitForm(data: FieldValues) {
        try {
            await dispatch(signInUser(data));
            history.push(location.state?.from?.pathname || '/dashboard');
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Container component={Paper} maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 4 }}>
            {/*<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>*/}
            {/*    <LockOutlinedIcon />*/}
            {/*</Avatar>*/}
            {/*<img src="/public/fuelmonitoring.png" alt="" width="100" height="100" />*/}
            <img src="https://fuelmonitoring.codingben.com/fuelmonitoring.png" alt="" width="100" height="100" />
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit(submitForm)} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Username"
                    autoFocus
                    {...register('username', { required: 'Username is required' })}
                    error={!!errors.username}
                    //helperText={errors?.username?.message}
                />
                <FormControl fullWidth margin="normal" error={!!errors.password}>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        {...register('password', { required: 'Password is required' })}
                        label="Password"
                    />
                </FormControl>
                <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Sign In
                </LoadingButton>
                {/*<Grid container>*/}
                {/*    <Grid item>*/}
                {/*        <Link to='/register'>*/}
                {/*            {"Don't have an account? Sign Up"}*/}
                {/*        </Link>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Box>
        </Container>
    );
}