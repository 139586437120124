import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { DashboardMonthlyProduct } from '../models/dashboard';
import { useRef, useState } from 'react';

interface Props {
    data: any[];
}


const yearFormater = (date: Date) => date.getFullYear().toString();
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
}).format;
interface SeriesItem {
    label: string;
    data: number[];
    showMark: boolean;
    highlightScope: any;
}

export default function AppDashboardLine(props: Props) {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const [chartWidth, setChartWidth] = useState(750);
    const updateChartWidth = () => {
        if (chartContainerRef.current) {
            const containerWidth = chartContainerRef.current.offsetWidth;
            setChartWidth(containerWidth);
        }
    };
    const xLabels = props.data.map(item => item.month);
    //const sales = props?.data.map(item => item.totalAmount);
    //const quantity = props?.data.map(item => item.totalQuantity);

    const transformedData: { series: SeriesItem[] } = {
        series: [],
        
    };

    // Collect unique product names from all months
    const productNames = Array.from(new Set(props.data.flatMap(month => month.productSalesAndQuantity.map((product: any) => product.productName))));
    // Create series for each product
    productNames.forEach(productName => {
        const seriesItem: SeriesItem  = {
            label: productName,
            data: [],
            showMark: false,
            highlightScope: { faded: 'global', highlighted: 'item' }
        };

        // Iterate through each month and extract total amount for the current product
        props.data.forEach(monthData => {
            const productData = monthData.productSalesAndQuantity.find((product: any) => product.productName === productName);
            seriesItem.data.push(productData ? productData.totalAmount : 0);
        });

        // Add the series item to the transformed data
        transformedData.series.push(seriesItem);
    });
    React.useEffect(() => {
        updateChartWidth();
        window.addEventListener('resize', updateChartWidth);

        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, [chartContainerRef]);
    

    return (
        <div ref={chartContainerRef}>
            <LineChart       
                {...transformedData}
                xAxis={[{ data: xLabels, scaleType: 'band' }]}
                series={transformedData.series as any[]}
                width={chartWidth}
                height={350}
                />
        </div>
    );
}