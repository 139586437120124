import { useEffect } from "react";
import { pumpTransactionSelectors, fetchPumpTransactionsAsync } from "../../features/pump-transactions/pumpTransactionSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function usePumpTransactions() {
    const pumpTransactions = useAppSelector(pumpTransactionSelectors.selectAll);
    const { pumpTransactionsLoaded, filtersLoaded, metaData, pumpTransactionActiveGasStation, pumpTransactionParams } = useAppSelector(state => state.pumpTransaction);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pumpTransactionsLoaded) dispatch(fetchPumpTransactionsAsync());
        if (pumpTransactionActiveGasStation) dispatch(fetchPumpTransactionsAsync());
    }, [pumpTransactionsLoaded, pumpTransactionActiveGasStation, dispatch])

    return {
        pumpTransactions,
        pumpTransactionsLoaded,
        filtersLoaded,
        pumpTransactionActiveGasStation,
        pumpTransactionParams,
        metaData
    }
}