import { Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Tooltip, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { currencyFormat, dateFormat } from "../../app/util/util";
import useInventories from "../../app/hooks/useInventories";
import AppPagination from "../../app/components/AppPagination";
import { useAppDispatch } from "../../app/store/configureStore";
import { getAxiosParamsForExport, removeInventory, setPageNumber } from "../inventories/inventorySlice";
import { useState } from "react";
import InventoryForm from "./InventoryForm";
//import { Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import { Inventory } from "../../app/models/inventory";
import { exportToCsv, exportToExcel } from "../../app/util/exportFile";
import { inventoryExportFields } from "../../app/util/exportSettings";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InventoryFilterDrawer from "./InventoryFilterDrawer";
import { hasAdminOrWriteRole, isAdminRole } from "../auth/authSlice";
export default function Inventories() {
    const { inventories, metaData, inventoryParams } = useInventories();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedInventory, setSelectedInventory] = useState<Inventory | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState("");

    function handleSelectInventory(inventory: Inventory) {
        setSelectedInventory(inventory);
        setEditMode(true);
    }

    function handleDeleteInventory(id: string) {
        setLoading(true);
        setTarget(id);
        agent.Inventory.delete(id)
            .then(() => dispatch(removeInventory(id)))
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    function cancelEdit() {
        if (selectedInventory) setSelectedInventory(undefined);
        setEditMode(false);
    }

    function noWrapAndMinWidth() {
        return { whiteSpace: 'nowrap', maxWidth: 5 };
    }

    function noWrap() {
        return { whiteSpace: 'nowrap' };
    }
    
    function exportData() {
        let inventoriesForExport: any;
        agent.Inventory.getFilteredForExport('', getAxiosParamsForExport(inventoryParams))
            .then(data => {
                inventoriesForExport = data.items;
            })
            .catch(error => console.log(error))
            .finally(() => {
                exportToExcel(`Inventories`, inventoryExportFields, inventoriesForExport);
            });
    }

    if (editMode) return <InventoryForm inventory={selectedInventory} cancelEdit={cancelEdit} />

    return (
        <>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5' sx={{ letterSpacing: '1px' }}>Inventories ({metaData?.totalCount || 0})</Typography>
                <Box sx={{ alignItems: 'right' }}>
                    <InventoryFilterDrawer />
                    <Tooltip title="Export to Excel" placement="top">
                        <IconButton
                            size='large'
                            color='inherit'
                            onClick={exportData}
                            sx={{ ml: 'auto', fontSize: '2rem' }}
                        ><CloudDownloadIcon /></IconButton>
                    </Tooltip>
                    {hasAdminOrWriteRole() && <Tooltip title="Create Record" placement="top">
                        <IconButton
                            color='inherit'
                            onClick={() => setEditMode(true)}
                            sx={{ ml: 'auto' }}
                        ><AddCircleIcon /></IconButton>
                    </Tooltip>}
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={noWrap} >
                        <TableRow>
                            {hasAdminOrWriteRole() && <TableCell align="center">Actions</TableCell>}
                            <TableCell align="center" style={{ display: 'none' }}>Id</TableCell>
                            <TableCell align="center" style={{ display: 'none' }}>GasStationId</TableCell>
                            <TableCell align="center">Product</TableCell>
                            <TableCell align="center">Supplier</TableCell>
                            <TableCell align="center">QtyInStock</TableCell>
                            <TableCell align="center">PurchasePrice</TableCell>
                            <TableCell align="center">SellingPrice</TableCell>
                            <TableCell align="center">Margin</TableCell>
                            <TableCell align="center">MarkUpProfit</TableCell>
                            <TableCell align="center">LastReorderDate</TableCell>
                            <TableCell align="center">LastReorderQty</TableCell>
                            <TableCell align="center">ExpirationDate</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={noWrap}  >
                        {inventories.map((inventory) => (
                            inventory.id &&
                            <TableRow
                                key={inventory.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {hasAdminOrWriteRole() && <TableCell sx={noWrapAndMinWidth} align="center">
                                    <Button style={{ padding: 0 }} onClick={() => handleSelectInventory(inventory)} startIcon={<Edit />} />
                                    <LoadingButton
                                        style={{ padding: 0 }}
                                        loading={loading && target === inventory.id}
                                        startIcon={<Delete />}
                                        color='error'
                                        onClick={() => handleDeleteInventory(inventory.id || '')}
                                    />
                                </TableCell>}
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {inventory.id}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {inventory.gasStationId}
                                </TableCell>
                                <TableCell align="center">{inventory.product.name}</TableCell>
                                <TableCell align="center">{inventory.supplier.name}</TableCell>
                                <TableCell align="center">{currencyFormat(inventory.quantityInStock || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(inventory.purchasePrice || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(inventory.sellingPrice || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(inventory.margin || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(inventory.markUpProfit || 0)}</TableCell>
                                <TableCell align="center">{dateFormat(inventory.lastReorderDate?.toString() || '')}</TableCell>
                                <TableCell align="center">{currencyFormat(inventory.lastReorderQuantity || 0)}</TableCell>
                                <TableCell align="center">{dateFormat(inventory.expirationDate?.toString() || '')}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {metaData &&
                <Box sx={{ pt: 2 }}>
                    <AppPagination
                        metaData={metaData}
                        onPageChange={(page: number) => dispatch(setPageNumber({ pageNumber: page }))}
                    />
                </Box>
            }
        </>
    )
}