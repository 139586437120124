import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { PumpPriceChange, PumpPriceChangeParams } from "../../app/models/pumpPriceChange";
import { RootState } from "../../app/store/configureStore";
import { InventoryLookupData } from "../../app/models/inventory";

interface PumpPriceChangeState {
    pumpPriceChangesLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    //brands: string[];
    //types: string[];
    allInventories: any[];
    pumpPriceChangeParams: PumpPriceChangeParams;
    metaData: MetaData | null;
    pumpPriceChangeActiveGasStation: string;
}

const pumpPriceChangesAdapter = createEntityAdapter<PumpPriceChange>();

function getAxiosParams(pumpPriceChangeParams: PumpPriceChangeParams) {
    const params = new URLSearchParams();
    params.append('page', pumpPriceChangeParams.pageNumber.toString());
    params.append('pageSize', pumpPriceChangeParams.pageSize.toString());
    if (pumpPriceChangeParams.productName) params.append('productName', pumpPriceChangeParams.productName);

    return params;
}

export const fetchPumpPriceChangesAsync = createAsyncThunk<PumpPriceChange[], void, { state: RootState }>(
    'pumpPriceChange/fetchPumpPriceChangesAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().pumpPriceChange.pumpPriceChangeParams);
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            const response = await agent.PumpPriceChange.getFiltered(
                gasStationId || '',
                params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchPumpPriceChangeAsync = createAsyncThunk<PumpPriceChange, string, { state: RootState }>(
    'pumpPriceChange/fetchPumpPriceChangeAsync',
    async (id, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            return await agent.PumpPriceChange.getByKeys(gasStationId || '', id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchFilters = createAsyncThunk<InventoryLookupData, void, { state: RootState }>(
    'inventory/fetchFilters',
    async (_, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            var response = await agent.Inventory.getLookupData(gasStationId || '');
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 10,
        gasStationId: '',
        productName: ''
        //orderBy: 'name',
        //brands: [],
        //types: [],
    }
}

export const pumpPriceChangeSlice = createSlice({
    name: 'pumpPriceChange',
    initialState: pumpPriceChangesAdapter.getInitialState<PumpPriceChangeState>({
        pumpPriceChangesLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        //brands: [],
        //types: [],
        allInventories: [],
        pumpPriceChangeParams: initParams(),
        metaData: null,        
        pumpPriceChangeActiveGasStation: ''
    }),
    reducers: {
        setPumpPriceChangeParams: (state, action) => {
            state.pumpPriceChangesLoaded = false;
            state.pumpPriceChangeParams = { ...state.pumpPriceChangeParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.pumpPriceChangesLoaded = false;
            state.pumpPriceChangeParams = { ...state.pumpPriceChangeParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetPumpPriceChangeParams: (state) => {
            state.pumpPriceChangeParams = initParams();
        },
        setPumpPriceChange: (state, action) => {
            pumpPriceChangesAdapter.upsertOne(state, action.payload);
            state.pumpPriceChangesLoaded = false;
        },
        removePumpPriceChange: (state, action) => {
            pumpPriceChangesAdapter.removeOne(state, action.payload);
            state.pumpPriceChangesLoaded = false;
        },
        setPumpPriceChangeActiveGasStation: (state, action) => {
            state.pumpPriceChangeActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchPumpPriceChangesAsync.pending, (state) => {
            state.status = 'pendingFetchPumpPriceChanges';
        });
        builder.addCase(fetchPumpPriceChangesAsync.fulfilled, (state, action) => {
            pumpPriceChangesAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.pumpPriceChangesLoaded = true;
        });
        builder.addCase(fetchPumpPriceChangesAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchPumpPriceChangeAsync.pending, (state) => {
            state.status = 'pendingFetchPumpPriceChange';
        });
        builder.addCase(fetchPumpPriceChangeAsync.fulfilled, (state, action) => {
            pumpPriceChangesAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchPumpPriceChangeAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchFilters.pending, (state) => {
            state.status = 'pendingFetchFilters';
        });
        builder.addCase(fetchFilters.fulfilled, (state, action) => {
            state.allInventories = action.payload.allInventories;
            state.filtersLoaded = true;
            state.status = 'idle';
        });
        builder.addCase(fetchFilters.rejected, (state, action) => {
            state.status = 'idle';
            console.log(action.payload);
        })
    })
})

export const pumpPriceChangeSelectors = pumpPriceChangesAdapter.getSelectors((state: RootState) => state.pumpPriceChange);

export const { setPumpPriceChangeParams, resetPumpPriceChangeParams, setMetaData, setPageNumber, setPumpPriceChange, removePumpPriceChange, setPumpPriceChangeActiveGasStation } = pumpPriceChangeSlice.actions;