import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { authSlice } from "../../features/auth/authSlice";
import { gasStationSlice } from "../../features/gas-stations/gasStationSlice";
import { cashRegisterSlice } from "../../features/cash-registers/cashRegisterSlice";
import { depositSlice } from "../../features/deposits/depositSlice";
import { expenseSlice } from "../../features/expenses/expenseSlice";
import { inventorySlice } from "../../features/inventories/inventorySlice";
import { paymentSlice } from "../../features/payments/paymentSlice";
import { payrollSlice } from "../../features/payrolls/payrollSlice";
import { productSlice } from "../../features/products/productSlice";
import { pumpPriceChangeSlice } from "../../features/pump-price-changes/pumpPriceChangeSlice";
import { pumpTransactionSlice } from "../../features/pump-transactions/pumpTransactionSlice";
import { supplierSlice } from "../../features/suppliers/supplierSlice";
import { transactionSlice } from "../../features/transactions/transactionSlice";
import { userSlice } from "../../features/users/userSlice";
import { dashboardSlice } from "../../features/dashboard/dashboardSlice";

// export function configureStore() {
//     return createStore(counterReducer);
// }

export const store = configureStore({
reducer: {
        auth: authSlice.reducer,
        gasStation: gasStationSlice.reducer,
        cashRegister: cashRegisterSlice.reducer,
        deposit: depositSlice.reducer,
        expense: expenseSlice.reducer,
        inventory: inventorySlice.reducer,
        payment: paymentSlice.reducer,
        payroll: payrollSlice.reducer,
        product: productSlice.reducer,
        pumpPriceChange: pumpPriceChangeSlice.reducer,
        pumpTransaction: pumpTransactionSlice.reducer,
        supplier: supplierSlice.reducer,
        transaction: transactionSlice.reducer,
        user: userSlice.reducer,
        dashboard: dashboardSlice.reducer,

    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;