import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { Payroll, PayrollParams } from "../../app/models/payroll";
import { RootState } from "../../app/store/configureStore";

interface PayrollState {
    payrollsLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    //brands: string[];
    //types: string[];
    payrollParams: PayrollParams;
    metaData: MetaData | null;
    payrollActiveGasStation: string;
}

const payrollsAdapter = createEntityAdapter<Payroll>();

function getAxiosParams(payrollParams: PayrollParams) {
    const params = new URLSearchParams();
    params.append('page', payrollParams.pageNumber.toString());
    params.append('pageSize', payrollParams.pageSize.toString());
    if (payrollParams.fromDate) params.append('fromDate', payrollParams.fromDate.toString());
    if (payrollParams.toDate) params.append('toDate', payrollParams.toDate.toString());

    return params;
}

export const getAxiosParamsForExport = (payrollParams: PayrollParams) => {
    const params = new URLSearchParams();
    params.append('page', payrollParams.pageNumber.toString());
    params.append('pageSize', payrollParams.pageSize.toString());
    if (payrollParams.fromDate) params.append('fromDate', payrollParams.fromDate.toString());
    if (payrollParams.toDate) params.append('toDate', payrollParams.toDate.toString());

    return params;
}

export const fetchPayrollsAsync = createAsyncThunk<Payroll[], void, { state: RootState }>(
    'payroll/fetchPayrollsAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().payroll.payrollParams);
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            const response = await agent.Payroll.getFiltered(gasStationId || '', params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchPayrollAsync = createAsyncThunk<Payroll, string, { state: RootState }>(
    'payroll/fetchPayrollAsync',
    async (id, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            return await agent.Payroll.getByKeys(gasStationId || '', id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)


function initParams() {
    return {
        pageNumber: 1,
        pageSize: 10,
        gasStationId: '',
        fromDate: null,
        toDate: null
    }
}

export const initParamsForFilter = () => {
    return initParams;
}

export const payrollSlice = createSlice({
    name: 'payroll',
    initialState: payrollsAdapter.getInitialState<PayrollState>({
        payrollsLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        payrollParams: initParams(),
        metaData: null,
        payrollActiveGasStation: ''
    }),
    reducers: {
        setPayrollParams: (state, action) => {
            state.payrollsLoaded = false;
            state.payrollParams = { ...state.payrollParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.payrollsLoaded = false;
            state.payrollParams = { ...state.payrollParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetPayrollParams: (state) => {
            state.payrollParams = initParams();
        },
        setPayroll: (state, action) => {
            payrollsAdapter.upsertOne(state, action.payload);
            state.payrollsLoaded = false;
        },
        removePayroll: (state, action) => {
            payrollsAdapter.removeOne(state, action.payload);
            state.payrollsLoaded = false;
        },
        setPayrollActiveGasStation: (state, action) => {
            state.payrollActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchPayrollsAsync.pending, (state) => {
            state.status = 'pendingFetchPayrolls';
        });
        builder.addCase(fetchPayrollsAsync.fulfilled, (state, action) => {
            payrollsAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.payrollsLoaded = true;
        });
        builder.addCase(fetchPayrollsAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchPayrollAsync.pending, (state) => {
            state.status = 'pendingFetchPayroll';
        });
        builder.addCase(fetchPayrollAsync.fulfilled, (state, action) => {
            payrollsAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchPayrollAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
    })
})

export const payrollSelectors = payrollsAdapter.getSelectors((state: RootState) => state.payroll);

export const { setPayrollParams, resetPayrollParams, setMetaData, setPageNumber, setPayroll, removePayroll, setPayrollActiveGasStation } = payrollSlice.actions;