import { Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Tooltip, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { currencyFormat } from "../../app/util/util";
import useSuppliers from "../../app/hooks/useSuppliers";
import AppPagination from "../../app/components/AppPagination";
import { useAppDispatch } from "../../app/store/configureStore";
import { getAxiosParamsForExport, removeSupplier, setPageNumber } from "../suppliers/supplierSlice";
import { useState } from "react";
import SupplierForm from "./SupplierForm";
//import { Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import { Supplier } from "../../app/models/supplier";
import { exportToCsv, exportToExcel } from "../../app/util/exportFile";
import { supplierExportFields } from "../../app/util/exportSettings";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SupplierFilterDrawer from "./SupplierFilterDrawer";
import { hasAdminOrWriteRole } from "../auth/authSlice";
export default function Suppliers() {
    const { suppliers, metaData, supplierParams } = useSuppliers();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState("");

    function handleSelectSupplier(supplier: Supplier) {
        setSelectedSupplier(supplier);
        setEditMode(true);
    }

    function handleDeleteSupplier(id: string) {
        setLoading(true);
        setTarget(id);
        agent.Supplier.delete(id)
            .then(() => dispatch(removeSupplier(id)))
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    function cancelEdit() {
        if (selectedSupplier) setSelectedSupplier(undefined);
        setEditMode(false);
    }

    function noWrapAndMinWidth() {
        return { whiteSpace: 'nowrap', minWidth: 10 };
    }

    function noWrap() {
        return { whiteSpace: 'nowrap' };
    }

    function exportData() {
        let dataForExport: any;
        agent.Product.getFilteredForExport('', getAxiosParamsForExport(supplierParams))
            .then(data => {
                dataForExport = data.items;
            })
            .catch(error => console.log(error))
            .finally(() => {
                exportToExcel(`Suppliers`, supplierExportFields, dataForExport);
            });
    }

    if (editMode) return <SupplierForm supplier={selectedSupplier} cancelEdit={cancelEdit} />

    return (
        <>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5' sx={{ letterSpacing: '1px' }}>Suppliers ({metaData?.totalCount || 0})</Typography>
                <Box sx={{ alignItems: 'right' }}>
                    <SupplierFilterDrawer />
                    <Tooltip title="Export to Excel" placement="top">
                        <IconButton
                            size='large'
                            color='inherit'
                            onClick={exportData}
                            sx={{ ml: 'auto', fontSize: '2rem' }}
                        ><CloudDownloadIcon /></IconButton>
                    </Tooltip>
                    {hasAdminOrWriteRole() && <Tooltip title="Create Record" placement="top">
                        <IconButton
                            size='large'
                            color='inherit'
                            onClick={() => setEditMode(true)}
                            sx={{ ml: 'auto' }}
                        ><AddCircleIcon /></IconButton>
                    </Tooltip>}
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={noWrap} >
                        <TableRow>
                            {hasAdminOrWriteRole() && <TableCell align="center">Actions</TableCell>}
                            <TableCell align="center" style={{ display: 'none' }}>Id</TableCell>
                            <TableCell align="center">SupplierCode</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">TelephoneNo</TableCell>
                            <TableCell align="center">MobileNo</TableCell>
                            <TableCell align="center">Description</TableCell>
                            <TableCell align="center">Address</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={noWrap}  >
                        {suppliers.map((supplier) => (
                            supplier.id &&
                            <TableRow
                                key={supplier.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {hasAdminOrWriteRole() && <TableCell sx={noWrapAndMinWidth} align="center">
                                    <Button style={{ padding: 0 }} onClick={() => handleSelectSupplier(supplier)} startIcon={<Edit />} />
                                    <LoadingButton
                                        style={{ padding: 0 }}
                                        loading={loading && target === supplier.id}
                                        startIcon={<Delete />}
                                        color='error'
                                        onClick={() => handleDeleteSupplier(supplier.id || '')}
                                    />
                                </TableCell>}
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {supplier.id}
                                </TableCell>
                                <TableCell align="center">{supplier.supplierCode}</TableCell>
                                <TableCell align="center">{supplier.name}</TableCell>
                                <TableCell align="center">{supplier.telephone}</TableCell>
                                <TableCell align="center">{supplier.mobileNumber}</TableCell>
                                <TableCell align="center">{supplier.description}</TableCell>
                                <TableCell align="center">{supplier.address}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {metaData &&
                <Box sx={{ pt: 2 }}>
                    <AppPagination
                        metaData={metaData}
                        onPageChange={(page: number) => dispatch(setPageNumber({ pageNumber: page }))}
                    />
                </Box>
            }
        </>
    )
}