import { Container, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { ToastContainer } from "react-toastify";
//import AboutPage from "../../features/about/AboutPage";
//import Catalog from "../../features/catalog/Catalog";
//import ProductDetails from "../../features/catalog/ProductDetails";
//import ContactPage from "../../features/contact/ContactPage";
//import HomePage from "../../features/home/HomePage";
import Header from "./Header";
import 'react-toastify/dist/ReactToastify.css';
import ServerError from "../errors/ServerError";
import NotFound from "../errors/NotFound";
//import BasketPage from "../../features/basket/BasketPage";
import LoadingComponent from "./LoadingComponent";
import { useAppDispatch } from "../store/configureStore";
//import { fetchBasketAsync } from "../../features/basket/basketSlice";
import Login from "../../features/auth/Login";
import Register from "../../features/auth/Register";
import { fetchCurrentUser } from "../../features/auth/authSlice";
import PrivateRoute from "./PrivateRoute";
//import Orders from "../../features/orders/Orders";
//import CheckoutWrapper from "../../features/checkout/CheckoutWrapper";
//import Inventory from "../../features/admin/Inventory";
import CashRegisters from "../../features/cash-registers/CashRegisters";
import CashRegisterForm from "../../features/cash-registers/CashRegisters";
import Deposits from "../../features/deposits/Deposits";
import Expenses from "../../features/expenses/Expenses";
import GasStations from "../../features/gas-stations/GasStations";
import Inventories from "../../features/inventories/Inventories";
import Payments from "../../features/payments/Payments";
import Payrolls from "../../features/payrolls/Payrolls";
import Products from "../../features/products/Products";
import PumpPriceChanges from "../../features/pump-price-changes/PumpPriceChanges";
import PumpTransactions from "../../features/pump-transactions/PumpTransactions";
import Suppliers from "../../features/suppliers/Suppliers";
import Transactions from "../../features/transactions/Transactions";
import Users from "../../features/users/Users";
import Dashboard from "../../features/dashboard/Dashboard";

function App() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const initApp = useCallback(async () => {
    try {
      await dispatch(fetchCurrentUser());
      //await dispatch(fetchBasketAsync());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch])

  useEffect(() => {
    initApp().then(() => setLoading(false));
  }, [initApp])

  const [darkmode, setdarkmode] = useState(false);
    const paletteType = darkmode ? 'dark' : 'light'
  //const paletteType = 'dark'
  const theme = createTheme({
    palette: {
      mode: paletteType,
      background: {
        default: paletteType === 'light' ? '#eaeaea' : '#121212'
          //default: '#121212'
      },
          //primary: { main: '#3f51b5' },
          //secondary: { main: '#f50057' }
    }
  })

  function handlethemechange() {
      setdarkmode(!darkmode);
  }

  if (loading) return <LoadingComponent message='Please wait...' />

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer position='top-right' hideProgressBar theme='colored' />
      <CssBaseline />
          <Header darkMode={darkmode} handleThemeChange={handlethemechange} />
      {/*<Route exact path='/' component={HomePage} />*/}
      <Route path={'/(.+)'} render={() => (
        <Container className="main-container-custom" sx={{ mt: 4, maxWidth:'100%' }}>
        <Switch>
            <PrivateRoute exact path='/dashboard' component={Dashboard} />
            <PrivateRoute exact path='/cash-registers' component={CashRegisters} />
            {/*<PrivateRoute path='/cash-registers/:id' component={CashRegisterForm} />*/}
            <PrivateRoute exact path='/deposits' component={Deposits} />
            <PrivateRoute exact path='/expenses' component={Expenses} />
            <PrivateRoute exact path='/gas-stations' component={GasStations} />
            <PrivateRoute exact path='/inventories' component={Inventories} />
            <PrivateRoute exact path='/payments' component={Payments} />
            <PrivateRoute exact path='/payrolls' component={Payrolls} />
            <PrivateRoute exact path='/products' component={Products} />
            <PrivateRoute exact path='/pump-price-changes' component={PumpPriceChanges} />
            <PrivateRoute exact path='/pump-transactions' component={PumpTransactions} />
            <PrivateRoute exact path='/suppliers' component={Suppliers} />
            <PrivateRoute exact path='/transactions' component={Transactions} />
            <PrivateRoute exact path='/users' component={Users} />
            <PrivateRoute exact path='/dashboard' component={Dashboard} />
            {/*<Route path='/cash-registers/:id' component={ProductDetails} />*/}
            {/*<Route path='/about' component={AboutPage} />*/}
            {/*<Route path='/contact' component={ContactPage} />*/}
            <Route path='/server-error' component={ServerError} />
            {/*<Route path='/basket' component={BasketPage} />*/}
            {/*<PrivateRoute path='/checkout' component={CheckoutWrapper} />*/}
            {/*<PrivateRoute path='/orders' component={Orders} />*/}
            {/*<PrivateRoute roles={['Admin']} path='/inventory' component={Inventory} />*/}
            <Route path='/' component={Login} />
            <Route path='' component={Login} />
            <Route path='/login' component={Login} />
            <Route path='/register' component={Register} />
            <Route component={NotFound} />
          </Switch>
        </Container>
      )} />

    </ThemeProvider>
  );
}

export default App;
