import { useEffect } from "react";
import { transactionSelectors, fetchTransactionsAsync } from "../../features/transactions/transactionSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useTransactions() {
    const transactions = useAppSelector(transactionSelectors.selectAll);
    const { transactionsLoaded, filtersLoaded, metaData, allInventories, transactionActiveGasStation } = useAppSelector(state => state.transaction);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!transactionsLoaded) dispatch(fetchTransactionsAsync());
        if (transactionActiveGasStation) dispatch(fetchTransactionsAsync());
    }, [transactionsLoaded, transactionActiveGasStation, dispatch])

    return {
        transactions,
        transactionsLoaded,
        filtersLoaded,
        allInventories,
        transactionActiveGasStation,
        //types,
        metaData
    }
}