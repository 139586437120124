import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { Payment, PaymentParams } from "../../app/models/payment";
import { RootState } from "../../app/store/configureStore";
import { Transaction } from "../../app/models/transaction";

interface PaymentState {
    paymentsLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    openTransactions: any[];
    paymentParams: PaymentParams;
    metaData: MetaData | null;
    paymentActiveGasStation: string;
}

const paymentsAdapter = createEntityAdapter<Payment>();

function getAxiosParams(paymentParams: PaymentParams) {
    const params = new URLSearchParams();
    params.append('page', paymentParams.pageNumber.toString());
    params.append('pageSize', paymentParams.pageSize.toString());
    if (paymentParams.fromDate) params.append('fromDate', paymentParams.fromDate.toString());
    if (paymentParams.toDate) params.append('toDate', paymentParams.toDate.toString());
    if (paymentParams.tranId) params.append('tranId', paymentParams.tranId);
    if (paymentParams.paymentMethod) params.append('paymentMethod', paymentParams.paymentMethod);

    return params;
}

export const getAxiosParamsForExport = (paymentParams: PaymentParams) => {
    return getAxiosParams(paymentParams);
}

export const fetchPaymentsAsync = createAsyncThunk<Payment[], void, { state: RootState }>(
    'payment/fetchPaymentsAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().payment.paymentParams);
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            const response = await agent.Payment.getFiltered(gasStationId || '', params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchPaymentAsync = createAsyncThunk<Payment, string, { state: RootState }>(
    'payment/fetchPaymentAsync',
    async (id, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            return await agent.Payment.getByKeys(gasStationId || '', id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchFiltersAsync = createAsyncThunk<Transaction[], void, { state: RootState}>(
    'payment/fetchFiltersAsync',
    async (_, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            return (await agent.Transaction.getOpenTransactions(gasStationId || '')).data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 10,
        gasStationId: '',
        fromDate: null,
        toDate: null,
        tranId: '',
        paymentMethod: ''
    }
}

export const initParamsForFilter = () => {
    return initParams;
}


export const paymentSlice = createSlice({
    name: 'payment',
    initialState: paymentsAdapter.getInitialState<PaymentState>({
        paymentsLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        paymentParams: initParams(),
        metaData: null,
        openTransactions: [],
        paymentActiveGasStation: ''
    }),
    reducers: {
        setPaymentParams: (state, action) => {
            state.paymentsLoaded = false;
            state.paymentParams = { ...state.paymentParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.paymentsLoaded = false;
            state.paymentParams = { ...state.paymentParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetPaymentParams: (state) => {
            state.paymentParams = initParams();
        },
        setPayment: (state, action) => {
            paymentsAdapter.upsertOne(state, action.payload);
            state.paymentsLoaded = false;
        },
        removePayment: (state, action) => {
            paymentsAdapter.removeOne(state, action.payload);
            state.paymentsLoaded = false;
        },
        setFiltersLoaded: (state, action) => {
            state.filtersLoaded = action.payload;
        },
        setPaymentActiveGasStation: (state, action) => {
            state.paymentActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchPaymentsAsync.pending, (state) => {
            state.status = 'pendingFetchPayments';
        });
        builder.addCase(fetchPaymentsAsync.fulfilled, (state, action) => {
            paymentsAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.paymentsLoaded = true;
        });
        builder.addCase(fetchPaymentsAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchPaymentAsync.pending, (state) => {
            state.status = 'pendingFetchPayment';
        });
        builder.addCase(fetchPaymentAsync.fulfilled, (state, action) => {
            paymentsAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchPaymentAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchFiltersAsync.pending, (state) => {
            state.status = 'pendingFetchFilters';
        });
        builder.addCase(fetchFiltersAsync.fulfilled, (state, action) => {
            state.openTransactions = action.payload;
            state.filtersLoaded = true;
            state.status = 'idle';
        });
        builder.addCase(fetchFiltersAsync.rejected, (state, action) => {
            state.status = 'idle';
            console.log(action.payload);
        })
    })
})

export const paymentSelectors = paymentsAdapter.getSelectors((state: RootState) => state.payment);

export const { setPaymentParams, resetPaymentParams, setMetaData, setPageNumber, setPayment, removePayment, setFiltersLoaded, setPaymentActiveGasStation } = paymentSlice.actions;