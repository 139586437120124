import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { CashRegister, CashRegisterParams } from "../../app/models/cashRegister";
import { RootState } from "../../app/store/configureStore";
import { dateFormatResetParam } from "../../app/util/util";

interface CashRegisterState {
    cashRegistersLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    //brands: string[];
    //types: string[];
    cashRegisterParams: CashRegisterParams;
    metaData: MetaData | null;
    cashRegisterActiveGasStation: string;
}

const cashRegistersAdapter = createEntityAdapter<CashRegister>();

function getAxiosParams(cashRegisterParams: CashRegisterParams) {
    const params = new URLSearchParams();
    params.append('page', cashRegisterParams.pageNumber.toString());
    params.append('pageSize', cashRegisterParams.pageSize.toString());
    if (cashRegisterParams.fromDate) {
        params.append('fromDate', dateFormatResetParam(cashRegisterParams.fromDate?.toString()));
    }
    if (cashRegisterParams.toDate) { 
        params.append('toDate', dateFormatResetParam(cashRegisterParams.toDate?.toString()));
    }
    return params;
}

export const getAxiosParamsForExport = (cashRegisterParams: CashRegisterParams) => {
    return getAxiosParams(cashRegisterParams);
}

export const fetchCashRegistersAsync = createAsyncThunk<CashRegister[], void, { state: RootState }>(
    'cashRegister/fetchCashRegistersAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().cashRegister.cashRegisterParams);
        const gasStationId = thunkAPI.getState()?.auth?.activeGasStation?.toString();
        try {
            const response = await agent.CashRegister.getFiltered(gasStationId || '', params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchCashRegisterAsync = createAsyncThunk<CashRegister, string, { state: RootState }>(
    'cashRegister/fetchCashRegisterAsync',
    async (id, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            return await agent.CashRegister.getByKeys(gasStationId || '', id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 10,
        gasStationId: '',
        fromDate: null,
        toDate: null
    }
}

export const initParamsForFilter = () => {
    return {
        pageNumber: 1,
        pageSize: 10,
        gasStationId: '',
        fromDate: null,
        toDate: null
    }
}

export const cashRegisterSlice = createSlice({
    name: 'cashRegister',
    initialState: cashRegistersAdapter.getInitialState<CashRegisterState>({
        cashRegistersLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        //brands: [],
        //types: [],
        cashRegisterParams: initParams(),
        metaData: null,
        cashRegisterActiveGasStation: ''
    }),
    reducers: {
        setCashRegisterParams: (state, action) => {
            state.cashRegistersLoaded = false;
            state.cashRegisterParams = { ...state.cashRegisterParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.cashRegistersLoaded = false;
            state.cashRegisterParams = { ...state.cashRegisterParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetCashRegisterParams: (state) => {
            state.cashRegisterParams = initParams();
        },
        setCashRegister: (state, action) => {
            cashRegistersAdapter.upsertOne(state, action.payload);
            state.cashRegistersLoaded = false;
        },
        removeCashRegister: (state, action) => {
            cashRegistersAdapter.removeOne(state, action.payload);
            state.cashRegistersLoaded = false;
        },
        setCashRegisterActiveGasStation: (state, action) => {
            state.cashRegisterActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchCashRegistersAsync.pending, (state) => {
            state.status = 'pendingFetchCashRegisters';
        });
        builder.addCase(fetchCashRegistersAsync.fulfilled, (state, action) => {
            cashRegistersAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.cashRegistersLoaded = true;
        });
        builder.addCase(fetchCashRegistersAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchCashRegisterAsync.pending, (state) => {
            state.status = 'pendingFetchCashRegister';
        });
        builder.addCase(fetchCashRegisterAsync.fulfilled, (state, action) => {
            cashRegistersAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchCashRegisterAsync.rejected, (state, action) => {
            console.log(action);
            state.status = 'idle';
        });
        //builder.addCase(fetchFilters.pending, (state) => {
        //    state.status = 'pendingFetchFilters';
        //});
        //builder.addCase(fetchFilters.fulfilled, (state, action) => {
        //    state.brands = action.payload.brands;
        //    state.types = action.payload.types;
        //    state.filtersLoaded = true;
        //    state.status = 'idle';
        //});
        //builder.addCase(fetchFilters.rejected, (state, action) => {
        //    state.status = 'idle';
        //    console.log(action.payload);
        //})
    })
})

export const cashRegisterSelectors = cashRegistersAdapter.getSelectors((state: RootState) => state.cashRegister);

export const { setCashRegisterParams, resetCashRegisterParams, setMetaData, setPageNumber, setCashRegister, removeCashRegister, setCashRegisterActiveGasStation } = cashRegisterSlice.actions;