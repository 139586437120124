import { Typography, Grid, Paper, Box, Button, FormControl, InputLabel, InputAdornment, IconButton, OutlinedInput } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import AppDropzone from "../../app/components/AppDropzone";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
//import useProducts from "../../app/hooks/useProducts";
import useUsers from "../../app/hooks/useUsers";
//import { Product } from "../../app/models/product";
import { User } from "../../app/models/user";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from "./userValidation";
import agent from "../../app/api/agent";
import { useAppDispatch } from "../../app/store/configureStore";
import { setUser } from "../users/userSlice";
import { LoadingButton } from "@mui/lab";
import AppCheckbox from "../../app/components/AppCheckbox";
import AppDatePicker from "../../app/components/AppDatePicker";
import AppSelectMultiCheckbox from "../../app/components/AppSelectMultiCheckbox";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface Props {
    user?: User;
    cancelEdit: () => void;
}

export default function UserForm({ user, cancelEdit }: Props) {
    const { control, reset, handleSubmit, watch, formState: { isDirty, isSubmitting, errors } } = useForm({
        mode: 'all',
        resolver: yupResolver<any>(validationSchema)
    });
    const { gasStations, roles, pages } = useUsers();
    const watchFile = watch('file', null);
    const dispatch = useAppDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (user && !watchFile && !isDirty) reset(user);
        return () => {
            if (watchFile) URL.revokeObjectURL(watchFile.preview);
        }
    }, [user, reset, watchFile, isDirty]);

    async function handleSubmitData(data: any) {
        let response: any;
        try {
            if (typeof (data.roles) === 'string') {
                let tempRoles = [];
                tempRoles.push(data.roles);
                data.roles = tempRoles;
            } 
            if (user) {
                response = await agent.User.patch(data.id, data);
            } else {
                response = await agent.User.post(data);
            }
            dispatch(setUser(response));
            toast.success(response.message?.toString() || 'Success!');
            cancelEdit();
        } catch (error) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred.');
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4, letterSpacing: '1px' }}>
                User Details
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <AppSelectMultiCheckbox items={gasStations} control={control} name='gasStations' label='GasStations' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppSelectList items={roles} control={control} name='roles' label='Roles' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppSelectMultiCheckbox items={pages} control={control} name='pages' label='Pages' />
                    </Grid>                    
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='userName' label='Username' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='firstName' label='First Name' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='lastName' label='Last Name' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='email' label='Email' />
                    </Grid>
                    {(user?.id === '' || user?.id === undefined || user?.id === null) && <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='password' label='Password' />
                        {/*<FormControl fullWidth >*/}
                        {/*    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>*/}
                        {/*    <OutlinedInput*/}
                        {/*        id="outlined-adornment-password"*/}
                        {/*        type={showPassword ? 'text' : 'password'}*/}
                        {/*        name="password"*/}
                        {/*        endAdornment={*/}
                        {/*            <InputAdornment position="end">*/}
                        {/*                <IconButton*/}
                        {/*                    aria-label="toggle password visibility"*/}
                        {/*                    onClick={handleClickShowPassword}*/}
                        {/*                    onMouseDown={handleMouseDownPassword}*/}
                        {/*                    edge="end"*/}
                        {/*                >*/}
                        {/*                    {showPassword ? <VisibilityOff /> : <Visibility />}*/}
                        {/*                </IconButton>*/}
                        {/*            </InputAdornment>*/}
                        {/*        }*/}
                        {/*        label="Password"*/}
                        {/*    />*/}
                        {/*</FormControl>*/}
                    </Grid>}
                    {(user?.id === '' || user?.id === undefined || user?.id === null) && <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='confirmPassword' label='Confirm Password' />
                        {/*<FormControl fullWidth >*/}
                        {/*    <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>*/}
                        {/*    <OutlinedInput*/}
                        {/*        id="outlined-adornment-confirm-password"*/}
                        {/*        type={showConfirmPassword ? 'text' : 'password'}*/}
                        {/*        name="confirmPassword"*/}
                        {/*        endAdornment={*/}
                        {/*            <InputAdornment position="end">*/}
                        {/*                <IconButton*/}
                        {/*                    aria-label="toggle confirm password visibility"*/}
                        {/*                    onClick={handleClickShowConfirmPassword}*/}
                        {/*                    onMouseDown={handleMouseDownConfirmPassword}*/}
                        {/*                    edge="end"*/}
                        {/*                >*/}
                        {/*                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}*/}
                        {/*                </IconButton>*/}
                        {/*            </InputAdornment>*/}
                        {/*        }*/}
                        {/*        label="Confirm Password"*/}
                        {/*    />*/}
                        {/*</FormControl>*/}
                    </Grid>}
                </Grid>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='contained' color='inherit'>Cancel</Button>
                    <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='success'>Submit</LoadingButton>
                </Box>
            </form>
        </Box>
    )
}