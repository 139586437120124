import { Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Tooltip, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { currencyFormat, dateFormat } from "../../app/util/util";
import usePayrolls from "../../app/hooks/usePayrolls";
import AppPagination from "../../app/components/AppPagination";
import { useAppDispatch } from "../../app/store/configureStore";
import { getAxiosParamsForExport, removePayroll, setPageNumber } from "../payrolls/payrollSlice";
import { useState } from "react";
import PayrollForm from "./PayrollForm";
//import { Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import { Payroll } from "../../app/models/payroll";
import { exportToExcel } from "../../app/util/exportFile";
import { payrollExportFields } from "../../app/util/exportSettings";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PayrollFilterDrawer from "./PayrollFilterDrawer";
import { hasAdminOrWriteRole } from "../auth/authSlice";
export default function Payrolls() {
    const { payrolls, metaData, payrollParams } = usePayrolls();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedPayroll, setSelectedPayroll] = useState<Payroll | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState("");

    function handleSelectPayroll(payroll: Payroll) {
        setSelectedPayroll(payroll);
        setEditMode(true);
    }

    function handleDeletePayroll(id: string) {
        setLoading(true);
        setTarget(id);
        agent.Payroll.delete(id)
            .then(() => dispatch(removePayroll(id)))
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    function cancelEdit() {
        if (selectedPayroll) setSelectedPayroll(undefined);
        setEditMode(false);
    }

    function noWrapAndMinWidth() {
        return { whiteSpace: 'nowrap', maxWidth: 5 };
    }

    function noWrap() {
        return { whiteSpace: 'nowrap' };
    }

    function exportData() {
        let payrollsForExport: any;
        agent.Payroll.getFilteredForExport('', getAxiosParamsForExport(payrollParams))
            .then(data => {
                payrollsForExport = data.items;
            })
            .catch(error => console.log(error))
            .finally(() => {
                exportToExcel(`Payrolls`, payrollExportFields, payrollsForExport);
            });
    }

    if (editMode) return <PayrollForm payroll={selectedPayroll} cancelEdit={cancelEdit} />

    return (
        <>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5' sx={{ letterSpacing: '1px' }}>Payrolls ({metaData?.totalCount || 0})</Typography>
                <Box sx={{ alignItems: 'right' }}>
                    <PayrollFilterDrawer />
                    <Tooltip title="Export to Excel" placement="top">
                        <IconButton
                            size='large'
                            color='inherit'
                            onClick={exportData}
                            sx={{ ml: 'auto', fontSize: '2rem' }}
                        ><CloudDownloadIcon /></IconButton>
                    </Tooltip>
                    {hasAdminOrWriteRole() && <Tooltip title="Create Record" placement="top">
                        <IconButton
                            color='inherit'
                            size='large'
                            onClick={() => setEditMode(true)}
                            sx={{ ml: 'auto' }}
                        ><AddCircleIcon /></IconButton>
                    </Tooltip>}
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={noWrap} >
                        <TableRow>
                            {hasAdminOrWriteRole() && <TableCell align="center">Actions</TableCell>}
                            <TableCell align="center" style={{ display: 'none' }}>Id</TableCell>
                            <TableCell align="center" style={{ display: 'none' }}>GasStationId</TableCell>
                            <TableCell align="center">PayrollDate</TableCell>
                            <TableCell align="center">FromDate</TableCell>
                            <TableCell align="center">ToDate</TableCell>
                            <TableCell align="center">SalaryAmount</TableCell>
                            <TableCell align="center">Notes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={noWrap}  >
                        {payrolls.map((payroll) => (
                            payroll.id &&
                            <TableRow
                                key={payroll.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {hasAdminOrWriteRole() && <TableCell sx={noWrapAndMinWidth} align="center">
                                    <Button style={{ padding: 0 }} onClick={() => handleSelectPayroll(payroll)} startIcon={<Edit />} />
                                    <LoadingButton
                                        style={{ padding: 0 }}
                                        loading={loading && target === payroll.id}
                                        startIcon={<Delete />}
                                        color='error'
                                        onClick={() => handleDeletePayroll(payroll.id || '')}
                                    />
                                </TableCell>}
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {payroll.id}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {payroll.gasStationId}
                                </TableCell>
                                <TableCell sx={noWrap} align="center">{dateFormat(payroll.payrollDate?.toString() || '')}</TableCell>
                                <TableCell sx={noWrap} align="center">{dateFormat(payroll.fromDate?.toString() || '')}</TableCell>
                                <TableCell sx={noWrap} align="center">{dateFormat(payroll.toDate?.toString() || '')}</TableCell>
                                <TableCell align="center">{currencyFormat(payroll.salaryAmount || 0)}</TableCell>
                                <TableCell align="center">{payroll.notes || ''}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {metaData &&
                <Box sx={{ pt: 2 }}>
                    <AppPagination
                        metaData={metaData}
                        onPageChange={(page: number) => dispatch(setPageNumber({ pageNumber: page }))}
                    />
                </Box>
            }
        </>
    )
}