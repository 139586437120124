import { Typography, Grid, Paper, Box, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import AppDropzone from "../../app/components/AppDropzone";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
//import useProducts from "../../app/hooks/useProducts";
import usePayments from "../../app/hooks/usePayments";
//import { Product } from "../../app/models/product";
import { Payment } from "../../app/models/payment";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from "./paymentValidation";
import agent from "../../app/api/agent";
import { useAppDispatch } from "../../app/store/configureStore";
import { setFiltersLoaded, setPayment } from "../payments/paymentSlice";
import { LoadingButton } from "@mui/lab";
import AppCheckbox from "../../app/components/AppCheckbox";
import AppDatePicker from "../../app/components/AppDatePicker";
import { currencyFormat, dateFormat, dateFormatReset } from "../../app/util/util";
import { toast } from "react-toastify";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { Transaction } from "../../app/models/transaction";
interface Props {
    payment?: Payment;
    cancelEdit: () => void;
}

export default function PaymentForm({ payment, cancelEdit }: Props) {
    const { control, reset, handleSubmit, register, watch, formState: { isDirty, isSubmitting, errors } } = useForm({
        mode: 'all',
        resolver: yupResolver<any>(validationSchema)
    });
    //const { openTransactions } = usePayments();
    const watchFile = watch('file', null);
    const [transactionId, setTransactionId] = useState('');
    const [tranId, setTranId] = useState('');
    const [openTransactions, setOpenTransactions] = useState<any[] | null>(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    setFiltersLoaded(false);
    useEffect(() => {
        if (payment && !watchFile && !isDirty) reset(payment);

        if (payment) {
            setTransactionId(payment.transactionId?.toString() || '');
            setTranId(payment.tranId || '');
        }
        agent.Transaction.getOpenTransactions('')
            .then(data => {
                setOpenTransactions(data.data);
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false));

        return () => {
            if (watchFile) URL.revokeObjectURL(watchFile.preview);
        }
    }, [payment, reset, watchFile, isDirty, transactionId]);
    if (loading) return <LoadingComponent message='Loading filters...' />
    async function handleSubmitData(data: FieldValues) {
        let response: any;
        try {
            
            data.paymentDate = dateFormatReset(data.paymentDate);
            data.transactionId = transactionId;
            const tranId = openTransactions?.filter(a => a.id === transactionId) || '';
            data.tranId = tranId.length > 0 ? tranId[0].tranId : '';

            if (payment) {
                response = await agent.Payment.patch(data.gasStationId, data.id, data);
            } else {
                response = await agent.Payment.post(data.gasStationId, data);
            }
            dispatch(setPayment(response));
            toast.success(response.message?.toString() || 'Success!');
            cancelEdit();
        } catch (error) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred.');
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4, letterSpacing: '1px' }}>
                Payment Details
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        {/*<AppSelectList items={openTransactions} control={control} name='transactionId' label='Tran Id' />*/}
                        <FormControl fullWidth error={!!errors.transactionId} >
                            <InputLabel>Tran Id</InputLabel>
                            <Select
                                value={transactionId}
                                variant='outlined'
                                {...register('transactionId')} 
                                onChange={(event) => {
                                    const { value } = event.target;
                                    //alert(value);
                                    setTransactionId(value);                                    
                                }}
                            >
                                {openTransactions?.map((item) => (
                                    <MenuItem key={item.id?.toString()} value={item.id?.toString()}>{`${item.tranId}`}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.transactionId?.message?.toString()}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='paymentDate' label='Payment Date' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='paymentMethod' label='Payment Method' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='amount' label='Amount' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='notes' label='Notes' />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='contained' color='inherit'>Cancel</Button>
                    <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='success'>Submit</LoadingButton>
                </Box>
            </form>
        </Box>
    )
}