import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useDashboard from '../../app/hooks/useDashboard';
import { useAppDispatch } from '../../app/store/configureStore';
import { useState } from 'react';
import { currencyFormat } from '../../app/util/util';
import BasicPie from '../../app/components/AppDashboardPie';
import PieColor from '../../app/components/AppDashboardPieColor';
import { Card, CardContent, Container, Typography } from '@mui/material';
import { alignProperty } from '@mui/material/styles/cssUtils';
import { AlignHorizontalCenter } from '@mui/icons-material';
import BiaxialBarChart from '../../app/components/AppDashboardBarBi';
import AppDashboardLine from '../../app/components/AppDashboardLine';
import Formatting from '../../app/components/AppDashboardLineCustom';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 100,
    lineHeight: '35px',
    fontSize: '30px',
    padding: '5%'
}));

const cardTotalAmount1 = {
    backgroundColor: '#90EE90'
};

const cardTotalQuantity = {
    backgroundColor: '#87CEEB'
};
const cardTotalAmount = {
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'auto',
};
const DashboardCard = ({
    title, todaySales, todayQuantity, thisWeekSales, thisWeekQty, thisMonthSales, thisMonthQty, thisYearSales, thisYearQty }: any) => (
    <Grid item xs={12} sm={3}>
        {/*<Typography variant="h6">Totals</Typography>*/}
        <Paper elevation={24} sx={cardTotalAmount}>
            <Typography variant="body1">Today Sales: {currencyFormat(todaySales)}</Typography>
            <Typography variant="body1">Today Qty Sold: {currencyFormat(todayQuantity)}</Typography>
            <Typography variant="body1">This Week Sales: {currencyFormat(thisWeekSales)}</Typography>
            <Typography variant="body1">This Week Qty Sold: {currencyFormat(thisWeekQty)}</Typography>
            <Typography variant="body1">This Month Sales: {currencyFormat(thisMonthSales)}</Typography>
            <Typography variant="body1">This Month Qty Sold: {currencyFormat(thisMonthQty)}</Typography>
            <Typography variant="body1">This Year Sales: {currencyFormat(thisYearSales)}</Typography>
            <Typography variant="body1">This Year Qty Sold: {currencyFormat(thisYearQty)}</Typography>
        </Paper>
    </Grid>
);

const sxCardHeader = { padding: '5px', marginLeft: '-90%', marginTop: '-10%', letterSpacing: '1px' };
const sxCardHeader2 = { padding: '5px', marginLeft: '-55%', marginTop: '-10%', letterSpacing: '1px' };
const sxCardHeader3 = { padding: '5px', marginLeft: '-65%', marginTop: '-10%', letterSpacing: '1px' };
const sxCardHeaderPump = { padding: '5px', letterSpacing: '1px' };

export default function Dashboard() {

    const { dashboard, metaData, dashboardParams } = useDashboard();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const temp = [
        {
            "month": "January",
            "totalQuantity": 50,
            "totalAmount": 5000
        },
        {
            "month": "February",
            "totalQuantity": 30,
            "totalAmount": 3000
        },
        {
            "month": "March",
            "totalQuantity": 12,
            "totalAmount": 1500
        },
        {
            "month": "April",
            "totalQuantity": 100,
            "totalAmount": 15000
        },
        {
            "month": "May",
            "totalQuantity": 22,
            "totalAmount": 2399
        },
        {
            "month": "June",
            "totalQuantity": 190,
            "totalAmount": 30004
        },
        {
            "month": "July",
            "totalQuantity": 5,
            "totalAmount": 200
        },
        {
            "month": "August",
            "totalQuantity": 309,
            "totalAmount": 45000
        },
        {
            "month": "September",
            "totalQuantity": 100,
            "totalAmount": 10599
        },
        {
            "month": "October",
            "totalQuantity": 34,
            "totalAmount": 5699
        },
        {
            "month": "November",
            "totalQuantity": 100,
            "totalAmount": 11434
        },
        {
            "month": "December",
            "totalQuantity": 78,
            "totalAmount": 6987
        }
    ]

    const tempPerProduct = [
        {
            "month": "January",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 6100
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 4400
                }
            ]
        },
        {
            "month": "February",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 8100
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 8400
                }
            ]
        },
        {
            "month": "March",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 3100
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 1400
                }
            ]
        },
        {
            "month": "April",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 5100
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 4400
                }
            ]
        },
        {
            "month": "May",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 2100
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 4400
                }
            ]
        },
        {
            "month": "June",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 6100
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 4400
                }
            ]
        },
        {
            "month": "July",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 6100
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 2400
                }
            ]
        },
        {
            "month": "August",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 4100
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 8400
                }
            ]
        },
        {
            "month": "September",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 5100
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 5400
                }
            ]
        },
        {
            "month": "October",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 3100
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 1400
                }
            ]
        },
        {
            "month": "November",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 2100
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 3400
                }
            ]
        },
        {
            "month": "December",
            "productSalesAndQuantity": [
                {
                    "productName": "TURBO DIESEL",
                    "totalQuantity": 1,
                    "totalAmount": 1300
                },
                {
                    "productName": "XCS",
                    "totalQuantity": 4,
                    "totalAmount": 4200
                }
            ]
        }
    ]
    const tempEndingLiters = [
        {
            productName: "DIESEL",
            key: "1",
            value: 2099
        },
        {
            productName: "GASOLINE",
            key: "2",
            value: 2099
        },
        {
            productName: "DIESEL",
            key: "3",
            value: 2099
        },
        {
            productName: "DIESEL",
            key: "4",
            value: 2099
        },

    ];
    return (
        <Box
            sx={{
                p: 2,
                borderRadius: 2,
                bgcolor: 'background.default',
                display: 'grid',                
                gap: 6,
            }}
        >   
            
            {/*{metaData?.pumpEndingLiters.perPumpToday && metaData.pumpEndingLiters.perPumpToday.length > 0 && (*/}
            {/*    <Grid container spacing={2}>*/}
            {/*        {metaData.pumpEndingLiters.perPumpToday.map((data) => (*/}
            {/*            <Grid item xs={12} sm={3} key={data.key}>*/}
            {/*                <Item elevation={12} sx={cardTotalAmount} style={{ backgroundColor: '#DAB6FC' }}>*/}
            {/*                    <Container>*/}
            {/*                        <Typography variant="h6" sx={sxCardHeader2}>Pump: {data.key}</Typography>                                    */}
            {/*                        <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Product: {data.productName}</Typography>*/}
            {/*                        <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Product: {data.value}</Typography>*/}
            {/*                    </Container>*/}
            {/*                    */}{/*<Container>*/}
            {/*                    */}{/*    <Typography variant="h6" sx={sxCardHeader}>&nbsp;</Typography>*/}
            {/*                    */}{/*    <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Total Liters Sold</Typography>*/}
            {/*                    */}{/*    {currencyFormat(metaData.totalSalesAndQuantity.thisWeek.totalQuantity || 123.3)}*/}
            {/*                    */}{/*</Container>*/}
            {/*                </Item>*/}
            {/*            </Grid>*/}
            {/*        ))}*/}
            {/*    </Grid>*/}
            {/*)}*/}
            {tempEndingLiters?.length > 0 && (
                <Grid container spacing={2}>
                    {tempEndingLiters.map((data) => (
                        <Grid item xs={12} sm={3} key={data.key}>
                            <Item elevation={12} sx={cardTotalAmount} style={{ backgroundColor: '#90EE90' }}>
                                <Container>
                                    <Typography variant="h6" sx={sxCardHeaderPump}>Pump {data.key} [{data.productName}]</Typography>
                                    <Typography variant="body2" sx={{ letterSpacing: '1px' }}></Typography>
                                    <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Current Liters </Typography>
                                    {currencyFormat(data.value)}
                                </Container>
                                {/*<Container>*/}
                                {/*    <Typography variant="h6" sx={sxCardHeader}>&nbsp;</Typography>*/}
                                {/*    <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Total Liters Sold</Typography>*/}
                                {/*    {currencyFormat(metaData.totalSalesAndQuantity.thisWeek.totalQuantity || 123.3)}*/}
                                {/*</Container>*/}
                            </Item>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Grid container spacing={2}>
               
                <Grid item xs={12} sm={3}>       
                    <Item key='todayTotalSales' elevation={12} sx={cardTotalAmount} style={{ backgroundColor:'#90EE90'}} >                        
                        <Container>
                            <Typography variant="h6" sx={sxCardHeader}>Today</Typography>
                            <Typography variant="body2" sx={{letterSpacing:'1px'}}>Total Sales</Typography>
                            {currencyFormat(metaData?.totalSalesAndQuantity.today.totalAmount || 1323.44)}
                        </Container>
                        <Container>
                            <Typography variant="h6" sx={sxCardHeader}>&nbsp;</Typography>
                            <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Total Liters Sold</Typography>
                            {currencyFormat(metaData?.totalSalesAndQuantity.today.totalQuantity || 123.3)}
                        </Container>
                    </Item>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Item key='todayTotalSales' elevation={12} sx={cardTotalAmount} style={{ backgroundColor: '#DAB6FC' }}>
                        <Container>
                            <Typography variant="h6" sx={sxCardHeader2}>This Week</Typography>
                            <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Total Sales</Typography>
                            {currencyFormat(metaData?.totalSalesAndQuantity.thisWeek.totalAmount || 1323.44)}
                        </Container>
                        <Container>
                            <Typography variant="h6" sx={sxCardHeader}>&nbsp;</Typography>
                            <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Total Liters Sold</Typography>
                            {currencyFormat(metaData?.totalSalesAndQuantity.thisWeek.totalQuantity || 123.3)}
                        </Container>
                    </Item>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Item key='todayTotalSales' elevation={12} sx={cardTotalAmount} style={{ backgroundColor: '#A3D9FF' }}>
                        <Container>
                            <Typography variant="h6" sx={sxCardHeader2}>This Month</Typography>
                            <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Total Sales</Typography>
                            {currencyFormat(metaData?.totalSalesAndQuantity.thisMonth.totalAmount || 1323.44)}
                        </Container>
                        <Container>
                            <Typography variant="h6" sx={sxCardHeader}>&nbsp;</Typography>
                            <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Total Liters Sold</Typography>
                            {currencyFormat(metaData?.totalSalesAndQuantity.thisMonth.totalQuantity || 123.3)}
                        </Container>
                    </Item>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Item key='todayTotalSales' elevation={12} sx={cardTotalAmount} style={{ backgroundColor: '#DCFFFD' }}>
                        <Container>
                            <Typography variant="h6" sx={sxCardHeader3}>This Year</Typography>
                            <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Total Sales</Typography>
                            {currencyFormat(metaData?.totalSalesAndQuantity.thisYear.totalAmount || 1323.44)}
                        </Container>
                        <Container>
                            <Typography variant="h6" sx={sxCardHeader}>&nbsp;</Typography>
                            <Typography variant="body2" sx={{ letterSpacing: '1px' }}>Total Liters Sold</Typography>
                            {currencyFormat(metaData?.totalSalesAndQuantity.thisYear.totalQuantity || 123.3)}
                        </Container>
                    </Item>
                </Grid>
                {/*<Formatting></Formatting>*/}
                {/*<AppDashboardLine data={metaData?.totalSalesAndQuantityPerProduct.thisYearMonthly as any[]} />*/}
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' sx={{ letterSpacing: '1px' }}>This Year</Typography>
                    <Container maxWidth="xl" ><AppDashboardLine data={tempPerProduct} /></Container>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' sx={{ letterSpacing: '1px' }}>Last Year</Typography>
                    <Container maxWidth="xl" ><AppDashboardLine data={tempPerProduct} /></Container>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/*<BiaxialBarChart data={metaData?.totalSalesAndQuantity.thisYearMonthly || temp}></BiaxialBarChart>*/}
                    <Typography variant='h6' sx={{ letterSpacing: '1px' }}>This Year</Typography>
                    <Container maxWidth="xl" ><BiaxialBarChart data={temp} /></Container>
                    
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/*<BiaxialBarChart data={metaData?.totalSalesAndQuantity.thisYearMonthly || temp}></BiaxialBarChart>*/}
                    <Typography variant='h6' sx={{ letterSpacing: '1px' }}>Last Year</Typography>
                    <Container maxWidth="xl"><BiaxialBarChart data={temp} /></Container>

                </Grid>
                {/*<DashboardCard*/}
                {/*    title="Today (Total)"*/}
                {/*    todaySales={metaData?.totalSalesAndQuantity.today.totalAmount || 0}*/}
                {/*    todayQty={metaData?.totalSalesAndQuantity.today.totalQuantity || 0}*/}
                {/*    thisWeekSales={metaData?.totalSalesAndQuantity.today.totalQuantity || 0}*/}
                {/*    thisWeekQty={metaData?.totalSalesAndQuantity.today.totalQuantity || 0}*/}
                {/*    thisMonthSales={metaData?.totalSalesAndQuantity.today.totalQuantity || 0}*/}
                {/*    thisMonthQty={metaData?.totalSalesAndQuantity.today.totalQuantity || 0}*/}
                {/*    thisYearSales={metaData?.totalSalesAndQuantity.today.totalQuantity || 0}*/}
                {/*    thisYearQty={metaData?.totalSalesAndQuantity.today.totalQuantity || 0}*/}
                {/*/>*/}
                
                
                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <PieColor*/}
                {/*        colorPallete={['#80ed99', '#90e0ef']}*/}
                {/*        label='This Week'*/}
                {/*        totalQuantity={currencyFormat(metaData?.totalSalesAndQuantity.thisWeek.totalQuantity || 123.5)}*/}
                {/*        totalSales={currencyFormat(metaData?.totalSalesAndQuantity.thisWeek.totalAmount || 12)}*/}
                {/*    ></PieColor> */}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <PieColor*/}
                {/*        colorPallete={['#80ed99', '#90e0ef']}*/}
                {/*        label='This Month'*/}
                {/*        totalQuantity={currencyFormat(metaData?.totalSalesAndQuantity.thisMonth.totalQuantity || 123.5)}*/}
                {/*        totalSales={currencyFormat(metaData?.totalSalesAndQuantity.thisMonth.totalAmount || 12)}*/}
                {/*    ></PieColor> */}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <PieColor*/}
                {/*        colorPallete={['#80ed99', '#90e0ef']}*/}
                {/*        label='This Year'*/}
                {/*        totalQuantity={currencyFormat(metaData?.totalSalesAndQuantity.thisYear.totalQuantity || 123.5)}*/}
                {/*        totalSales={currencyFormat(metaData?.totalSalesAndQuantity.thisYear.totalAmount || 12)}*/}
                {/*    ></PieColor> */}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={3}>*/}
                {/*    <Typography>Today (Total)</Typography>*/}
                {/*    <Item key='todayTotalAmount' elevation={12} sx={cardTotalAmount} >                        */}
                {/*        Sales: {currencyFormat(metaData?.totalSalesAndQuantity.today.totalAmount || 0)}<br />*/}
                {/*        Qty Sold: {currencyFormat(metaData?.totalSalesAndQuantity.today.totalQuantity || 0)}*/}
                {/*    </Item>*/}
                {/*</Grid>*/}
                {/*<DashboardCard*/}
                {/*    title="Today (Total)"*/}
                {/*    sales={metaData?.totalSalesAndQuantity.today.totalAmount || 0}*/}
                {/*    quantity={metaData?.totalSalesAndQuantity.today.totalQuantity || 0}*/}
                {/*/>*/}
                {/*<Grid item xs={12} sm={3}>*/}
                {/*    <Item key='todayTotalQuantity' elevation={12} sx={cardTotalQuantity}>*/}
                {/*        {currencyFormat(metaData?.totalSalesAndQuantity.today.totalQuantity || 0)}*/}
                {/*    </Item>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={3}>*/}
                {/*    <Item key='thisWeekTotalAmount' elevation={12} sx={cardTotalAmount}>*/}
                {/*        {currencyFormat(metaData?.totalSalesAndQuantity.today.totalAmount || 0)}*/}
                {/*    </Item>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={3}>*/}
                {/*    <Item key='thisWeekTotalQuantity' elevation={12} sx={cardTotalQuantity}>*/}
                {/*        {currencyFormat(metaData?.totalSalesAndQuantity.today.totalQuantity || 0)}*/}
                {/*    </Item>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={3}>*/}
                {/*    <Item key='thisMonthTotalAmount' elevation={12} sx={cardTotalAmount}>*/}
                {/*        {currencyFormat(metaData?.totalSalesAndQuantity.today.totalAmount || 0)}*/}
                {/*    </Item>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={3}>*/}
                {/*    <Item key='thisMonthTotalQuantity' elevation={12} sx={cardTotalQuantity}>*/}
                {/*        {currencyFormat(metaData?.totalSalesAndQuantity.today.totalQuantity || 0)}*/}
                {/*    </Item>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={3}>*/}
                {/*    <Item key='thisYearTotalAmount' elevation={12} sx={cardTotalAmount}>*/}
                {/*        {currencyFormat(metaData?.totalSalesAndQuantity.today.totalAmount || 0)}*/}
                {/*    </Item>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={3}>*/}
                {/*    <Item key='thisYearTotalQuantity' elevation={12} sx={cardTotalQuantity}>*/}
                {/*        {currencyFormat(metaData?.totalSalesAndQuantity.today.totalQuantity || 0)}*/}
                {/*    </Item>*/}
                {/*</Grid>*/}
            </Grid>           
        </Box>

    );
}