import { Typography, Grid, Paper, Box, Button } from "@mui/material";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import AppDropzone from "../../app/components/AppDropzone";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
//import useProducts from "../../app/hooks/useProducts";
import useGasStations from "../../app/hooks/useGasStations";
//import { Product } from "../../app/models/product";
import { GasStation } from "../../app/models/gasStation";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from "./gasStationValidation";
import agent from "../../app/api/agent";
import { useAppDispatch } from "../../app/store/configureStore";
import { setGasStation } from "../gas-stations/gasStationSlice";
import { LoadingButton } from "@mui/lab";
import AppCheckbox from "../../app/components/AppCheckbox";
import { toast } from "react-toastify";

interface Props {
    gasStation?: GasStation;
    cancelEdit: () => void;
}

export default function GasStationForm({ gasStation, cancelEdit }: Props) {
    const { control, reset, handleSubmit, watch, formState: { isDirty, isSubmitting } } = useForm({
        mode: 'all',
        resolver: yupResolver<any>(validationSchema)
    });
    //const { brands, types } = useGasStations();
    const watchFile = watch('file', null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (gasStation && !watchFile && !isDirty) reset(gasStation);
        return () => {
            if (watchFile) URL.revokeObjectURL(watchFile.preview);
        }
    }, [gasStation, reset, watchFile, isDirty]);

    async function handleSubmitData(data: FieldValues) {
        let response: any;
        try {
            
            if (gasStation) {
                response = await agent.GasStation.patch(data.id, data);
            } else {
                response = await agent.GasStation.post(data);
            }
            dispatch(setGasStation(response));
            toast.success(response.message?.toString() || 'Success!');
            cancelEdit();
        } catch (error: any) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred.');
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4, letterSpacing: '1px' }}>
                Gas Station Details
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='name' label='Name' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='address' label='Address' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='mobileNumber' label='Mobile No.' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='telephoneNumber' label='Telephone No.' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='description' label='Description' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppCheckbox
                            disabled={false}
                            name='isActive'
                            label='Is Active'
                            control={control}
                        />
                    </Grid>
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <AppSelectList items={brands} control={control} name='brand' label='Brand' />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <AppSelectList items={types} control={control} name='type' label='Type' />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <AppTextInput type='number' control={control} name='price' label='Price' />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <AppTextInput type='number' control={control} name='quantityInStock' label='Quantity in Stock' />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}>*/}
                    {/*    <AppTextInput multiline={true} rows={4} control={control} name='description' label='Description' />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}>*/}
                    {/*    <Box display='flex' justifyContent='space-between' alignItems='center'>*/}
                    {/*        <AppDropzone control={control} name='file' />*/}
                    {/*        {watchFile ? (*/}
                    {/*            <img src={watchFile.preview} alt='preview' style={{ maxHeight: 200 }} />*/}
                    {/*        ) : (*/}
                    {/*            <img src={product?.pictureUrl} alt={product?.name} style={{ maxHeight: 200 }} />*/}
                    {/*        )}*/}
                    {/*    </Box>*/}

                    {/*</Grid>*/}
                </Grid>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='contained' color='inherit'>Cancel</Button>
                    <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='success'>Submit</LoadingButton>
                </Box>
            </form>
        </Box>
    )
}