import { Typography, Grid, Paper, Box, Button } from "@mui/material";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import AppDropzone from "../../app/components/AppDropzone";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
//import useProducts from "../../app/hooks/useProducts";
import useDeposits from "../../app/hooks/useDeposits";
//import { Product } from "../../app/models/product";
import { Deposit } from "../../app/models/deposit";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from "./depositValidation";
import agent from "../../app/api/agent";
import { useAppDispatch } from "../../app/store/configureStore";
import { setDeposit } from "../deposits/depositSlice";
import { LoadingButton } from "@mui/lab";
import AppCheckbox from "../../app/components/AppCheckbox";
import AppDatePicker from "../../app/components/AppDatePicker";
import { currencyFormat, dateFormat, dateFormatReset } from "../../app/util/util";
import { toast } from "react-toastify";

interface Props {
    deposit?: Deposit;
    cancelEdit: () => void;
}

export default function DepositForm({ deposit, cancelEdit }: Props) {
    const { control, reset, handleSubmit, watch, formState: { isDirty, isSubmitting } } = useForm({
        mode: 'all',
        resolver: yupResolver<any>(validationSchema)
    });
    //const { brands, types } = useGasStations();
    const watchFile = watch('file', null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (deposit && !watchFile && !isDirty) reset(deposit);
        return () => {
            if (watchFile) URL.revokeObjectURL(watchFile.preview);
        }
    }, [deposit, reset, watchFile, isDirty]);

    async function handleSubmitData(data: FieldValues) {
        let response: any;
        try {
            
            data.depositDate = dateFormatReset(data.depositDate);
            if (data.fromDate) data.fromDate = dateFormatReset(data.fromDate);
            if (data.toDate) data.toDate = dateFormatReset(data.toDate);

            if (deposit) {
                response = await agent.Deposit.patch(data.gasStationId, data.id, data);
            } else {
                response = await agent.Deposit.post(data.gasStationId, data);
            }
            dispatch(setDeposit(response));
            toast.success(response.message?.toString() || 'Success!');
            cancelEdit();
        } catch (error: any) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred.');
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4, letterSpacing: '1px' }}>
                Deposit Details
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='depositDate' label='Deposit Date' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='fromDate' label='From Date' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='toDate' label='To Date' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='amount' label='Amount' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='notes' label='Notes' />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='contained' color='inherit'>Cancel</Button>
                    <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='success'>Submit</LoadingButton>
                </Box>
            </form>
        </Box>
    )
}