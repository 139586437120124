import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { Expense, ExpenseParams } from "../../app/models/expense";
import { MetaData } from "../../app/models/pagination";
import { RootState } from "../../app/store/configureStore";
import { dateFormatResetParam } from "../../app/util/util";

interface ExpenseState {
    expensesLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    expenseParams: ExpenseParams;
    metaData: MetaData | null;
    expenseActiveGasStation: string;
}

const expensesAdapter = createEntityAdapter<Expense>();

function getAxiosParams(expenseParams: ExpenseParams) {
    const params = new URLSearchParams();
    params.append('page', expenseParams.pageNumber.toString());
    params.append('pageSize', expenseParams.pageSize.toString());
    if (expenseParams.fromDate) params.append('fromDate', expenseParams.fromDate.toString());
    if (expenseParams.toDate) params.append('toDate', expenseParams.toDate.toString());
    if (expenseParams.or) params.append('or', expenseParams.or);
    if (expenseParams.particulars) params.append('particulars', expenseParams.particulars);
    if (expenseParams.accountTitle) params.append('accountTitle', expenseParams.accountTitle);
    return params;
}

export const getAxiosParamsForExport = (expenseParams: ExpenseParams) => {
    return getAxiosParams(expenseParams);
}

export const fetchExpensesAsync = createAsyncThunk<Expense[], void, { state: RootState }>(
    'expense/fetchExpensesAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().expense.expenseParams);
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            const response = await agent.Expense.getFiltered(gasStationId || '', params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchExpenseAsync = createAsyncThunk<Expense, string, { state: RootState }>(
    'expense/fetchExpenseAsync',
    async (id, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            return await agent.Expense.getByKeys(gasStationId || '', id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)


function initParams() {
    return {
        pageNumber: 1,
        pageSize: 10,
        gasStationId: '',
        fromDate: null,
        toDate: null,
        or: '',
        particulars: '',
        accountTitle: ''
    }
}

export const initParamsForFilter = () =>  {
    return initParams;
}

export const expenseSlice = createSlice({
    name: 'expense',
    initialState: expensesAdapter.getInitialState<ExpenseState>({
        expensesLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        expenseParams: initParams(),
        metaData: null,
        expenseActiveGasStation: ''
    }),
    reducers: {
        setExpenseParams: (state, action) => {
            state.expensesLoaded = false;
            state.expenseParams = { ...state.expenseParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.expensesLoaded = false;
            state.expenseParams = { ...state.expenseParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetExpenseParams: (state) => {
            state.expenseParams = initParams();
        },
        setExpense: (state, action) => {
            expensesAdapter.upsertOne(state, action.payload);
            state.expensesLoaded = false;
        },
        removeExpense: (state, action) => {
            expensesAdapter.removeOne(state, action.payload);
            state.expensesLoaded = false;
        },
        setExpenseActiveGasStation: (state, action) => {
            state.expenseActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchExpensesAsync.pending, (state) => {
            state.status = 'pendingFetchExpenses';
        });
        builder.addCase(fetchExpensesAsync.fulfilled, (state, action) => {
            expensesAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.expensesLoaded = true;
        });
        builder.addCase(fetchExpensesAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchExpenseAsync.pending, (state) => {
            state.status = 'pendingFetchExpense';
        });
        builder.addCase(fetchExpenseAsync.fulfilled, (state, action) => {
            expensesAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchExpenseAsync.rejected, (state, action) => {
            console.log(action);
            state.status = 'idle';
        });
        //builder.addCase(fetchFilters.pending, (state) => {
        //    state.status = 'pendingFetchFilters';
        //});
        //builder.addCase(fetchFilters.fulfilled, (state, action) => {
        //    state.brands = action.payload.brands;
        //    state.types = action.payload.types;
        //    state.filtersLoaded = true;
        //    state.status = 'idle';
        //});
        //builder.addCase(fetchFilters.rejected, (state, action) => {
        //    state.status = 'idle';
        //    console.log(action.payload);
        //})
    })
})

export const expenseSelectors = expensesAdapter.getSelectors((state: RootState) => state.expense);

export const { setExpenseParams, resetExpenseParams, setMetaData, setPageNumber, setExpense, removeExpense, setExpenseActiveGasStation } = expenseSlice.actions;