import { useEffect } from "react";
import { payrollSelectors, fetchPayrollsAsync } from "../../features/payrolls/payrollSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function usePayrolls() {
    const payrolls = useAppSelector(payrollSelectors.selectAll);
    const { payrollsLoaded, filtersLoaded, metaData, payrollActiveGasStation, payrollParams } = useAppSelector(state => state.payroll);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!payrollsLoaded) dispatch(fetchPayrollsAsync());
        if (payrollActiveGasStation) dispatch(fetchPayrollsAsync());
    }, [payrollsLoaded, payrollActiveGasStation, dispatch])

    return {
        payrolls,
        payrollsLoaded,
        filtersLoaded,
        payrollActiveGasStation,
        payrollParams,
        metaData
    }
}