import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { GasStation, GasStationParams } from "../../app/models/gasStation";
import { RootState } from "../../app/store/configureStore";

interface GasStationState {
    gasStationsLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    //brands: string[];
    //types: string[];
    gasStationParams: GasStationParams;
    metaData: MetaData | null;
}

const gasStationsAdapter = createEntityAdapter<GasStation>();

function getAxiosParams(gasStationParams: GasStationParams) {
    const params = new URLSearchParams();
    params.append('page', gasStationParams.pageNumber.toString());
    params.append('pageSize', gasStationParams.pageSize.toString());
    if (gasStationParams.name) params.append('name', gasStationParams.name);
    if (gasStationParams.isActive) params.append('isActive', gasStationParams.isActive.toString());
    return params;
}

export const fetchGasStationsAsync = createAsyncThunk<GasStation[], void, { state: RootState }>(
    'gasStation/fetchGasStationsAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().gasStation.gasStationParams);
        try {
            const response = await agent.GasStation.getFiltered(params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchGasStationAsync = createAsyncThunk<GasStation, string>(
    'gasStation/fetchGasStationAsync',
    async (gasStationId, thunkAPI) => {
        try {
            return await agent.GasStation.getByKeys(gasStationId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 10,
        name: '',
        isActive: true
        //orderBy: 'name',
        //brands: [],
        //types: []
    }
}

export const gasStationSlice = createSlice({
    name: 'gasStation',
    initialState: gasStationsAdapter.getInitialState<GasStationState>({
        gasStationsLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        //brands: [],
        //types: [],
        gasStationParams: initParams(),
        metaData: null
    }),
    reducers: {
        setGasStationParams: (state, action) => {
            state.gasStationsLoaded = false;
            state.gasStationParams = { ...state.gasStationParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.gasStationsLoaded = false;
            state.gasStationParams = { ...state.gasStationParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetGasStationParams: (state) => {
            state.gasStationParams = initParams();
        },
        setGasStation: (state, action) => {
            gasStationsAdapter.upsertOne(state, action.payload);
            state.gasStationsLoaded = false;
        },
        removeGasStation: (state, action) => {
            gasStationsAdapter.removeOne(state, action.payload);
            state.gasStationsLoaded = false;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchGasStationsAsync.pending, (state) => {
            state.status = 'pendingFetchGasStations';
        });
        builder.addCase(fetchGasStationsAsync.fulfilled, (state, action) => {
            gasStationsAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.gasStationsLoaded = true;
        });
        builder.addCase(fetchGasStationsAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchGasStationAsync.pending, (state) => {
            state.status = 'pendingFetchGasStation';
        });
        builder.addCase(fetchGasStationAsync.fulfilled, (state, action) => {
            gasStationsAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchGasStationAsync.rejected, (state, action) => {
            console.log(action);
            state.status = 'idle';
        });
        //builder.addCase(fetchFilters.pending, (state) => {
        //    state.status = 'pendingFetchFilters';
        //});
        //builder.addCase(fetchFilters.fulfilled, (state, action) => {
        //    state.brands = action.payload.brands;
        //    state.types = action.payload.types;
        //    state.filtersLoaded = true;
        //    state.status = 'idle';
        //});
        //builder.addCase(fetchFilters.rejected, (state, action) => {
        //    state.status = 'idle';
        //    console.log(action.payload);
        //})
    })
})

export const gasStationSelectors = gasStationsAdapter.getSelectors((state: RootState) => state.gasStation);

export const { setGasStationParams, resetGasStationParams, setMetaData, setPageNumber, setGasStation, removeGasStation } = gasStationSlice.actions;