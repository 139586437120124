export interface MetaData {
    currentPage: number | null;
    totalPages: number | null;
    pageSize: number | null;
    totalCount: number | null;
}

export class PaginatedResponse<T> {
    items: T;
    metaData: MetaData;

    constructor(items: T, metaData: MetaData) {
        this.items = items;
        this.metaData = metaData;
    }
}