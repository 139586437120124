import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { Transaction, TransactionParams } from "../../app/models/transaction";
import { RootState } from "../../app/store/configureStore";
import { PumpTransaction } from "../../app/models/pumpTransaction";
import { InventoryLookupData } from "../../app/models/inventory";

interface TransactionState {
    transactionsLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    liters: PumpTransaction | null;
    allInventories: any[];
    //types: string[];
    transactionParams: TransactionParams;
    metaData: MetaData | null;
    transactionActiveGasStation: string;
}

const transactionsAdapter = createEntityAdapter<Transaction>();

function getAxiosParams(transactionParams: TransactionParams) {
    const params = new URLSearchParams();
    params.append('page', transactionParams.pageNumber.toString());
    params.append('pageSize', transactionParams.pageSize.toString());
    if (transactionParams.transactionDate) params.append('transactionDate', transactionParams.transactionDate.toString());
    if (transactionParams.transactionType) params.append('transactionType', transactionParams.transactionType);
    if (transactionParams.productName) params.append('productName', transactionParams.productName);
    if (transactionParams.supplierName) params.append('supplierName', transactionParams.supplierName);
    if (transactionParams.shift) params.append('shift', transactionParams.shift);
    if (transactionParams.pumpNumber) params.append('pumpNumber', transactionParams.pumpNumber);
    if (transactionParams.paymentMethod) params.append('paymentMethod', transactionParams.paymentMethod);
    return params;
}

export const fetchTransactionsAsync = createAsyncThunk<Transaction[], void, { state: RootState }>(
    'transaction/fetchTransactionsAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().transaction.transactionParams);
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            const response = await agent.Transaction.getFiltered(gasStationId || '', params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchTransactionAsync = createAsyncThunk<Transaction, string, { state: RootState }>(
    'transaction/fetchTransactionAsync',
    async (id, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            return await agent.Transaction.getByKeys(gasStationId || '', id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchLitersAsync = createAsyncThunk<PumpTransaction, { inventoryId: string, pumpNumber: string }>(
    'pumptransactions/fetchLitersAsync',
    async({inventoryId, pumpNumber}, thunkAPI) => {
        try {
            return (await agent.PumpTransaction.getLiters(inventoryId, pumpNumber)).data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchFilters = createAsyncThunk<InventoryLookupData, void, { state: RootState }>(
    'inventory/fetchFilters',
    async (_, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            var response = await agent.Inventory.getLookupData(gasStationId || '');
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 50,
        gasStationId: '',
        transactionDate: null,
        transactionType: '',
        productName: '',
        supplierName: '',
        shift: '',
        pumpNumber: '',
        paymentMethod: '',
        //orderBy: 'name',
        //brands: [],
        //types: [],
    }
}

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState: transactionsAdapter.getInitialState<TransactionState>({
        transactionsLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        liters: null,
        allInventories: [],
        //types: [],
        transactionParams: initParams(),
        metaData: null,
        transactionActiveGasStation: ''
    }),
    reducers: {
        setTransactionParams: (state, action) => {
            state.transactionsLoaded = false;
            state.transactionParams = { ...state.transactionParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.transactionsLoaded = false;
            state.transactionParams = { ...state.transactionParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetTransactionParams: (state) => {
            state.transactionParams = initParams();
        },
        setTransaction: (state, action) => {
            transactionsAdapter.upsertOne(state, action.payload);
            state.transactionsLoaded = false;
        },
        removeTransaction: (state, action) => {
            transactionsAdapter.removeOne(state, action.payload);
            state.transactionsLoaded = false;
        },
        setFiltersLoaded: (state, action) => {
            state.filtersLoaded = action.payload;
        },
        setTransactionActiveGasStation: (state, action) => {
            state.transactionActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchTransactionsAsync.pending, (state) => {
            state.status = 'pendingFetchTransactions';
        });
        builder.addCase(fetchTransactionsAsync.fulfilled, (state, action) => {
            transactionsAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.transactionsLoaded = true;
        });
        builder.addCase(fetchTransactionsAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchTransactionAsync.pending, (state) => {
            state.status = 'pendingFetchTransaction';
        });
        builder.addCase(fetchTransactionAsync.fulfilled, (state, action) => {
            transactionsAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchTransactionAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchFilters.pending, (state) => {
            state.status = 'pendingFetchFilters';
        });
        builder.addCase(fetchFilters.fulfilled, (state, action) => {
            //state.liters = action.payload;
            state.allInventories = action.payload.allInventories;
            state.filtersLoaded = true;
            state.status = 'idle';
        });
        builder.addCase(fetchFilters.rejected, (state, action) => {
            state.status = 'idle';
        })
    })
})

export const transactionSelectors = transactionsAdapter.getSelectors((state: RootState) => state.transaction);

export const { setTransactionParams, resetTransactionParams, setMetaData, setPageNumber, setTransaction, removeTransaction, setFiltersLoaded, setTransactionActiveGasStation } = transactionSlice.actions;