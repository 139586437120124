import { useEffect } from "react";
import { cashRegisterSelectors, fetchCashRegistersAsync } from "../../features/cash-registers/cashRegisterSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useCashRegisters() {
    const cashRegisters = useAppSelector(cashRegisterSelectors.selectAll);
    const { cashRegistersLoaded, filtersLoaded, metaData, cashRegisterActiveGasStation, cashRegisterParams } = useAppSelector(state => state.cashRegister);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!cashRegistersLoaded) dispatch(fetchCashRegistersAsync());
        if (cashRegisterActiveGasStation) dispatch(fetchCashRegistersAsync());
    }, [cashRegistersLoaded, cashRegisterActiveGasStation,  dispatch])

    return {
        cashRegisters,
        cashRegistersLoaded,
        filtersLoaded,
        cashRegisterActiveGasStation,
        cashRegisterParams,
        //brands,
        //types,
        metaData
    }
}