import { useEffect } from "react";
import { dashboardSelectors, fetchDashboardAsync } from "../../features/dashboard/dashboardSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useDashboard() {
    const dashboard = useAppSelector(dashboardSelectors.selectAll);
    const { dashboardLoaded, filtersLoaded, metaData, dashboardActiveGasStation, dashboardParams } = useAppSelector(state => state.dashboard);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!dashboardLoaded) dispatch(fetchDashboardAsync());
        if (dashboardActiveGasStation) dispatch(fetchDashboardAsync());
    }, [dashboardLoaded, dashboardActiveGasStation, dispatch])

    return {
        dashboard,
        dashboardLoaded,
        filtersLoaded,
        dashboardActiveGasStation,
        dashboardParams,
        metaData
    }
}