import { useController, UseControllerProps } from "react-hook-form";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
//dayjs.extend(utc);
//dayjs.extend(timezone);
//dayjs.tz.setDefault('Asia/Taipei');
interface Props extends UseControllerProps {
    label: string;
}

export default function AppDatePicker(props: Props) {
    

    const { fieldState, field } = useController({ ...props, defaultValue: '' })
    const val = field.value !== '' && field.value !== null && field.value !== undefined ? dayjs(field.value) : null;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer sx={{ display: 'flex', paddingTop: '0px' }} components={['DatePicker']}>
                <DatePicker
                    sx={{ display: 'flex' }}
                    {...props}
                    {...field}
                    label={props.label}
                    value={val}
                    slotProps={{ textField: { fullWidth: true, error: !!fieldState.error, helperText: fieldState.error?.message  } }}
                    //format='LL'
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}