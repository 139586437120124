import { useEffect } from "react";
import { gasStationSelectors, fetchGasStationsAsync } from "../../features/gas-stations/gasStationSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useGasStations() {
    const gasStations = useAppSelector(gasStationSelectors.selectAll);
    const { gasStationsLoaded, filtersLoaded, metaData } = useAppSelector(state => state.gasStation);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!gasStationsLoaded) dispatch(fetchGasStationsAsync());
    }, [gasStationsLoaded, dispatch])

    return {
        gasStations,
        gasStationsLoaded,
        filtersLoaded,
        //brands,
        //types,
        metaData
    }
}