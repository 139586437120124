import { useEffect } from "react";
import { depositSelectors, fetchDepositsAsync } from "../../features/deposits/depositSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useDeposits() {
    const deposits = useAppSelector(depositSelectors.selectAll);
    const { depositsLoaded, filtersLoaded, metaData, depositActiveGasStation, depositParams } = useAppSelector(state => state.deposit);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!depositsLoaded) dispatch(fetchDepositsAsync());
        if (depositActiveGasStation) dispatch(fetchDepositsAsync());
    }, [depositsLoaded, depositActiveGasStation, dispatch])

    return {
        deposits,
        depositsLoaded,
        filtersLoaded,
        depositActiveGasStation,
        depositParams,
        metaData
    }
}