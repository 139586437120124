import { useEffect } from "react";
import { paymentSelectors, fetchPaymentsAsync, fetchFiltersAsync } from "../../features/payments/paymentSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function usePayments() {
    const payments = useAppSelector(paymentSelectors.selectAll);
    const { paymentsLoaded, filtersLoaded, metaData, openTransactions, paymentActiveGasStation, paymentParams } = useAppSelector(state => state.payment);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!paymentsLoaded) dispatch(fetchPaymentsAsync());
        if (paymentActiveGasStation) dispatch(fetchPaymentsAsync());
    }, [paymentsLoaded, paymentActiveGasStation, dispatch])

    useEffect(() => {
        if (!filtersLoaded) dispatch(fetchFiltersAsync());
    }, [filtersLoaded, dispatch]);

    return {
        payments,
        paymentsLoaded,
        filtersLoaded,
        openTransactions,
        paymentActiveGasStation,
        metaData,
        paymentParams
    }
}