import { useEffect } from "react";
import { productSelectors, fetchProductsAsync } from "../../features/products/productSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useProducts() {
    const products = useAppSelector(productSelectors.selectAll);
    const { productsLoaded, filtersLoaded, metaData, productActiveGasStation, productParams } = useAppSelector(state => state.product);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!productsLoaded) dispatch(fetchProductsAsync());
        if (productActiveGasStation) dispatch(fetchProductsAsync());
    }, [productsLoaded, productActiveGasStation, dispatch])

    return {
        products,
        productsLoaded,
        filtersLoaded,
        productActiveGasStation,
        productParams, 
        metaData
    }
}