import { Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Tooltip, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { currencyFormat } from "../../app/util/util";
import useUsers from "../../app/hooks/useUsers";
import AppPagination from "../../app/components/AppPagination";
import { useAppDispatch } from "../../app/store/configureStore";
import { removeUser, setPageNumber } from "../users/userSlice";
import { useState } from "react";
import UserForm from "./UserForm";
//import { Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import { User } from "../../app/models/user";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isAdminRole } from "../auth/authSlice";
export default function Users() {
    const { users, metaData } = useUsers();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState("");

    function handleSelectUser(user: User) {
        setSelectedUser(user);
        setEditMode(true);
    }

    function handleDeleteUser(id: string) {
        setLoading(true);
        setTarget(id);
        agent.User.delete(id)
            .then(() => dispatch(removeUser(id)))
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    function cancelEdit() {
        if (selectedUser) setSelectedUser(undefined);
        setEditMode(false);
    }
    function noWrapAndMinWidth() {
        return { whiteSpace: 'nowrap', maxWidth: 5 };
    }

    function noWrap() {
        return { whiteSpace: 'nowrap' };
    }

    if (editMode) return <UserForm user={selectedUser} cancelEdit={cancelEdit} />

    return (
        <>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5' sx={{ letterSpacing: '1px' }}>Users ({metaData?.totalCount || 0})</Typography>
                <Box sx={{ alignItems: 'right' }}>
                    {/*<Tooltip title="Export to Excel" placement="top">*/}
                    {/*    <IconButton*/}
                    {/*        size='large'*/}
                    {/*        color='inherit'*/}
                    {/*        onClick={exportData}*/}
                    {/*        sx={{ ml: 'auto', fontSize: '2rem' }}*/}
                    {/*    ><CloudDownloadIcon /></IconButton>*/}
                    {/*</Tooltip>*/}
                    {isAdminRole() && <Tooltip title="Create Record" placement="top">
                        <IconButton
                            color='inherit'
                            onClick={() => setEditMode(true)}
                            sx={{ ml: 'auto' }}
                        ><AddCircleIcon /></IconButton>
                    </Tooltip>}
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={noWrap} >
                        <TableRow>
                            {isAdminRole() && <TableCell align="center">Actions</TableCell>}
                            <TableCell align="center" style={{ display: 'none' }}>Id</TableCell>
                            <TableCell align="center">Username</TableCell>
                            <TableCell align="center">FirstName</TableCell>
                            <TableCell align="center">LastName</TableCell>
                            <TableCell align="center">Email</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            user.id &&
                            <TableRow
                                key={user.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {isAdminRole() && <TableCell sx={noWrapAndMinWidth} align="center">
                                    <Button style={{ padding: 0 }} onClick={() => handleSelectUser(user)} startIcon={<Edit />} />
                                    <LoadingButton
                                        style={{ padding: 0 }}
                                        loading={loading && target === user.id}
                                        startIcon={<Delete />}
                                        color='error'
                                        onClick={() => handleDeleteUser(user.id || '')}
                                    />
                                </TableCell>}
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {user.id}
                                </TableCell>
                                <TableCell align="center">{user.userName}</TableCell>
                                <TableCell align="center">{user.firstName}</TableCell>
                                <TableCell align="center">{user.lastName}</TableCell>
                                <TableCell align="center">{user.email}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {metaData &&
                <Box sx={{ pt: 2 }}>
                    <AppPagination
                        metaData={metaData}
                        onPageChange={(page: number) => dispatch(setPageNumber({ pageNumber: page }))}
                    />
                </Box>
            }
        </>
    )
}