import { useEffect } from "react";
import { pumpPriceChangeSelectors, fetchPumpPriceChangesAsync } from "../../features/pump-price-changes/pumpPriceChangeSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function usePumpPriceChanges() {
    const pumpPriceChanges = useAppSelector(pumpPriceChangeSelectors.selectAll);
    const { pumpPriceChangesLoaded, filtersLoaded, metaData, pumpPriceChangeActiveGasStation } = useAppSelector(state => state.pumpPriceChange);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!pumpPriceChangesLoaded) dispatch(fetchPumpPriceChangesAsync());
        if (pumpPriceChangeActiveGasStation) dispatch(fetchPumpPriceChangesAsync());
    }, [pumpPriceChangesLoaded, pumpPriceChangeActiveGasStation, dispatch])

    return {
        pumpPriceChanges,
        pumpPriceChangesLoaded,
        filtersLoaded,
        pumpPriceChangeActiveGasStation,
        //brands,
        //types,
        metaData
    }
}