import { Typography, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { MetaData } from "../models/pagination";

interface Props {
    metaData: MetaData;
    onPageChange: (page: number) => void;
}

export default function AppPagination({metaData, onPageChange}: Props) {
    const {currentPage, totalCount, totalPages, pageSize} = metaData;
    const [pageNumber, setPageNumber] = useState(currentPage);

    function handlePageChange(page: number) {
        setPageNumber(page);
        onPageChange(page);
    } 

    return (
        <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography>
                Displaying {((currentPage || 0) - 1) * (pageSize || 0) + ((pageSize || 0) === 0 ? 0 :1)}-
                {(currentPage || 0)*(pageSize || 0) > (totalCount || 0) 
                    ? totalCount 
                    : (currentPage || 0)*(pageSize || 0)} of {totalCount} items
            </Typography>
            <Pagination
                size='small'
                count={totalPages || 0}
                page={(pageNumber || 0)}
                onChange={(e, page) => handlePageChange(page)}
            />
        </Box>
    )
}