import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { Deposit, DepositParams } from "../../app/models/deposit";
import { RootState } from "../../app/store/configureStore";
import { dateFormatResetParam } from "../../app/util/util";

interface DepositState {
    depositsLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    //brands: string[];
    //types: string[];
    depositParams: DepositParams;
    metaData: MetaData | null;
    depositActiveGasStation: string;
}

const depositsAdapter = createEntityAdapter<Deposit>();

function getAxiosParams(depositParams: DepositParams) {
    const params = new URLSearchParams();
    params.append('page', depositParams.pageNumber.toString());
    params.append('pageSize', depositParams.pageSize.toString());
    if (depositParams.fromDate) params.append('fromDate', depositParams.fromDate.toString());
    if (depositParams.toDate) params.append('toDate', depositParams.toDate.toString());
    return params;
}


export const getAxiosParamsForExport = (depositParams: DepositParams) => {
    return getAxiosParams(depositParams);
}

export const fetchDepositsAsync = createAsyncThunk<Deposit[], void, { state: RootState }>(
    'deposit/fetchDepositsAsync',
    async (_, thunkAPI) => {        
        const params = getAxiosParams(thunkAPI.getState().deposit.depositParams);
        const gasStationId = thunkAPI.getState()?.auth?.activeGasStation?.toString();
        try {
            const response = await agent.Deposit.getFiltered(gasStationId || '', params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: []};
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchDepositAsync = createAsyncThunk<Deposit, string, { state: RootState }>(
    'deposit/fetchDepositAsync',
    async (id, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            return await agent.Deposit.getByKeys(gasStationId || '', id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 10,
        gasStationId: '',
        fromDate: null,
        toDate: null
    }
}

export const initParamsForFilter = () =>  {
    return initParams;
}

export const depositSlice = createSlice({
    name: 'deposit',
    initialState: depositsAdapter.getInitialState<DepositState>({
        depositsLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        depositParams: initParams(),
        metaData: null,
        depositActiveGasStation: ''
    }),
    reducers: {
        setDepositParams: (state, action) => {
            state.depositsLoaded = false;
            state.depositParams = { ...state.depositParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.depositsLoaded = false;
            state.depositParams = { ...state.depositParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetDepositParams: (state) => {
            state.depositParams = initParams();
        },
        setDeposit: (state, action) => {
            depositsAdapter.upsertOne(state, action.payload);
            state.depositsLoaded = false;
        },
        removeDeposit: (state, action) => {
            depositsAdapter.removeOne(state, action.payload);
            state.depositsLoaded = false;
        },
        setDepositActiveGasStation: (state, action) => {
            state.depositActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchDepositsAsync.pending, (state) => {
            state.status = 'pendingFetchDeposits';
        });
        builder.addCase(fetchDepositsAsync.fulfilled, (state, action) => {
            depositsAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.depositsLoaded = true;
        });
        builder.addCase(fetchDepositsAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchDepositAsync.pending, (state) => {
            state.status = 'pendingFetchDeposit';
        });
        builder.addCase(fetchDepositAsync.fulfilled, (state, action) => {
            depositsAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchDepositAsync.rejected, (state, action) => {
            console.log(action);
            state.status = 'idle';
        });
    })
})

export const depositSelectors = depositsAdapter.getSelectors((state: RootState) => state.deposit);

export const { setDepositParams, resetDepositParams, setMetaData, setPageNumber, setDeposit, removeDeposit, setDepositActiveGasStation } = depositSlice.actions;