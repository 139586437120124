import { useEffect } from "react";
import { expenseSelectors, fetchExpensesAsync } from "../../features/expenses/expenseSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useExpenses() {
    const expenses = useAppSelector(expenseSelectors.selectAll);
    const { expensesLoaded, filtersLoaded, metaData, expenseActiveGasStation, expenseParams } = useAppSelector(state => state.expense);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!expensesLoaded) dispatch(fetchExpensesAsync());
        if (expenseActiveGasStation) dispatch(fetchExpensesAsync());
    }, [expensesLoaded, expenseActiveGasStation, dispatch])

    return {
        expenses,
        expensesLoaded,
        filtersLoaded,
        expenseActiveGasStation,
        expenseParams,
        metaData
    }
}