import { Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Tooltip, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { currencyFormat, dateFormat } from "../../app/util/util";
import useTransactions from "../../app/hooks/useTransactions";
import AppPagination from "../../app/components/AppPagination";
import { useAppDispatch } from "../../app/store/configureStore";
import { removeTransaction, setPageNumber } from "../transactions/transactionSlice";
import { useState } from "react";
import TransactionForm from "./TransactionForm";
//import { Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import { Transaction } from "../../app/models/transaction";
import { exportToCsv, exportToExcel } from "../../app/util/exportFile";
import { transactionExportFields } from "../../app/util/exportSettings";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { hasAdminOrWriteRole } from "../auth/authSlice";
export default function Transactions() {
    const { transactions, metaData } = useTransactions();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState<Transaction | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState("");

    function handleSelectTransaction(transaction: Transaction) {
        setSelectedTransaction(transaction);
        setEditMode(true);
    }

    function handleDeleteTransaction(id: string) {
        setLoading(true);
        setTarget(id);
        agent.Transaction.delete(id)
            .then(() => dispatch(removeTransaction(id)))
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    function cancelEdit() {
        if (selectedTransaction) setSelectedTransaction(undefined);
        setEditMode(false);
    }
    function noWrapAndMinWidth() {
        return { whiteSpace: 'nowrap', maxWidth: 5 };
    }

    function noWrap() {
        return { whiteSpace: 'nowrap' };
    }

    function exportData() {
        exportToExcel(`Transactions`, transactionExportFields, transactions);
    }

    if (editMode) return <TransactionForm transaction={selectedTransaction} cancelEdit={cancelEdit} />

    return (
        <>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5' sx={{ letterSpacing: '1px' }}>Transactions ({metaData?.totalCount || 0})</Typography>
                <Box sx={{ alignItems: 'right' }}>
                    <Tooltip title="Export to Excel" placement="top">
                        <IconButton
                            size='large'
                            color='inherit'
                            onClick={exportData}
                            sx={{ ml: 'auto', fontSize: '2rem' }}
                        ><CloudDownloadIcon /></IconButton>
                    </Tooltip>
                    {hasAdminOrWriteRole() && <Tooltip title="Create Record" placement="top">
                        <IconButton
                            color='inherit'
                            onClick={() => setEditMode(true)}
                            sx={{ ml: 'auto' }}
                        ><AddCircleIcon /></IconButton>
                    </Tooltip>}
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={noWrap}  >
                        <TableRow>
                            {hasAdminOrWriteRole() && <TableCell align="center">Actions</TableCell>}
                            <TableCell align="center" style={{ display: 'none' }}>Id</TableCell>
                            <TableCell align="center" style={{ display: 'none' }}>GasStationId</TableCell>
                            <TableCell align="center">TranId</TableCell>
                            <TableCell align="center">TransactionDate</TableCell>
                            {/*<TableCell align="center">Transaction Type</TableCell>*/}
                            <TableCell align="center">Product</TableCell>
                            <TableCell align="center">Supplier</TableCell>
                            <TableCell align="center">Shift</TableCell>
                            <TableCell align="center">PumpNumber</TableCell>
                            <TableCell align="center">Status</TableCell>
                            {/*<TableCell align="center">PumpTransaction</TableCell>*/}
                            <TableCell align="center">BeginningLiters</TableCell>
                            <TableCell align="center">EndingLiters</TableCell>
                            <TableCell align="center">Calibration</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                            <TableCell align="center">Cost</TableCell>
                            <TableCell align="center">Price</TableCell>
                            <TableCell align="center">Sales</TableCell>
                            <TableCell align="center">AmountAddOn</TableCell>
                            <TableCell align="center">POAmount</TableCell>
                            <TableCell align="center">Discount</TableCell>
                            <TableCell align="center">DiscountCard</TableCell>
                            <TableCell align="center">TotalExcDiscount</TableCell>
                            <TableCell align="center">TotalIncDiscount</TableCell>

                            {/*<TableCell align="center">Payment Method</TableCell>*/}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={noWrap}  >
                        {transactions.map((transaction) => (
                            transaction.id &&
                            <TableRow
                                key={transaction.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {hasAdminOrWriteRole() && <TableCell sx={noWrap} align="center">
                                    <Button style={{ padding: 0 }} onClick={() => handleSelectTransaction(transaction)} startIcon={<Edit />} />
                                    <LoadingButton
                                        style={{ padding: 0 }}
                                        loading={loading && target === transaction.id}
                                        startIcon={<Delete />} color='error'
                                        onClick={() => handleDeleteTransaction(transaction.id || '')}
                                    />
                                </TableCell>}
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {transaction.id}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {transaction.gasStationId}
                                </TableCell>
                                <TableCell align="center">{transaction.tranId || ''}</TableCell>
                                <TableCell align="center">{dateFormat(transaction.transactionDate?.toString() || '')}</TableCell>
                                {/*<TableCell align="center">{transaction.transactionType}</TableCell>*/}
                                <TableCell align="center">{transaction.inventory.product.name}</TableCell>
                                <TableCell align="center">{transaction.inventory.supplier.name}</TableCell>
                                <TableCell align="center">{transaction.shift}</TableCell>
                                <TableCell align="center">{transaction.pumpTransaction.pumpNumber}</TableCell>
                                <TableCell align="center">{transaction.status}</TableCell>
                                <TableCell align="center">{currencyFormat(transaction.pumpTransaction.beginningLiters || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(transaction.pumpTransaction.endingLiters || 0)}</TableCell>
                                {/*<TableCell align="center">{transaction.pumpTransactionId}</TableCell>*/}
                                <TableCell align="center">{transaction.calibration}</TableCell>
                                <TableCell align="center">{currencyFormat(transaction.quantity || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(transaction.cost || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(transaction.price || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(transaction.pumpTransaction.saleAmount|| 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(transaction.amountAddOn || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(transaction.poAmount || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(transaction.discount || 0)}</TableCell>
                                <TableCell align="center">{transaction.discountCard}</TableCell>
                                <TableCell align="center">{currencyFormat(transaction.totalAmountExcDiscount || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(transaction.totalAmountIncDiscount || 0)}</TableCell>
                                {/*<TableCell align="center">{transaction.paymentMethod}</TableCell>*/}
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {metaData &&
                <Box sx={{ pt: 2 }}>
                    <AppPagination
                        metaData={metaData}
                        onPageChange={(page: number) => dispatch(setPageNumber({ pageNumber: page }))}
                    />
                </Box>
            }
        </>
    )
}