import { Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Tooltip, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { currencyFormat, dateFormat } from "../../app/util/util";
import usePumpPriceChanges from "../../app/hooks/usePumpPriceChanges";
import AppPagination from "../../app/components/AppPagination";
import { useAppDispatch } from "../../app/store/configureStore";
import { removePumpPriceChange, setPageNumber } from "../pump-price-changes/pumpPriceChangeSlice";
import { useState } from "react";
import PumpPriceChangeForm from "./PumpPriceChangeForm";
//import { Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import { PumpPriceChange } from "../../app/models/pumpPriceChange";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { hasAdminOrWriteRole } from "../auth/authSlice";
export default function PumpPriceChanges() {
    const { pumpPriceChanges, metaData } = usePumpPriceChanges();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedPumpPriceChange, setSelectedPumpPriceChange] = useState<PumpPriceChange | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState("");

    function handleSelectPumpPriceChange(pumpPriceChange: PumpPriceChange) {
        setSelectedPumpPriceChange(pumpPriceChange);
        setEditMode(true);
    }

    function handleDeletePumpPriceChange(id: string) {
        setLoading(true);
        setTarget(id);
        agent.PumpPriceChange.delete(id)
            .then(() => dispatch(removePumpPriceChange(id)))
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    function cancelEdit() {
        if (selectedPumpPriceChange) setSelectedPumpPriceChange(undefined);
        setEditMode(false);
    }

    function noWrapAndMinWidth() {
        return { whiteSpace: 'nowrap', maxWidth: 5 };
    }

    function noWrap() {
        return { whiteSpace: 'nowrap' };
    }

    if (editMode) return <PumpPriceChangeForm pumpPriceChange={selectedPumpPriceChange} cancelEdit={cancelEdit} />

    return (
        <>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5' sx={{ letterSpacing: '1px' }}>Pump Price Changes ({metaData?.totalCount || 0})</Typography>
                <Box sx={{ alignItems: 'right' }}>
                    {/*<Tooltip title="Export to Excel" placement="top">*/}
                    {/*    <IconButton*/}
                    {/*        size='large'*/}
                    {/*        color='inherit'*/}
                    {/*        onClick={exportData}*/}
                    {/*        sx={{ ml: 'auto', fontSize: '2rem' }}*/}
                    {/*    ><CloudDownloadIcon /></IconButton>*/}
                    {/*</Tooltip>*/}
                    {hasAdminOrWriteRole() && <Tooltip title="Create Record" placement="top">
                        <IconButton
                            color='inherit'
                            onClick={() => setEditMode(true)}
                            sx={{ ml: 'auto' }}
                        ><AddCircleIcon /></IconButton>
                    </Tooltip>}
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={noWrap}  >
                        <TableRow>
                            {hasAdminOrWriteRole() && <TableCell align="center">Actions</TableCell>}
                            <TableCell align="center" style={{ display: 'none' }}>Id</TableCell>
                            <TableCell align="center" style={{ display: 'none' }}>GasStationId</TableCell>
                            <TableCell align="center">Product</TableCell>
                            <TableCell align="center">Supplier</TableCell>
                            <TableCell align="center">EffectiveDate</TableCell>
                            <TableCell align="center">NewPrice</TableCell>
                            <TableCell align="center">PreviousPrice</TableCell>
                            <TableCell align="center">ChangeReason</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={noWrap}  >
                        {pumpPriceChanges.map((pumpPriceChange) => (
                            pumpPriceChange.id &&
                            <TableRow
                                key={pumpPriceChange.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {hasAdminOrWriteRole() && <TableCell sx={noWrapAndMinWidth} align="center">
                                    <Button style={{ padding: 0 }} onClick={() => handleSelectPumpPriceChange(pumpPriceChange)} startIcon={<Edit />} />
                                    <LoadingButton
                                        style={{ padding: 0 }}
                                        loading={loading && target === pumpPriceChange.id}
                                        startIcon={<Delete />}
                                        color='error'
                                        onClick={() => handleDeletePumpPriceChange(pumpPriceChange.id || '')}
                                    />
                                </TableCell>}
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {pumpPriceChange.id}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {pumpPriceChange.gasStationId}
                                </TableCell>
                                <TableCell align="center">{pumpPriceChange.inventory?.product.name}</TableCell>
                                <TableCell align="center">{pumpPriceChange.inventory?.supplier.name}</TableCell>
                                <TableCell align="center">{dateFormat(pumpPriceChange.effectiveDate?.toString() || '')}</TableCell>
                                <TableCell align="center">{currencyFormat(pumpPriceChange.newSellingPrice || 0)}</TableCell>
                                <TableCell align="center">{currencyFormat(pumpPriceChange.previousSellingPrice || 0)}</TableCell>
                                <TableCell align="center">{pumpPriceChange.changeReason}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {metaData &&
                <Box sx={{ pt: 2 }}>
                    <AppPagination
                        metaData={metaData}
                        onPageChange={(page: number) => dispatch(setPageNumber({ pageNumber: page }))}
                    />
                </Box>
            }
        </>
    )
}