import { Typography, Grid, Paper, Box, Button } from "@mui/material";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import AppDropzone from "../../app/components/AppDropzone";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
//import useProducts from "../../app/hooks/useProducts";
import usePayrolls from "../../app/hooks/usePayrolls";
//import { Product } from "../../app/models/product";
import { Payroll } from "../../app/models/payroll";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from "./payrollValidation";
import agent from "../../app/api/agent";
import { useAppDispatch } from "../../app/store/configureStore";
import { setPayroll } from "../payrolls/payrollSlice";
import { LoadingButton } from "@mui/lab";
import AppCheckbox from "../../app/components/AppCheckbox";
import AppDatePicker from "../../app/components/AppDatePicker";
import { toast } from "react-toastify";

interface Props {
    payroll?: Payroll;
    cancelEdit: () => void;
}

export default function PayrollForm({ payroll, cancelEdit }: Props) {
    const { control, reset, handleSubmit, watch, formState: { isDirty, isSubmitting } } = useForm({
        mode: 'all',
        resolver: yupResolver<any>(validationSchema)
    });
    //const { brands, types } = useGasStations();
    const watchFile = watch('file', null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (payroll && !watchFile && !isDirty) reset(payroll);
        return () => {
            if (watchFile) URL.revokeObjectURL(watchFile.preview);
        }
    }, [payroll, reset, watchFile, isDirty]);

    async function handleSubmitData(data: FieldValues) {
        let response: any;
        try {
            
            data.date = new Date(data.date).toISOString();
            data.fromDate = new Date(data.fromDate).toISOString();
            data.toDate = new Date(data.toDate).toISOString();
            if (payroll) {
                response = await agent.Payroll.patch(data.gasStationId, data.id, data);
            } else {
                response = await agent.Payroll.post(data.gasStationId, data);
            }
            dispatch(setPayroll(response));
            toast.success(response.message?.toString() || 'Success!');
            cancelEdit();
        } catch (error) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred.');
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4, letterSpacing: '1px' }}>
                Payroll Details
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='payrollDate' label='Date' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='fromDate' label='From Date' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='toDate' label='To Date' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='salaryAmount' label='Salary Amount' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='notes' label='Notes' />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='contained' color='inherit'>Cancel</Button>
                    <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='success'>Submit</LoadingButton>
                </Box>
            </form>
        </Box>
    )
}