import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { PumpTransaction, PumpTransactionParams } from "../../app/models/pumpTransaction";
import { RootState } from "../../app/store/configureStore";

interface PumpTransactionState {
    pumpTransactionsLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    pumpTransactionParams: PumpTransactionParams;
    metaData: MetaData | null;
    pumpTransactionActiveGasStation: string;
}

const pumpTransactionsAdapter = createEntityAdapter<PumpTransaction>();

function getAxiosParams(pumpTransactionParams: PumpTransactionParams) {
    const params = new URLSearchParams();
    params.append('page', pumpTransactionParams.pageNumber.toString());
    params.append('pageSize', pumpTransactionParams.pageSize.toString());
    if (pumpTransactionParams.pumpNumber) params.append('pumpNumber', pumpTransactionParams.pumpNumber);
    if (pumpTransactionParams.fromDate) params.append('fromDate', pumpTransactionParams.fromDate.toString());
    if (pumpTransactionParams.toDate) params.append('toDate', pumpTransactionParams.toDate.toString());
    return params;
}

export const getAxiosParamsForExport = (pumpTransactionParams: PumpTransactionParams) => {
    return getAxiosParams(pumpTransactionParams);
}

export const fetchPumpTransactionsAsync = createAsyncThunk<PumpTransaction[], void, { state: RootState }>(
    'pumpTransaction/fetchPumpTransactionsAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().pumpTransaction.pumpTransactionParams);
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            const response = await agent.PumpTransaction.getFiltered(gasStationId || '', params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchPumpTransactionAsync = createAsyncThunk<PumpTransaction, string, { state: RootState }>(
    'pumpTransaction/fetchPumpTransactionAsync',
    async (id, thunkAPI) => {
        const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
        try {
            return await agent.PumpTransaction.getByKeys(gasStationId || '', id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

function initParams() {
    return {
        pageNumber: 1,
        pageSize: 10,
        gasStationId: '',
        fromDate: null,
        toDate: null,
        pumpNumber: '',
    }
}

export const initParamsForFilter = () => {
    return initParams;
}

export const pumpTransactionSlice = createSlice({
    name: 'pumpTransaction',
    initialState: pumpTransactionsAdapter.getInitialState<PumpTransactionState>({
        pumpTransactionsLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        pumpTransactionParams: initParams(),
        metaData: null,
        pumpTransactionActiveGasStation: ''
    }),
    reducers: {
        setPumpTransactionParams: (state, action) => {
            state.pumpTransactionsLoaded = false;
            state.pumpTransactionParams = { ...state.pumpTransactionParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.pumpTransactionsLoaded = false;
            state.pumpTransactionParams = { ...state.pumpTransactionParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetPumpTransactionParams: (state) => {
            state.pumpTransactionParams = initParams();
        },
        setPumpTransaction: (state, action) => {
            pumpTransactionsAdapter.upsertOne(state, action.payload);
            state.pumpTransactionsLoaded = false;
        },
        removePumpTransaction: (state, action) => {
            pumpTransactionsAdapter.removeOne(state, action.payload);
            state.pumpTransactionsLoaded = false;
        },
        setPumpTransactionActiveGasStation: (state, action) => {
            state.pumpTransactionActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchPumpTransactionsAsync.pending, (state) => {
            state.status = 'pendingFetchPumpTransactions';
        });
        builder.addCase(fetchPumpTransactionsAsync.fulfilled, (state, action) => {
            pumpTransactionsAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.pumpTransactionsLoaded = true;
        });
        builder.addCase(fetchPumpTransactionsAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchPumpTransactionAsync.pending, (state) => {
            state.status = 'pendingFetchPumpTransaction';
        });
        builder.addCase(fetchPumpTransactionAsync.fulfilled, (state, action) => {
            pumpTransactionsAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchPumpTransactionAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
    })
})

export const pumpTransactionSelectors = pumpTransactionsAdapter.getSelectors((state: RootState) => state.pumpTransaction);

export const { setPumpTransactionParams, resetPumpTransactionParams, setMetaData, setPageNumber, setPumpTransaction, removePumpTransaction, setPumpTransactionActiveGasStation } = pumpTransactionSlice.actions;