import * as yup from 'yup';

export const validationParamSchema = yup.object({
    fromDate: yup
        .date()
        .typeError("Must be a valid date")
        .required("Required")
        .max(yup.ref('toDate'), 'From Date must be before To Date'),
    toDate: yup
        .date()
        .typeError("Must be a valid date")
        .required("Required")
        .min(yup.ref('fromDate'), 'To Date must be after From Date')
})