import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/agent";
import { MetaData } from "../../app/models/pagination";
import { Supplier, SupplierParams } from "../../app/models/supplier";
import { RootState } from "../../app/store/configureStore";

interface SupplierState {
    suppliersLoaded: boolean;
    filtersLoaded: boolean;
    status: string;
    supplierParams: SupplierParams;
    metaData: MetaData | null;
    supplierActiveGasStation: string;
}

const suppliersAdapter = createEntityAdapter<Supplier>();

function getAxiosParams(supplierParams: SupplierParams) {
    const params = new URLSearchParams();
    params.append('page', supplierParams.pageNumber.toString());
    params.append('pageSize', supplierParams.pageSize.toString());
    if (supplierParams.supplierName) params.append('supplierName', supplierParams.supplierName);
    return params;
}

export const getAxiosParamsForExport = (supplierParams: SupplierParams) => {
    return getAxiosParams(supplierParams);
}

export const fetchSuppliersAsync = createAsyncThunk<Supplier[], void, { state: RootState }>(
    'supplier/fetchSuppliersAsync',
    async (_, thunkAPI) => {
        const params = getAxiosParams(thunkAPI.getState().supplier.supplierParams);
        try {
            const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
            const response = await agent.Supplier.getFiltered(gasStationId || '', params);
            thunkAPI.dispatch(setMetaData(response));
            return response.items || { items: [] };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)

export const fetchSupplierAsync = createAsyncThunk<Supplier, string, { state: RootState } >(
    'supplier/fetchSupplierAsync',
    async (id, thunkAPI) => {
        try {
            const gasStationId = thunkAPI.getState()?.auth?.user?.gasStations[0].toString();
            return await agent.Supplier.getByKeys(gasStationId || '', id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data })
        }
    }
)


function initParams() {
    return {
        pageNumber: 1,
        pageSize: 50,
        gasStationId: '',
        supplierName: ''
    }
}

export const initParamsForFilter = () => {
    return initParams;
}

export const supplierSlice = createSlice({
    name: 'supplier',
    initialState: suppliersAdapter.getInitialState<SupplierState>({
        suppliersLoaded: false,
        filtersLoaded: false,
        status: 'idle',
        supplierParams: initParams(),
        metaData: null,
        supplierActiveGasStation: ''
    }),
    reducers: {
        setSupplierParams: (state, action) => {
            state.suppliersLoaded = false;
            state.supplierParams = { ...state.supplierParams, ...action.payload, pageNumber: 1 };
        },
        setPageNumber: (state, action) => {
            state.suppliersLoaded = false;
            state.supplierParams = { ...state.supplierParams, ...action.payload };
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        resetSupplierParams: (state) => {
            state.supplierParams = initParams();
        },
        setSupplier: (state, action) => {
            suppliersAdapter.upsertOne(state, action.payload);
            state.suppliersLoaded = false;
        },
        removeSupplier: (state, action) => {
            suppliersAdapter.removeOne(state, action.payload);
            state.suppliersLoaded = false;
        },
        setSupplierActiveGasStation: (state, action) => {
            state.supplierActiveGasStation = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchSuppliersAsync.pending, (state) => {
            state.status = 'pendingFetchSuppliers';
        });
        builder.addCase(fetchSuppliersAsync.fulfilled, (state, action) => {
            suppliersAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.suppliersLoaded = true;
        });
        builder.addCase(fetchSuppliersAsync.rejected, (state, action) => {
            state.status = 'idle';
        });
        builder.addCase(fetchSupplierAsync.pending, (state) => {
            state.status = 'pendingFetchSupplier';
        });
        builder.addCase(fetchSupplierAsync.fulfilled, (state, action) => {
            suppliersAdapter.upsertOne(state, action.payload);
            state.status = 'idle';
        });
        builder.addCase(fetchSupplierAsync.rejected, (state, action) => {
            console.log(action);
            state.status = 'idle';
        });
    })
})

export const supplierSelectors = suppliersAdapter.getSelectors((state: RootState) => state.supplier);

export const { setSupplierParams, resetSupplierParams, setMetaData, setPageNumber, setSupplier, removeSupplier, setSupplierActiveGasStation } = supplierSlice.actions;