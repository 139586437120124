import { useEffect } from "react";
import { userSelectors, fetchUsersAsync, fetchFilters } from "../../features/users/userSlice";
import { useAppSelector, useAppDispatch } from "../store/configureStore";

export default function useUsers() {
    const users = useAppSelector(userSelectors.selectAll);
    const { usersLoaded, filtersLoaded, gasStations, roles, pages, metaData } = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!usersLoaded) dispatch(fetchUsersAsync());
    }, [usersLoaded, dispatch])

    useEffect(() => {
        if (!filtersLoaded) dispatch(fetchFilters());
    }, [filtersLoaded, dispatch]);

    return {
        users,
        usersLoaded,
        filtersLoaded,
        gasStations,
        roles,
        pages,
        metaData
    }
}