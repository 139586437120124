import { ShoppingCart } from "@mui/icons-material";
//import { AppBar, Badge, Box, IconButton, List, ListItem, Switch, Toolbar, Typography } from "@mui/material";
import { Badge, Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, Switch, ListItemText, Toolbar, Typography, CssBaseline, Drawer, Divider } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { useAppSelector } from "../store/configureStore";
import SignedInMenu from "./SignedInMenu";
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import * as React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';
import AttachMoneyTwoToneIcon from '@mui/icons-material/AttachMoneyTwoTone';
import MoneyOffCsredTwoToneIcon from '@mui/icons-material/MoneyOffCsredTwoTone';
import LocalGasStationTwoToneIcon from '@mui/icons-material/LocalGasStationTwoTone';
import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone';
import PaymentTwoToneIcon from '@mui/icons-material/PaymentTwoTone';
import RequestQuoteTwoToneIcon from '@mui/icons-material/RequestQuoteTwoTone';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import PriceChangeTwoToneIcon from '@mui/icons-material/PriceChangeTwoTone';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import GasMeterTwoToneIcon from '@mui/icons-material/GasMeterTwoTone';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import SquareTwoToneIcon from '@mui/icons-material/SquareTwoTone';
import { alignProperty } from "@mui/material/styles/cssUtils";
import { left } from "@popperjs/core";
interface AppBarProps extends MuiAppBarProps {
    darkMode: boolean;
    handleThemeChange: () => void;
    open?: boolean;
}

const midLinks = [
    //{ title: 'catalog', path: '/catalog' },
    //{ title: 'about', path: '/about' },
    //{ title: 'contact', path: '/contact' }
]

const rightLinks = [
    { title: 'login', path: '/login' },
    { title: 'register', path: '/register' }
]

const navStyles = {
    color: 'inherit',
    textDecoration: 'none',
    typography: '3',
    '&:hover': {
        color: 'grey.500'
    },
    '&.active': {
        color: 'text.secondary'
    }
}

const navStylesLeft = {
    color: 'inherit',
    textDecoration: 'none',
    typography: '3',
    '&:hover': {
        color: 'grey.500'
    },
    '&.active': {
        color: 'text.secondary'
    },
    alignProperty: 'left'
}

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    '&:hover': {
        color: 'grey.500'
    },
    '&.active': {
        color: 'text.secondary'
    }
}));

function CamelCaseToHyphenated(camelCase: string): string {
    return camelCase
        .replace(/([a-z])([A-Z])/g, '$1-$2')  // Add a hyphen between lowercase and uppercase letters
        .toLowerCase(); // Convert the result to lowercase
}

function GetModuleIcon(module: string){
    switch (module) {
        case 'CashRegisters':
            return <LocalAtmTwoToneIcon />;
        case 'Deposits':
            return <AttachMoneyTwoToneIcon />;
        case 'Expenses':
            return <MoneyOffCsredTwoToneIcon />;
        case 'GasStations':
            return <LocalGasStationTwoToneIcon />;
        case 'Inventories':
            return <InventoryTwoToneIcon />;
        case 'Payments':
            return <PaymentTwoToneIcon />;
        case 'Payrolls':
            return <RequestQuoteTwoToneIcon />;
        case 'Products':
            return <CategoryTwoToneIcon />;
        case 'PumpPriceChanges':
            return <PriceChangeTwoToneIcon />;
        case 'PumpTransactions':
            return <GasMeterTwoToneIcon />;
        case 'Suppliers':
            return <BusinessTwoToneIcon />;
        case 'Transactions':
            return <ReceiptLongTwoToneIcon />;
        case 'Users':
            return <PeopleOutlineTwoToneIcon />;
        default:
            return <SquareTwoToneIcon />;
    }
}

const orderedPages = [
    'Dashboard',
    'Transactions',
    'PumpTransactions',
    'Payments',
    'Deposits',
    'Expenses',
    'Payrolls',
    'CashRegisters',
    'Inventories',
    'PumpPriceChanges',
    'Products',
    'Suppliers',
    'GasStations',
    'Users'
];


export default function Header({ darkMode, handleThemeChange }: AppBarProps) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const { user } = useAppSelector(state => state.auth);

    const modules = user?.pages?.map(page => ({
        title: page,
        path: `/${page}`,
    }));
    
    if (modules) {
        // Sort modules based on the order in orderedPages
        modules.sort((a, b) => {
            const indexA = orderedPages.indexOf(a.title);
            const indexB = orderedPages.indexOf(b.title);

            // If both pages are in the orderedPages array, compare their indices
            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
            }

            // If only one page is in the orderedPages array, prioritize it
            if (indexA !== -1) {
                return -1;
            }
            if (indexB !== -1) {
                return 1;
            }

            // If neither page is in the orderedPages array, maintain the original order
            return 0;
        });
    }
    return (
        user &&
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} darkMode={false} handleThemeChange={handleThemeChange}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left' }}>
                    <Box display='flex' alignItems='center'>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        ><MenuIcon /></IconButton>
                        <Typography variant="h5" sx={{ letterSpacing: '2px' }}>FUEL MONITORING</Typography> 
                    </Box>                    
                    
                    <Box display='flex' alignItems='center'>
                        <Typography sx={navStyles}>{user ? (<SignedInMenu />) : (null)}</Typography>
                        </Box>
                </Toolbar>                
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    }
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                        <img src="https://fuelmonitoring.codingben.com/fuelmonitoring.png" alt="" style={{ margin: 'auto'}} width="100" height="100" />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {user && (user.roles?.length || 0) > 0 && modules?.map(({ title, path }) => (
                        <ListItem
                            component={NavLink}
                            to={CamelCaseToHyphenated(path)}
                            key={CamelCaseToHyphenated(path)}
                            sx={navStyles}
                            disablePadding
                            onClick={handleDrawerClose}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    {GetModuleIcon(title)}
                                </ListItemIcon>
                                <ListItemText primary={title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
            </Main>
            { user ? (<SignedInMenu />) : (null)}
        </Box>
        //<Box sx={{ display: 'flex' }}>{ user ? (<SignedInMenu />) : (null)}</Box>
    );
}