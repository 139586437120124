import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";
import { history } from "../..";
import agent from "../../app/api/agent";
import { User } from "../../app/models/user";
import { RootState, store } from "../../app/store/configureStore";

interface AccountState {
    user: User | null;
    activeGasStation: string | null;
}

const initialState: AccountState = {
    user: null,
    activeGasStation: null
}

export const signInUser = createAsyncThunk<User, FieldValues>(
    'account/signInUser',
    async (data, thunkAPI) => {
        try {
            const userDto = await agent.Auth.login(data);
            

            const user  = userDto.data;
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const fetchCurrentUser = createAsyncThunk<User>(
    'account/fetchCurrentUser',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(setUser(JSON.parse(localStorage.getItem('user')!)));
        try {
            const userDto = await agent.Auth.currentUser();
            const user = userDto.data;
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    },
    {
        condition: () => {
            if (!localStorage.getItem('user')) return false;
        }
    }
)
//export function hasAdminOrWriteRole(): boolean {
//    return userRoles.includes('Admin') || userRoles.includes('Write');
//}

export const hasAdminOrWriteRole = (): boolean => {
    const userRoles = store.getState().auth?.user?.roles || [];
    return userRoles.includes('Admin') || userRoles.includes('Write');
};

export const isAdminRole = (): boolean => {
    const userRoles = store.getState().auth?.user?.roles || [];
    return userRoles.includes('Admin');
};

export const authSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        signOut: (state) => {
            state.user = null;
            state.activeGasStation = null;
            localStorage.removeItem('user');
            history.push('/');
        },
        setUser: (state, action) => {
            let claims = JSON.parse(atob(action.payload.accessToken.split('.')[1]));
            let roles = claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            let pages = claims['page'];
            let gasStations = claims['gasStation'];
            let email = claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
            state.user = {
                ...action.payload,
                roles: typeof (roles) === 'string' ? [roles] : roles,
                pages: typeof (pages) === 'string' ? [pages] : pages,
                gasStations: typeof (gasStations) === 'string' ? [gasStations] : gasStations,
                activeGasStation: typeof (gasStations) === 'string' ? gasStations : gasStations[0],
                email: email
            };
            state.activeGasStation = typeof (gasStations) === 'string' ? gasStations : gasStations[0];

        },
        setActiveGasStation: (state, action) => {
            if (state.user) {
                state.activeGasStation = action.payload;
            }            
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchCurrentUser.rejected, (state) => {
            state.user = null;
            state.activeGasStation = null;
            localStorage.removeItem('user');
            toast.error('Session expired - please login again');
            history.push('/login');
        });
        builder.addMatcher(isAnyOf(signInUser.fulfilled, fetchCurrentUser.fulfilled), (state, action) => {
            let claims = JSON.parse(atob(action.payload.accessToken.split('.')[1]));
            let roles = claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            let pages = claims['page'];
            let gasStations = claims['gasStation'];
            let email = claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
            state.user = {
                ...action.payload,
                roles: typeof (roles) === 'string' ? [roles] : roles,
                pages: typeof (pages) === 'string' ? [pages] : pages,
                gasStations: typeof (gasStations) === 'string' ? [gasStations] : gasStations,
                email: email
            };
            state.activeGasStation = typeof (gasStations) === 'string' ? gasStations : gasStations[0];
        });
        builder.addMatcher(isAnyOf(signInUser.rejected), (state, action) => {
            throw action.payload;
        })
    })
})

export const { signOut, setUser, setActiveGasStation } = authSlice.actions;