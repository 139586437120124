import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useEffect, useRef, useState } from 'react';

interface Props {
    data: any[];
}



export default function BiaxialBarChart(props: Props) {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const [chartWidth, setChartWidth] = useState(750);

    const sales = props.data.map(item => item.totalAmount);
    const quantity = props.data.map(item => item.totalQuantity);
    //const [highlighted, setHighlighted] = React.useState('item');
    //const [faded, setFaded] = React.useState('global');

    const xLabels = props.data.map(item => item.month);

    const updateChartWidth = () => {
        if (chartContainerRef.current) {
            const containerWidth = chartContainerRef.current.offsetWidth;
            setChartWidth(containerWidth);
        }
    };

    useEffect(() => {
        updateChartWidth();
        window.addEventListener('resize', updateChartWidth);

        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, [chartContainerRef]);
    return (
        <div ref={chartContainerRef}>
            <BarChart
                width={chartWidth}
                height={350}
                series={[
                    {
                        data: sales,
                        label: 'Total Sales',
                        id: 'sales',
                        yAxisKey: 'leftAxisId',
                        highlightScope: { faded: 'global', highlighted: 'item' }

                    },
                    {
                        data: quantity,
                        label: 'Total Liters Sold',
                        id: 'sold',

                        yAxisKey: 'rightAxisId',
                        highlightScope: { faded: 'global', highlighted: 'item' }
                    },
                ]}
                xAxis={[{ data: xLabels, scaleType: 'band' }]}
                yAxis={[{ id: 'leftAxisId', label: "Sales" }, { id: 'rightAxisId', label: "Liters Sold" }]}
                rightAxis="rightAxisId"
            />
        </div>
    );
}