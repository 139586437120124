import { Typography, Grid, Paper, Box, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import AppDropzone from "../../app/components/AppDropzone";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
import useProducts from "../../app/hooks/useProducts";
import usePumpPriceChanges from "../../app/hooks/usePumpPriceChanges";
//import { Product } from "../../app/models/product";
import { PumpPriceChange } from "../../app/models/pumpPriceChange";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from "./pumpPriceChangeValidation";
import agent from "../../app/api/agent";
import { useAppDispatch } from "../../app/store/configureStore";
import { setPumpPriceChange } from "../pump-price-changes/pumpPriceChangeSlice";
import { LoadingButton } from "@mui/lab";
import AppCheckbox from "../../app/components/AppCheckbox";
import AppDatePicker from "../../app/components/AppDatePicker";
import { currencyFormat, dateFormat, dateFormatReset } from "../../app/util/util";
import { toast } from "react-toastify";
import useInventories from "../../app/hooks/useInventories";
import { InventoryLookupData } from "../../app/models/inventory";
import LoadingComponent from "../../app/layout/LoadingComponent";

interface Props {
    pumpPriceChange?: PumpPriceChange;
    cancelEdit: () => void;
}

export default function PumpPriceChangeForm({ pumpPriceChange, cancelEdit }: Props) {
    const { control, reset, handleSubmit, register, watch, formState: { isDirty, isSubmitting, errors } } = useForm({
        mode: 'all',
        resolver: yupResolver<any>(validationSchema)
    });
    const { inventories } = useInventories();
    const [inventoryId, setInventoryId] = useState('');
    const [allInventories, setAllInventories] = useState<any[] | null>(null);
    const watchFile = watch('file', null);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (pumpPriceChange && !watchFile && !isDirty) reset(pumpPriceChange);
        if (pumpPriceChange) {
            setInventoryId(pumpPriceChange.inventoryId || '');

        }
        agent.Inventory.getLookupData('')
            .then(data => {
                setAllInventories((data.data as InventoryLookupData).allInventories);
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false));

        return () => {
            if (watchFile) URL.revokeObjectURL(watchFile.preview);
        }
    }, [pumpPriceChange, reset, watchFile, isDirty]);

    if (loading) return <LoadingComponent message='Loading filters...' />

    async function handleSubmitData(data: FieldValues) {
        let response: any;
        try {
            data.inventoryId = inventoryId || '';
            if (data.effectiveDate) data.effectiveDate = dateFormatReset(data.effectiveDate);
            if (pumpPriceChange) {
                response = await agent.PumpPriceChange.patch(data.gasStationId, data.id, data);
            } else {
                response = await agent.PumpPriceChange.post(data.gasStationId, data);
            }
            dispatch(setPumpPriceChange(response));
            toast.success(response.message?.toString() || 'Success!');
            cancelEdit();
        } catch (error) {
            console.log(error);
            toast.error(error?.toString() || 'Error occurred.');
        }
    }

    return (
        <Box component={Paper} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4, letterSpacing: '1px' }}>
                Pump Price Change Details
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitData)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth error={!!errors.inventoryId}>
                            <InputLabel>Product-Supplier</InputLabel>
                            <Select
                                value={inventoryId}
                                variant='outlined'
                                {...register('inventoryId')}
                                inputProps={{ readOnly: (pumpPriceChange?.id?.toString() || '') !== '' ? true : false }}
                                onChange={async (event) => {
                                    const { value } = event.target;
                                    setInventoryId(value);                                   
                                }}

                            >
                                {allInventories?.map((item) => (
                                    <MenuItem key={item.id?.toString()} value={item.id?.toString()}>{`${item.supplier.name}-${item.product.name}`}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.inventoryId?.message?.toString()}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppDatePicker control={control} name='effectiveDate' label='Effective Date' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='newSellingPrice' label='New Price' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput type='number' control={control} name='previousSellingPrice' label='Previous Price' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AppTextInput control={control} name='changeReason' label='Change Reason' />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
                    <Button onClick={cancelEdit} variant='contained' color='inherit'>Cancel</Button>
                    <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='success'>Submit</LoadingButton>
                </Box>
            </form>
        </Box>
    )
}