import { Typography, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Tooltip, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { currencyFormat } from "../../app/util/util";
import useGasStations from "../../app/hooks/useGasStations";
import AppPagination from "../../app/components/AppPagination";
import { useAppDispatch } from "../../app/store/configureStore";
import { removeGasStation, setPageNumber } from "../gas-stations/gasStationSlice";
import { useState } from "react";
import GasStationForm from "./GasStationForm";
//import { Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import { GasStation } from "../../app/models/gasStation";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { hasAdminOrWriteRole, isAdminRole } from "../auth/authSlice";
export default function GasStations() {
    const { gasStations, metaData } = useGasStations();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState(false);
    const [selectedGasStation, setSelectedGasStation] = useState<GasStation | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState("");

    function handleSelectGasStation(gasStation: GasStation) {
        setSelectedGasStation(gasStation);
        setEditMode(true);
    }

    function handleDeleteGasStation(id: string) {
        setLoading(true);
        setTarget(id);
        agent.GasStation.delete(id)
            .then(() => dispatch(removeGasStation(id)))
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }

    function cancelEdit() {
        if (selectedGasStation) setSelectedGasStation(undefined);
        setEditMode(false);
    }

    function noWrapAndMinWidth() {
        return { whiteSpace: 'nowrap', maxWidth: 5 };
    }

    function noWrap() {
        return { whiteSpace: 'nowrap' };
    }

    if (editMode) return <GasStationForm gasStation={selectedGasStation} cancelEdit={cancelEdit} />

    return (
        <>
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5' sx={{ letterSpacing: '1px' }}>Gas Stations ({metaData?.totalCount || 0})</Typography>
                <Box sx={{ alignItems: 'right' }}>
                    {/*<Tooltip title="Export to Excel" placement="top">*/}
                    {/*    <IconButton*/}
                    {/*        size='large'*/}
                    {/*        color='inherit'*/}
                    {/*        onClick={exportData}*/}
                    {/*        sx={{ ml: 'auto', fontSize: '2rem' }}*/}
                    {/*    ><CloudDownloadIcon /></IconButton>*/}
                    {/*</Tooltip>*/}
                    {isAdminRole() && <Tooltip title="Create Record" placement="top">
                        <IconButton
                            color='inherit'
                            onClick={() => setEditMode(true)}
                            sx={{ ml: 'auto' }}
                        ><AddCircleIcon /></IconButton>
                    </Tooltip>}
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={noWrap} >
                        <TableRow>
                            {isAdminRole() && <TableCell align="center">Actions</TableCell>}
                            <TableCell align="center" style={{ display: 'none' }}>Id</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Address</TableCell>
                            <TableCell align="center">MobileNo</TableCell>
                            <TableCell align="center">TelephoneNo</TableCell>
                            <TableCell align="center">Description</TableCell>
                            <TableCell align="center">IsActive</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={noWrap}  >
                        {gasStations.map((gasStation) => (
                            gasStation.id &&
                            <TableRow
                                key={gasStation.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {isAdminRole() && <TableCell sx={noWrapAndMinWidth} align="center">
                                    <Button style={{ padding: 0 }} onClick={() => handleSelectGasStation(gasStation)} startIcon={<Edit />} />
                                    {/*<LoadingButton*/}
                                    {/*    style={{ padding: 0 }}*/}
                                    {/*    loading={loading && target === gasStation.id}*/}
                                    {/*    startIcon={<Delete />}*/}
                                    {/*    color='error'*/}
                                    {/*    onClick={() => handleDeleteGasStation(gasStation.id || '')}*/}
                                    {/*/>*/}
                                </TableCell>}
                                <TableCell component="th" scope="row" style={{ display: 'none' }}>
                                    {gasStation.id}
                                </TableCell>
                                <TableCell align="center">{gasStation.name}</TableCell>
                                <TableCell align="center">{gasStation.address}</TableCell>
                                <TableCell align="center">{gasStation.mobileNumber}</TableCell>
                                <TableCell align="center">{gasStation.telephoneNumber}</TableCell>
                                <TableCell align="center">{gasStation.description}</TableCell>
                                <TableCell align="center">{gasStation.isActive === true ? 'Yes' : 'No'}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {metaData &&
                <Box sx={{ pt: 2 }}>
                    <AppPagination
                        metaData={metaData}
                        onPageChange={(page: number) => dispatch(setPageNumber({ pageNumber: page }))}
                    />
                </Box>
            }
        </>
    )
}